import { createTheme, ThemeProvider } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router-dom'

import { appRoutes, version } from '../../constants'
import AlertsConfigPage from '../../pages/AlertsConfigPage'
import AlertsPage from '../../pages/AlertsPage'
import AlertZonesPage from '../../pages/AlertZonesPage'
import CalibrationsPage from '../../pages/CalibrationsPage'
import CountPage from '../../pages/CountPage'
import DashboardPage from '../../pages/DashboardPage'
import ErrorPage from '../../pages/ErrorPage'
import MessengerPage from '../../pages/MessengerPage'
import PlanificationsPage from '../../pages/PlanificationsPage'
import PreviewPage from '../../pages/PreviewPage'
import ProjectsPage from '../../pages/ProjectsPage'
import ReportsPage from '../../pages/ReportsPage'
import TimerProjectsPage from '../../pages/TimerProjectsPage'
import TimerUsersPage from '../../pages/TimerUsersPage'
import AdminOrganizationsPage from '../../pages/AdminOrganizationsPage'
import AdminVesselsPage from '../../pages/AdminVesselsPage'
import AdminConfigNotifications from '../../pages/AdminConfigNotifications';
import UsersManagementPage from '../../pages/UsersManagementPage'
import UsersProfilePage from '../../pages/UsersProfilePage'
import TitleBar from '../comun/TitleBar'
import Version from '../comun/Version'
import Copyright from '../Copyright'
import Logo from '../LogoOjo/LogoOjo'
import MenuOptions from './MenuOptions'
import DownloadsPage from '../../pages/DownloadsPage'
import SensorConfigPage from '../../pages/SensorConfigPage'
import RequestConfigPage from '../../pages/RequestConfigPage'
import AdminOrganizationVesselsPage from '../../pages/AdminOrganizationVesselsPage'

const drawerWidth = 290

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}))

export default function AppLayout() {
	const [t] = useTranslation('common')
	const mdTheme = createTheme({
		palette: {
			secondary: {
				main: '#737373',
			},
		},
	})

	const LeftDrawer = () => {
		const [open, setOpen] = React.useState(false)
		return (
			<Drawer
				sx={{ height: '100%', overflow: 'hidden' }}
				variant='permanent'
				open={open}
				onMouseEnter={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}>
				<Toolbar
					variant='dense'
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Logo open={open} />
				</Toolbar>
				<Divider />
				<MenuOptions t={t} open={open} setOpen={setOpen}></MenuOptions>
				<Divider />
				{open ? <Version tag={version.company.concat(version.value)} /> : <Version tag={version.value} />}
				<Copyright open={open} />
			</Drawer>
		)
	}

	return (
		<ThemeProvider theme={mdTheme}>
			<Box sx={{ display: 'flex', height: '100%' }}>
				<CssBaseline />
				<LeftDrawer />
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						flex: 1,
						marginLeft: '72px',
						height: '100%',
					}}>
					<TitleBar />
					<Switch>
						<Route path={appRoutes.HOME} component={DashboardPage} exact />
						<Route path={appRoutes.PROJECTS} component={ProjectsPage} exact />
						<Route path={appRoutes.REPORTS} component={ReportsPage} exact />
						<Route path={appRoutes.CALIBRATIONS} component={CalibrationsPage} exact />
						<Route path={appRoutes.COUNT} component={CountPage} exact />
						<Route path={appRoutes.PLANIFICATIONS} component={PlanificationsPage} exact />
						<Route path={appRoutes.TIMER_PROJECTS} component={TimerProjectsPage} exact />
						<Route path={appRoutes.TIMER_USERS} component={TimerUsersPage} exact />
						<Route path={appRoutes.USERS_MANAGEMENT} component={UsersManagementPage} exact />
						<Route path={appRoutes.DOWNLOADS} component={DownloadsPage} exact />
						<Route path={appRoutes.USERS_PROFILE} component={UsersProfilePage} exact />
						<Route path={appRoutes.PREVIEW} component={PreviewPage} exact />
						<Route path={appRoutes.ALERTS} component={AlertsPage} exact />
						<Route path={appRoutes.ALERTS_URL} component={AlertsPage} exact />
						<Route path={appRoutes.ALERTS_CONFIG} component={AlertsConfigPage} exact />
						<Route path={appRoutes.ALERT_ZONES} component={AlertZonesPage} exact />
						<Route path={appRoutes.MESSENGER_PAGE} component={MessengerPage} exact />
						<Route path={appRoutes.ERROR_PAGE} component={ErrorPage} exact />
						<Route path={appRoutes.ADMIN_ORGANIZATIONS} component={AdminOrganizationsPage} exact/>
						<Route path={appRoutes.ADMIN_VESSELS} component={AdminVesselsPage} exact/>
						<Route path={appRoutes.ADMIN_CONFIG_NOTIFICATIONS} component={AdminConfigNotifications} exact/>
						<Route path={appRoutes.ADMIN_ORGANIZATIONS_VESSELS} component={AdminOrganizationVesselsPage} exact/>
						<Route path={appRoutes.SENSOR_CONFIG} component={SensorConfigPage} exact/>
						<Route path={appRoutes.REQUEST_CONFIG} component={RequestConfigPage} exact/>
						<Redirect to={appRoutes.HOME} />
					</Switch>
				</div>
			</Box>
		</ThemeProvider>
	)
}
