import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'
import { prepareParams } from '../../ServicesUtils'

export const useConfigAlertRequest = () => {
	const [dataConfigAlert, setDataConfigAlert] = useState(null)
	const [errorConfigAlert, setErrorConfigAlert] = useState(null)
	const [isLoadingConfigAlert, setIsLoadingConfigAlert] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const configAlertRequest = useCallback(
		(filter) => {
			client
				.get(baseUrl + 'horus/f3/notification/config?' + prepareParams(filter))

				.then((result) => {
					setIsLoadingConfigAlert(false)

					if (result.data.success) {
						setDataConfigAlert(result.data)
					} else {
						setErrorConfigAlert({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingConfigAlert(false)
					setErrorConfigAlert(err.response)
				})

			setDataConfigAlert(null)
			setErrorConfigAlert(null)
			setIsLoadingConfigAlert(true)
		},
		[client]
	)
	return {
		configAlertRequest,
		isLoadingConfigAlert,
		dataConfigAlert,
		errorConfigAlert,
	}
}
