import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../../../comun/BasicDialog'
import { useForm } from 'react-hook-form'
import AutoCompleteComponent from '../../../comun/form_fields/AutoCompleteComponent'
import DatePickerComponent from '../../../comun/form_fields/DatePickerComponent'
import SelectComponent from '../../../comun/form_fields/SelectComponent'

export default function OrganizationVesselForm({
    selectedVessel,
    onClose,
    onSave,
    mode,
    open,
    mlProcessType
}) {
    const [t] = useTranslation('common')
    const { setValue, watch, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onSubmit' })

    const [optionsMLProcessLabel, setOptionsMLProcessLabel] = useState([]);
    const [optionsLiveCamLabel, setOptionsLiveCamLabel] = useState([]);

    const [isDisabled, setIsDisabled] = useState(false);

    const mlProcessValue = watch('mlProcess');

    /** Efecto que carga los datos en el form de edit */
    useEffect(() => {

        const comboOptions = [
            { id: true, label: t('vessels.form.enabled') },
            { id: false, label: t('vessels.form.disabled') },
        ];

        setOptionsMLProcessLabel(comboOptions)
        setOptionsLiveCamLabel(comboOptions)

        const rowMLOption = comboOptions.find(o => o.id === selectedVessel.mlProcessIsEnabled);
        setValue('mlProcess', rowMLOption)

        const rowLiveCamOption = comboOptions.find(o => o.id === selectedVessel.liveCamIsEnabled)
        setValue('liveCam', rowLiveCamOption)

        const rowMLProcessType = mlProcessType.find(o => o.id === selectedVessel.mlProcessType)
        setValue('mlTypeProcess', rowMLProcessType)

        setValue('lastMessageReadDate', selectedVessel.lastMessageReadDate)

    }, [selectedVessel]);

    /** Efecto que revisa el cambio en el campo MLProcess */
    useEffect(() => {
        if (mlProcessValue) {
            if (mlProcessValue.id === false) {
                setIsDisabled(true)
                setValue('mlTypeProcess', '')
            } else {
                setIsDisabled(false)
            }
        }
    }, [mlProcessValue]);

    return (
        <Box component='form' onSubmit={handleSubmit((data) => onSave(data))}>
            <BasicDialog
                title={`${t('organization-vessels.form.name')} - ${selectedVessel.vessel.description}`}
                onOpen={open}
                onClose={onClose}
                mode={mode}
                saveTxt={t('comun.actions.save')}>
                <Box sx={{ minWidth: '600px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <AutoCompleteComponent sx={{ m: 1, marginRight: '10px', width: '50%', flex: 1 }}
                            label={t('organization-vessels.form.liveCam')}
                            size='small'
                            control={control}
                            name="liveCam"
                            options={optionsLiveCamLabel}
                            optionsLabel={'label'}
                            keyProperty={'id'}
                        />
                        <AutoCompleteComponent sx={{ m: 1, marginRight: '10px', width: '50%', flex: 1 }}
                            size='small'
                            control={control}
                            label={t('organization-vessels.form.mlProcess')}
                            name='mlProcess'
                            options={optionsMLProcessLabel}
                            optionsLabel={'label'}
                            keyProperty={'id'}
                        />

                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <DatePickerComponent sx={{ m: 1, marginRight: '10px', width: '50%', flex: 1 }}
                            defaultValue={null}
                            control={control}
                            label={t('organization-vessels.table.lastCommentRead')}
                            name="lastMessageReadDate"
                            size="small"
                            errors={errors?.lastMessageReadDate}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('comun.form-errors.required'),
                                },
                            }}
                        />
                        {selectedVessel.mlProcessIsEnabled && (
                            <AutoCompleteComponent sx={{ m: 1, marginRight: '10px', width: '50%', flex: 1 }}
                                label={t('organization-vessels.form.mltype')}
                                size='small'
                                control={control}
                                name="mlTypeProcess"
                                options={mlProcessType}
                                optionsLabel={'description'}
                                keyProperty={'id'}
                                errors={errors?.mlTypeProcess}
                                disabled={isDisabled}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('comun.form-errors.required'),
                                    },
                                }}
                            />
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <SelectComponent sx={{ m: 1, marginRight: '10px', width: '50%', flex: 1 }}
                         size='small'
                         label={t('organization-vessels.form.mltype')}
                         control={control}
                         name="mlTypeProcessT"
                         onChange={handleChange}
                         options={optionsMLProcessLabel}
                         optionsLabel={'label'}
                         optionsValue={'id'}
                        >    
                        </SelectComponent> */}
                    </Box>
                </Box>
            </BasicDialog>

        </Box>
    )
}
