import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useDashboardStartMLProcess = () => {
	const [mlProcessData, setMLProcessStart] = useState(null)
	const [errorMLProcess, setErrorMLProcess] = useState(null)
	const [isLoadingMLProcess, setIsLoadingMLProcess] = useState(false)

	const { client } = useContext(ApiClientContext)

	const mlProcessStart = useCallback(
		(vesselId) => {
			client
				.post(baseUrl + 'horus/admin/process/dashboard/start/mlprocess?vesselApiId='+vesselId, null)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						mlProcessData({ data: result.data })
					} else {
						setErrorMLProcess({ statusText: result.data.errorMsg })
					}
					setIsLoadingMLProcess(false)
				})
				.catch((error) => {
					setErrorMLProcess(error.response)
					setIsLoadingMLProcess(false)
				})

			setMLProcessStart(null)
			setErrorMLProcess(null)
			setIsLoadingMLProcess(true)
		},
		[client]
	)

	return {
		mlProcessStart,
		isLoadingMLProcess,
		mlProcessData,
		errorMLProcess,
		setErrorMLProcess,
	}
}