import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'
import { prepareParams } from '../../ServicesUtils'

export const useZoneAlertRequest = () => {
	const [dataZoneAlert, setDataZoneAlert] = useState(null)
	const [errorZoneAlert, setErrorZoneAlert] = useState(null)
	const [isLoadingZoneAlert, setIsLoadingZoneAlert] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const zoneAlertRequest = useCallback(
		(filter) => {
			client
				.get(baseUrl + 'horus/f3/notification/zone?' + prepareParams(filter))
				.then((result) => {
					setIsLoadingZoneAlert(false)
					if (result.data.success) {
						setDataZoneAlert(result.data)
					} else {
						setErrorZoneAlert({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingZoneAlert(false)
					setErrorZoneAlert(err.response)
				})

			setDataZoneAlert(null)
			setErrorZoneAlert(null)
			setIsLoadingZoneAlert(true)
		},
		[client]
	)
	return {
		zoneAlertRequest,
		dataZoneAlert,
		errorZoneAlert,
		isLoadingZoneAlert,
	}
}
