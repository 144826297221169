import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
const {useParams, useHistory} = require("react-router-dom/cjs/react-router-dom.min");

import {Box, Divider} from '@mui/material';

const {messageDialogTypes, baseFilter, modeTypesCRUD} = require("constants");
import MessageSnackBar from 'components/comun/MessageSnackBar';
import BackdropComponent from 'components/comun/utils/BackdropComponent';
import ConfirmationDialog from "components/comun/ConfirmationDialog";

import { PagedResponseList, SelectValueNumberModel, SelectValueStringModel } from "models/BasicModel";

import { ConfigNotificationModel } from "models/config_notifications/ConfigNotificationModel";
import {ConfigNotificationTableFilterModel, ConfigNotificationViewTableModel} from 'models/config_notifications/ConfigNotificationTableModel';
import { FishingProfile } from "models/fishing_profile/FishingProfileModel";

import ConfigNotificationsFilter from "./configuration_notifications/ConfigNotificationsFilter";
import ConfNotificationsItemTypeForm from "./configuration_notifications/ConfigNotificationsForm";
import ConfigNotificationsTable from "./configuration_notifications/ConfigNotificationsTable";

import { configNotificationAdd, 
    configNotificationUpdate, 
    configNotificationDelete, 
    configNotificationsDataTableGetDataTable, 
    getConfigNotificationsFilterData } from "services/admin/configuration_notifications/ConfigurationNotificationsService";

const AdminConfigNotificationsContainer:React.FC = () => {
    const [t] = useTranslation('common');
    const history = useHistory();
    const { id } = useParams();
    //MODELS
    const [organizationId, setOrganizationId] = useState<number>(id);
    const [fishingProfiles, setFishingProfiles] = useState<FishingProfile[]>([]);

    const [optionsFishingProfiles, setOptionsFishingProfiles] = useState<SelectValueNumberModel[]>([]);
    const [optionsItemTypesFilter, setOptionsItemtypesFilter] = useState<SelectValueStringModel[]>([]);

    //ENTITY
    const [configNotificationsListModel, setConfigNotificationsListModel] = useState<PagedResponseList<ConfigNotificationModel> | null>(null); 
    const [configNotificationSelected, setConfigNotificationSelected] = useState<ConfigNotificationModel | null>(null);
    //ViewModel
    const [configNotificationsListViewModel, setConfigNotificationsListViewModel] = useState<PagedResponseList<ConfigNotificationViewTableModel> | null>(null); 
    //Filter
	const [filterModel, setFilterModel] = useState<ConfigNotificationTableFilterModel>({
        organizationId: organizationId,
        fishingProfileId: null,
        itemType: null,
        mail: null,
        removed: false,
        pageNo: baseFilter.pageNo,
        pageSize: baseFilter.pageSize,
        orderField:'description',
        orderDirection:'asc'
    })

    //Modales
	const [openModal, setOpenModal] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
	const [modeCRUD, setModeCRUD] = useState(modeTypesCRUD.INSERT_MODE);

    //SERVICES
    const {configNotificationFilterRequest, isLoadingFilterRequest, configNotificationsFilterRequestRes, errorMsgFilterRequest} = getConfigNotificationsFilterData();
    const {configNotificationsDataTableGetReq, isLoadingDataTableGet, configNotificationsDataTableGetRes, errorMsgDataTableGet } = configNotificationsDataTableGetDataTable();
    const {configNotificationsDeleteReq, isLoadingDelete, configNotificationsDeleteRes, errorMsgDelete } = configNotificationDelete();
    const {configNotificationsUpdateReq, isLoadingUpdate, configNotificationsUpdateRes, errorMsgUpdate } = configNotificationUpdate();
    const {configNotificationsAddReq, isLoadingAdd, configNotificationsAddRes, errorMsgAdd } = configNotificationAdd();

    //Errors control
	const [openError, setOpenError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string | null>(null);
	const [typeMessage, setTypeMessage] = useState<any | null>(null);

    /** Render que llama a la API /filters 1ª llamada de carga inicial*/
    useEffect(() => {
        configNotificationFilterRequest(organizationId.toString())
    }, []);

    useEffect(() => {
        setFishingProfiles(configNotificationsFilterRequestRes ? configNotificationsFilterRequestRes.fishingProfiles : []);
        setOptionsFishingProfiles(configNotificationsFilterRequestRes 
                ? configNotificationsFilterRequestRes.fishingProfiles.map(fp => ({ value: fp.fishingProfileId, description: fp.description }))
                :[]);

        if(configNotificationsFilterRequestRes && configNotificationsFilterRequestRes.resultFilter){
            effectUpdateTableData(configNotificationsFilterRequestRes.resultFilter, configNotificationsFilterRequestRes.fishingProfiles);
        }
        
    }, [configNotificationsFilterRequestRes]);

    useEffect(() => { 
        effectUpdateTableData(configNotificationsDataTableGetRes, fishingProfiles);
    }, [configNotificationsDataTableGetRes]);

    useEffect(() => {   
        if(configNotificationsAddRes || configNotificationsUpdateRes || configNotificationsDeleteRes){
            onCloseModelForm();
            onFilter(filterModel);
        }  
    }, [configNotificationsAddRes, configNotificationsUpdateRes, configNotificationsDeleteRes]);

    useEffect(() => {
		if (
			errorMsgFilterRequest ||
			errorMsgDataTableGet || 
            errorMsgDelete ||
            errorMsgUpdate ||
            errorMsgAdd
		) {
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE);
			setOpenError(true);
			if (errorMsgFilterRequest) {
				setErrorMsg(errorMsgFilterRequest)
			} else if (errorMsgDataTableGet) {
				setErrorMsg(errorMsgDataTableGet)
			}else if(errorMsgDelete){
                setErrorMsg(errorMsgDelete);
            }else if(errorMsgUpdate){
                setErrorMsg(errorMsgUpdate);
            }else if(errorMsgAdd){
                setErrorMsg(errorMsgAdd);
            }
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [
		errorMsgFilterRequest,
		errorMsgDataTableGet,
        errorMsgDelete,
        errorMsgUpdate,
        errorMsgAdd
	]);

    const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	};

    const handleFormConfigItemTypeNot = (idModel: number, mode: any) => {
		setModeCRUD(mode);
        var configNotSelectedModel: ConfigNotificationModel = {
            id: idModel,
            organizationId: organizationId,
            fishingProfileId: 0,
            itemType:'',
            description:'',
            isDeleted:false,
            emails:[],
            lines: []
        };
        if(idModel > 0){
            configNotSelectedModel = configNotificationsListModel!.data.filter(f => f.id == idModel)[0];
        }
        setConfigNotificationSelected(configNotSelectedModel);
		if (modeTypesCRUD.UPDATE_MODE === mode || modeTypesCRUD.INSERT_MODE === mode) {
            setOpenModal(true);
		} else if (modeTypesCRUD.DELETE_MODE === mode) {
			setOpenDelete(true);
		} 
	}

    function effectUpdateTableData(pagedTableModel: PagedResponseList<ConfigNotificationModel> |null, auxFishingProfiles : FishingProfile[]){
        setConfigNotificationsListModel(pagedTableModel ? pagedTableModel : null);
        setConfigNotificationsListViewModel(pagedTableModel 
                ? {
                    pageNo: pagedTableModel!.pageNo,
                    pageSize: pagedTableModel!.pageSize,
                    errorMsg: pagedTableModel!.errorMsg,
                    success: pagedTableModel!.success,
                    totalCount: pagedTableModel!.totalCount,
                    data: pagedTableModel.data.map(d => mapTableModelToViewTableModel(d, auxFishingProfiles)),
                    pendingData: pagedTableModel!.pendingData             
                }
                : null);
    }

    function mapTableModelToViewTableModel(source: ConfigNotificationModel, auxFishingProfiles : FishingProfile[]):ConfigNotificationViewTableModel
    {
        var fishingProfile : FishingProfile | null = auxFishingProfiles 
            ? !auxFishingProfiles.find(fp => fp.fishingProfileId == source.fishingProfileId) ? null
            : auxFishingProfiles.filter(fp => fp.fishingProfileId == source.fishingProfileId)[0] 
            : null;
        return {
            id: source.id,
            description: source.description,
            organizationId: source.organizationId,
            fishingProfileId: source.fishingProfileId,
            fishingProfileName: fishingProfile ? fishingProfile.description : '',
            itemType: source.itemType,
            itemTypeName: fishingProfile ? !fishingProfile.itemTypeList.find(it => it.itemType == source.itemType) ? ''
            :  fishingProfile.itemTypeList.filter(it => it.itemType == source.itemType)[0].description
            : '',
            isDeleted: source.isDeleted            
        };
    }

    const onFilter = (dataModel : ConfigNotificationTableFilterModel) => {
        dataModel.organizationId = organizationId;
        dataModel.orderField = 'ID';
		setFilterModel(dataModel);
        configNotificationsDataTableGetReq(dataModel);
	}

    const onChangeFishingProfile = (idFishingProfile: number) => {
        setOptionsItemtypesFilter(!fishingProfiles ? []
            : !fishingProfiles.find(fp => fp.fishingProfileId == idFishingProfile) ? [] 
            : fishingProfiles?.filter(fp => fp.fishingProfileId == idFishingProfile)[0]
                .itemTypeList.map(it => ({ value: it.itemType, description: it.description }))
        );   
    };

    /******************** EVENTOS MODAL************/
	const onCloseDelete = (confirmed : boolean) => {
		setOpenDelete(false);
		if (confirmed && configNotificationSelected) {
			configNotificationsDeleteReq(configNotificationSelected!)
		}
	}
    const onCloseModelForm = () => {
        setOpenModal(false);
    };
    const onSaveModelForm = (modelSave: ConfigNotificationModel) => {
        modelSave.organizationId = organizationId;
        var jsonexam = JSON.stringify(modelSave);

        if (modeTypesCRUD.UPDATE_MODE === modeCRUD){
			configNotificationsUpdateReq(modelSave);
        }else if(modeTypesCRUD.INSERT_MODE === modeCRUD){
			configNotificationsAddReq(modelSave);
        }
    };

    return (
        <Box sx={{mx: 3, my: 1, display: 'flex', flexDirection: 'column'}}>
            <Box sx={{width: '100%'}}>
                <ConfigNotificationsFilter fishingsProfiles={optionsFishingProfiles} 
                    onFilter={onFilter} 
                    filterModel={filterModel} 
                    onChangeFishingProfile={onChangeFishingProfile} 
                    itemTypes={optionsItemTypesFilter}></ConfigNotificationsFilter>
            </Box>

            <Divider sx={{m: 2}}></Divider>

            <Box>
                <ConfigNotificationsTable dataTable={configNotificationsListViewModel} 
                    onFilter={onFilter} 
                    filterModel={filterModel}
                    rowAction={handleFormConfigItemTypeNot}
                />
            </Box>

            <BackdropComponent
                open={ isLoadingFilterRequest
                    || isLoadingDataTableGet
                    || isLoadingDelete
                    || isLoadingUpdate
                    || isLoadingAdd} 
                    size={undefined} sx={undefined} thickness={undefined} messenger={undefined}			/>

			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={typeMessage}
			/>

            {openModal ? (
				<ConfNotificationsItemTypeForm
                    modelSelected={configNotificationSelected}
                    open={openModal}
                    onClose={onCloseModelForm}
                    onSave={onSaveModelForm}
                    mode={modeCRUD}
                    fishingProfiles={fishingProfiles} 
                />
			) : null}

            <ConfirmationDialog
				onOpen={openDelete}
				onClose={onCloseDelete}
				dialogTitle={configNotificationSelected?.isDeleted ? t('organization-itemtypes.form.title-undelete') : t('organization-itemtypes.form.title-delete')}
				dialogContent={configNotificationSelected?.isDeleted ? t('organization-itemtypes.form.undelete-content') : t('organization-itemtypes.form.delete-content')}
				dialogSaveButton={configNotificationSelected?.isDeleted ? t('comun.actions.undelete') : t('comun.actions.delete')}
				dialogCancelButton={t('comun.actions.close')}
                isDelete={true}
                annotationToDelete={undefined}
			/>
        </Box>
    );
}

export default AdminConfigNotificationsContainer;