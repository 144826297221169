import Box from '@mui/material/Box'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../comun/BasicDialog'
import SelectComponent from '../comun/form_fields/SelectComponent'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'

export default function ProjectForm({ project, programs, status, actions, action, open, onSave, onClose }) {
	const [t] = useTranslation('common')
	const projectRefMaxLength = 50
	const descriptionMaxLength = 255
	const rfmoMaxLength = 100

	const {
		control,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const isUpdateMode = actions.UPDATE_PROJECT == action
	const [disableField, setDisableField] = React.useState(true)

	const selectProgram = watch('programId')

	React.useEffect(() => {
		if (!isUpdateMode) {
			setValue('typeAnalysisId', '')
			setValue('vesselApiId', '')
		} else if (project && selectProgram) {
			let type = selectProgram.typeAnalysisList.find((s) => s.typeAnalysisId == project.typeAnalysisId)
			let vessel = selectProgram.vesselList.find((s) => s.vesselApiId == project.vesselApiId)
			setValue('typeAnalysisId', type)
			setValue('vesselApiId', vessel)
		}
		setDisableField(selectProgram ? false : true)
	}, [selectProgram])

	useEffect(() => {
		if (project && isUpdateMode) {
			let program = programs.find((p) => p.programId == project.programId)
			setValue('programId', program)
		}
	}, [project])

	const getTitle = () => {
		if (actions.UPDATE_PROJECT === action) {
			return t('project.form.title-update')
		} else if (actions.INSERT_PROJECT === action) {
			return t('project.form.title-new')
		}
	}

	const programOptions = programs.filter((program) =>
		!isUpdateMode ? program.canUseInProjectCreation == true : true
	)

	return (
		<Box>
			{open ? (
				<Box component='form' onSubmit={handleSubmit(onSave)}>
					<BasicDialog
						title={getTitle()}
						onOpen={open}
						onClose={onClose}
						saveTxt={t('comun.actions.save')}
						disabled={!programOptions.length && !isUpdateMode}>
						<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '80vh' }}>
							<Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
								<TextFieldComponent
									defaultValue={isUpdateMode ? (project.description ? project.description : '') : ''}
									sx={{ width: '100%', m: 1 }}
									name='description'
									multiline
									rows={2}
									label={t('project.form.description')}
									control={control}
									size='small'
									errors={errors?.description}
									rules={{
										required: {
											value: true,
											message: t('project.form.validations.required'),
										},
										maxLength: {
											value: descriptionMaxLength,
											message: `${t('comun.form-errors.max-length')} ${descriptionMaxLength}`,
										},
									}}
								/>
							</Box>

							<Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
								<AutoCompleteComponent
									defaultValue={null}
									disabled={isUpdateMode}
									size='small'
									sx={{ width: '33.333%', m: 1 }}
									control={control}
									name='programId'
									label={t('project.form.program')}
									options={programOptions}
									optionsLabel={'programDescription'}
									keyProperty={'programId'}
									errors={errors?.programId}
									evalMethod={(opt, val) => {
										return opt.programId === val.programId
									}}
									rules={{
										required: {
											value: true,
											message: t('project.form.validations.required'),
										},
									}}
								/>
								<AutoCompleteComponent
									disabled={isUpdateMode ? true : disableField}
									size='small'
									sx={{ width: '33.333%', m: 1 }}
									control={control}
									name='typeAnalysisId'
									label={t('project.form.analysis')}
									options={selectProgram ? selectProgram.typeAnalysisList : []}
									optionsLabel='typeAnalysisDescription'
									keyProperty='typeAnalysisId'
									errors={errors?.typeAnalysisId}
									rules={{
										required: {
											value: true,
											message: t('project.form.validations.required'),
										},
									}}
								/>
								<AutoCompleteComponent
									disabled={isUpdateMode ? true : disableField}
									size='small'
									sx={{ width: '33.333%', m: 1 }}
									control={control}
									name='vesselApiId'
									label={t('project.form.vessel')}
									options={selectProgram ? selectProgram.vesselList : []}
									optionsLabel='vesselName'
									keyProperty ='vesselApiId'
									errors={errors?.vesselApiId}
									rules={{
										required: {
											value: true,
											message: t('project.form.validations.required'),
										},
									}}
								/>
							</Box>

							<Box sx={{ display: 'none', flexDirection: 'row', m: 1 }}>
								<TextFieldComponent
									defaultValue={isUpdateMode ? (project.projectRef ? project.projectRef : '') : ''}
									sx={{ width: '33.333%', m: 1 }}
									name='projectRef'
									label={t('project.form.project-ref')}
									control={control}
									size='small'
									errors={errors?.projectRef}
									rules={{
										// required: {
										// 	value: true,
										// 	message: t('project.form.validations.required'),
										// },
										maxLength: {
											value: projectRefMaxLength,
											message: `${t('comun.form-errors.max-length')} ${projectRefMaxLength}`,
										},
									}}
								/>
								<TextFieldComponent
									defaultValue={isUpdateMode ? (project.rfmo ? project.rfmo : '') : ''}
									sx={{ width: '33.333%', m: 1 }}
									name='rfmo'
									label={t('project.form.rfmo')}
									control={control}
									size='small'
									errors={errors?.rfmo}
									rules={{
										// required: {
										// 	value: true,
										// 	message: t('project.form.validations.required'),
										// },
										maxLength: {
											value: rfmoMaxLength,
											message: `${t('comun.form-errors.max-length')} ${rfmoMaxLength}`,
										},
									}}
								/>
								<AutoCompleteComponent
									defaultValue={
										isUpdateMode
											? project.projectStatusId
												? status.find((i) => i.value == project.projectStatusId)
												: null
											: status.find((i) => i.value == 1)
									}
									sx={{ width: '33.333%', m: 1 }}
									label={t('project.form.status')}
									name='projectStatusId'
									control={control}
									size='small'
									disabled
									options={status}
									optionsLabel={'label'}
									keyProperty={'value'}
									errors={errors?.projectStatusId}
									rules={{
										required: {
											value: true,
											message: t('project.form.validations.required'),
										},
									}}
								/>
							</Box>
						</Box>
					</BasicDialog>
				</Box>
			) : null}
		</Box>
	)
}
