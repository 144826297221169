import React from "react";
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import ErrorBoundary from "../components/ErrorBoundary";
import RequestConfigContainer from "../components/admin/vessels/configuration/RequestConfigContainer";

const RequestConfigPage = () => {
    const history = useHistory()
  return (
    <ErrorBoundary history={history}>
        <RequestConfigContainer></RequestConfigContainer>
    </ErrorBoundary>
  )
}

export default RequestConfigPage