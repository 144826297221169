import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useOrganizationActivate = () => {
	const [orgDataActivate, setOrgDataActivate] = useState(null)
	const [errorOrgActivate, setErrorOrgActivate] = useState(null)
	const [isLoadingOrgActivate, setIsLoadingOrgActivate] = useState(false)

	const { client } = useContext(ApiClientContext)
	const orgActivate = useCallback(
		(organization) => {
			client
				.put(baseUrl + 'horus/admin/undelete/organization', organization )
				.then((result) => {
					setIsLoadingOrgActivate(false)
					if (result.status === 200 && result.data.success) {
						setOrgDataActivate({ data: result.data })
					} else {
						setErrorOrgActivate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorOrgActivate(error.response)
					setIsLoadingOrgActivate(false)
				})

			setIsLoadingOrgActivate(true)
			setOrgDataActivate(null)
			setErrorOrgActivate(null)
		},
		[client]
	)

	return {
		orgActivate,
		isLoadingOrgActivate,
		orgDataActivate,
		errorOrgActivate,
		setErrorOrgActivate,
	}
}
