import React, { useEffect, useState } from 'react'
import { baseFilter, messageDialogTypes, modeTypesCRUD, codesActivityTimer } from '../../constants'
import ConfirmationDialog from '../comun/ConfirmationDialog'
import { useVesselUpdate } from '../../services/admin/vessels/useVesselUpdate'
import { useVesselInsert } from '../../services/admin/vessels/useVesselInsert'
import { useVesselDelete } from '../../services/admin/vessels/useVesselDelete'
import { useVesselActivate } from '../../services/admin/vessels/useVesselActivate'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid, Paper } from '@mui/material'
import EntityFilter from './organization/EntityFilter'
import VesselsTable from './vessels/VesselsTable'
import { useVesselRequest } from '../../services/admin/vessels/useVesselRequest'
import { useVesselFilterRequest } from '../../services/admin/vessels/useVesselFilterRequest'
import VesselsForm from './vessels/VesselsForm'
import VesselFilter from './vessels/VesselFilter'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'

/** Filtros iniciales (orderDirection,orderField,pageNo,pageSize) */
const initialFilter = { ...baseFilter, orderDirection: 'asc', orderField: 'id' }

export default function AdminVesselsContainer() {
  const [t] = useTranslation('common')

  /* == EntityFilter (Cuadro de busqueda) == */
  const [filterVessel, setFilterVessel] = useState('')
  const [favouriteFilters, setFavourites] = React.useState(null)
  const [filter, setFilter] = useState(initialFilter) //Filtros

  /* == VesselsTable == */
  const [open, setOpen] = useState(false)
  const [mode, setMode] = React.useState(modeTypesCRUD.INSERT_MODE)
  const [openDelete, setOpenDelete] = useState(false)
  const [openClear, setOpenClear] = useState(false)
  const [openReactivate, setOpenReactivate] = useState(false)
  const [selectedVessel, setSelectedVessel] = useState(null)
  const [vesselList, setVesselList] = useState(null)

  /* == Control de errores == */
  const [openError, setOpenError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [typeMessage, setTypeMessage] = useState(null)

  /* == CRUD == */
  const { vesselUpdate, isLoadingUpdate, vesselDataUpdate, errorVesselsUpdate } = useVesselUpdate()
  const { vesselInsert, isLoadingInsert, vesselDataInsert, errorVesselInsert } = useVesselInsert()
  const { vesselDelete, isLoadingDelete, vesselDataDelete, errorVesselDelete } = useVesselDelete()
  const { vesselActivate, isLoadingVesselActivate, vesselDataActivate, errorVesselActivate } = useVesselActivate()
  const { vesselRequest, isLoading, vesselData, errorVesselData } = useVesselRequest() //1ª carga con filtro
  const { vesselFilterRequest, isLoadingVesselFilter, vesselFilter, errorVesselFilter } = useVesselFilterRequest() //2ª Carga inicial

  /** Efecto de la primera carga de datos */
  useEffect(() => {
    vesselFilterRequest()
  }, [])

  /** Efecto para renderizar los datos de la API */
  useEffect(() => {
    if (vesselFilter != null) {
      setVesselList(vesselFilter.resultFilter)
      setFavourites(vesselFilter.favouritesDic)
    }
  }, [vesselFilter])

  /** Efecto que renderiza la carga con el filtro de busqueda */
  useEffect(() => {
    if (vesselData != null) {
      setVesselList(vesselData)
    }
  }, [vesselData])

  /** Efecto que recarga el componente cuando borra, activa o actualiza un barco */
  useEffect(() => {
    if (vesselDataDelete || vesselDataActivate || vesselDataUpdate || vesselDataInsert) {
      onClose()
      vesselRequest(filter)
    }
  }, [vesselDataDelete, vesselDataActivate, vesselDataUpdate])

  /** Efecto que recoge los errores de la primera carga */
  useEffect(() => {
    if (errorVesselData) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorVesselData)
    }
  }, [vesselData])

  /** Efecto que recoge los errores de la carga con filtros */
  useEffect(() => {
    if (errorVesselFilter) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorVesselFilter)
    }
  }, [vesselFilter, errorVesselFilter])

  /** Efecto que recoge los errores del CRUD */
  useEffect(() => {
    if (errorVesselDelete) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorVesselDelete)
    } else if (errorVesselInsert) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorVesselInsert)
    } else if (errorVesselActivate) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorVesselActivate)
    } else if (errorVesselsUpdate) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorVesselsUpdate)
    } else {
      setOpenError(false)
      setErrorMsg(null)
    }
  }, [errorVesselDelete, errorVesselInsert, errorVesselActivate, errorVesselsUpdate])


  /* == EVENTOS DE MODAL (VesselsTable) == */
  const handleOpenForm = (row) => {
    setSelectedVessel(row)
    setOpen(true)
  }

  /** Metodo que maneja la modal de error */
  const handleCloseError = () => {
    setOpenError(false)
    vesselRequest(filter)
  }

  const onClose = () => {
    setOpen(false)
  }

  /** DELETE// Acción de delete sobre un barco */
  const onCloseDelete = (confirmed) => {
    setOpenDelete(false)
    if (confirmed) {
      vesselDelete(selectedVessel)
    }
  }

  /** Método que llama a la API para reactivar el barco*/
  const onCloseReactivate = (confirmed) => {
    setOpenReactivate(false)
    if (confirmed) {
      vesselActivate(selectedVessel)
    }
  }

  /** CRUD en modales*/
  const handleFormVessels = (row, mode) => {
    setMode(mode)
    if (modeTypesCRUD.UPDATE_MODE === mode) {
      handleOpenForm(row)
    } else if (modeTypesCRUD.DELETE_MODE === mode) {
      setOpenDelete(true)
      setSelectedVessel(row)
    } else if (modeTypesCRUD.INSERT_MODE === mode) {
      handleOpenForm(defaultRow)
    } else if (modeTypesCRUD.CLEAR_MODE === mode) {
      setOpenClear(true)
      setSelectedVessel(row)
    } else if (modeTypesCRUD.ENABLE_MODE === mode) {
      setOpenReactivate(true)
      setSelectedVessel(row)
    }
  }

  const parseData = (data) => {
    const { name, owner, camNo, terminalId, albacoraId, seatubeId, recMode, imo, lastHeartBeatDate, lastHeartBeatSent, dataFrom, lastPing } = data
    const vessel = {
      name, owner, camNo, isActive: true, terminalId, albacoraId, seatubeId, recMode, imo, lastHeartBeatDate, lastHeartBeatSent, dataFrom, lastPing
    }

    return vessel
  }

  /** Insert & Update Vessels*/
  const onSave = (data) => {
    var vessel = parseData(data)

    if (modeTypesCRUD.UPDATE_MODE === mode) {
      vessel.id = data.id
      vesselUpdate(vessel)
      setOpen(false)
    } else if (modeTypesCRUD.INSERT_MODE === mode) {
      vesselInsert(vessel)
      setOpen(false)
    }
  }

  /** Cuando hace una busqueda*/
  const onFilter = (filter) => {
    setFilter(filter)
    vesselRequest(filter) //Llamada a API con filtro
  }

  /** Campos del filtro*/
  const vesselFields = [
    { name: 'filter', label: 'Filtro' }
  ]

  const defaultRow = {
    id: '',
    name: '',
    owner: '',
    camNo: '',
    terminalId: ''
  }

  return (
    <Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>

      <BackdropComponent
        open={
          isLoading ||
          isLoadingVesselFilter ||
          isLoadingInsert ||
          isLoadingUpdate ||
          isLoadingDelete ||
          isLoadingVesselActivate
        }
      />

      <MessageSnackBar
        openError={openError}
        errorMsg={errorMsg}
        typeMsg={typeMessage}
        handleCloseError={handleCloseError}
      />

      <Box sx={{ width: '100%' }}>
        <Grid>
          <VesselFilter
            filterVessel={filterVessel}
            onFilter={onFilter}
            filter={filter}
            fields={vesselFields}
            favouriteFilters={favouriteFilters}
          ></VesselFilter>
          <Divider sx={{ m: 2 }} variant='middle' />
          <Paper sx={{ width: '100%' }}>
            <VesselsTable
              vesselList={vesselList}
              filter={filter}
              onFilter={onFilter}
              handleFormVessels={handleFormVessels}            >
            </VesselsTable>
          </Paper>
        </Grid>

        {open && (
          <VesselsForm
            selectedVessel={selectedVessel}
            onClose={onClose}
            onSave={onSave}
            mode={mode}
            open={open}
          />
        )}

        <ConfirmationDialog
          onOpen={openDelete}
          onClose={onCloseDelete}
          dialogTitle={t('vessels.form.title-delete')}
          dialogContent={t('vessels.form.delete-content')}
          dialogSaveButton={t('comun.actions.delete')}
          dialogCancelButton={t('comun.actions.close')}></ConfirmationDialog>

        <ConfirmationDialog
          onOpen={openReactivate}
          onClose={onCloseReactivate}
          dialogTitle={t('vessels.form.title-reactivate')}
          dialogContent={t('vessels.form.reactivate-content')}
          dialogSaveButton={t('comun.actions.enable')}
          dialogCancelButton={t('comun.actions.close')}></ConfirmationDialog>
      </Box>
    </Box>
  )
}

