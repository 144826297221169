import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useVesselInsert = () => {
	const [vesselDataInsert, setVesselDataInsert] = useState(null)
	const [errorVesselInsert, setErrorVesselInsert] = useState(null)
	const [isLoadingInsert, setIsLoadingInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const vesselInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/admin/vessel', props)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setVesselDataInsert({ data: result.data })
					} else {
						setErrorVesselInsert({ statusText: result.data.errorMsg })
					}
					setIsLoadingInsert(false)
				})
				.catch((error) => {
					setErrorVesselInsert(error.response)
					setIsLoadingInsert(false)
				})

			setVesselDataInsert(null)
			setErrorVesselInsert(null)
			setIsLoadingInsert(true)
		},
		[client]
	)

	return {
		vesselInsert,
		isLoadingInsert,
		vesselDataInsert,
		setVesselDataInsert,
		errorVesselInsert,
		setErrorVesselInsert,
	}
}