import Box from '@mui/material/Box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { isStartDateBefore } from '../comun/DateUtils'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import DatePickerComponent from '../comun/form_fields/DatePickerComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'

export default function GeneralDataProgramForm({ methodsForm, selected, clients }) {
	const {
		control,
		reset,
		getValues,
		formState: { errors },
	} = methodsForm

	const [clientsList] = React.useState(clients)

	const [t] = useTranslation('common')

	React.useEffect(() => {
		if (selected != null) {
			reset(selected)
		}
	}, [selected])

		return (
			<Box sx={{ p: 1, width: '100%' }}>
				<Box sx={{ display: 'flex' }}>
					<TextFieldComponent
						defaultValue={selected?.description}
						sx={{ width: '100%'}}
						rules={{
							maxLength: {
								value: 250,
								message: t('programs.validations.max-length'),
							},
							required: t('programs.validations.required-field'),
						}}
						control={control}
						label={t('programs.filter.description')}
						name='description'
						size='small'
						errors={errors?.description}
					/>
				</Box>

				<Box sx={{ display: 'none' }}>
					{clientsList && clientsList.length > 0 ? (
						<AutoCompleteComponent
							size='small'
							sx={{ minWidth: '100%', mt: 1 }}
							control={control}
							defaultValue={selected.client ? { id: selected.client.id, name: selected.client.name } : null}
							name='clients'
							label={t('programs.filter.clients')}
							errors={errors?.clients}
							optionsLabel={'name'}
							keyProperty={'id'}
							options={clientsList}
						/>
					) : null}
				</Box>

				<Box sx={{ display: 'flex' }}>
					<Box sx={{ width: '50%' }}>
						<DatePickerComponent
							size='small'
							sx={{ width: '100%', mt: 1, pr: 1 }}
							control={control}
							defaultValue={selected.startDate}
							name='startDate'
							label={t('programs.filter.start-date')}
							errors={errors?.startDate}
							rules={{
								required: t('comun.form-errors.required'),
								validate: {
									// Validación de campo requerido
									required: (value) => {
										if (!value) return t('comun.form-errors.required');
										return true;
									},
									// Validación de formato de fecha
									format: (value) => {
										const isValidDate = value && !isNaN(new Date(value).getTime())
										if (!isValidDate) return t('programs.validations.date-format');

									},
									// Validación de rango de fechas (startDateValue)
									startDate: (value) => {
										const startDateValue = getValues('startDate'); 
										const isValidRange = isStartDateBefore(startDateValue, getValues('endDate'));
										if (!isValidRange) return t('programs.validations.start-date');
										return true;
									},
								},
							}}

						></DatePickerComponent>
					</Box>
					<Box sx={{ width: '50%' }}>
						<DatePickerComponent
							size='small'
							sx={{ width: '100%', mt: 1 }}
							control={control}
							defaultValue={selected.endDate}
							name='endDate'
							errors={errors?.endDate}
							label={t('programs.filter.end-date')}
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								}
							}}></DatePickerComponent>
					</Box>
				</Box>
			</Box>
		)
	}
