import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useOrganizationInsert = () => {
	const [organizationDataInsert, setOrganizationDataInsert] = useState(null)
	const [errorOrgInsert, setErrorOrgInsert] = useState(null)
	const [isLoadingInsert, setIsLoadingInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const organizationInsert = useCallback(
		(organization) => {
			client
				.post(baseUrl + 'horus/admin/organization', organization)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setOrganizationDataInsert({ data: result.data })
					} else {
						setErrorOrgInsert({ statusText: result.data.errorMsg })
					}
					setIsLoadingInsert(false)
				})
				.catch((error) => {
					setErrorOrgInsert(error.response)
					setIsLoadingInsert(false)
				})

			setOrganizationDataInsert(null)
			setErrorOrgInsert(null)
			setIsLoadingInsert(true)
		},
		[client]
	)

	return {
		organizationInsert,
		isLoadingInsert,
		organizationDataInsert,
		setOrganizationDataInsert,
		errorOrgInsert,
		setErrorOrgInsert,
	}
}