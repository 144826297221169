import { ApiClientContext } from "../../ApiClientProvider"
import React, { useCallback, useState } from 'react'
import { baseUrl } from "../../../constants"
import { prepareParams } from "../../ServicesUtils"


export const useVesselRequest = () => {
    const [vesselData, setVesselData] = useState(null)
    const [errorVesselData, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
    const { client } = React.useContext(ApiClientContext)

    const vesselRequest = useCallback(
        (filter) => {
            client
                .get(baseUrl + 'horus/admin/vessel?' + prepareParams(filter))
                .then((result) => {
                    setIsLoading(false)
                    if(result.data.success){
                        setVesselData(result.data)
                    }else{
                        setError({ statusText: result.data.errorMsg })
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    setError(err.response) 
                }) 

                setVesselData(null)
                setError(null)
                setIsLoading(true)
        },
        [client]
    )
    return{
        vesselRequest,
        isLoading,
        vesselData,
        errorVesselData
    }
}