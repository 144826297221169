import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import FieldsetComponent from "components/comun/FieldsetComponent";

import TextFieldComponent from "components/comun/form_fields/TextFieldComponent";
import SelectComponent from "components/comun/form_fields/SelectComponent";

import { ConfItemTypeSectionProps } from "models/config_notifications/ConfigNotificationComponentsModel";
import { FishingProfileItemTypeLine } from "models/fishing_profile/FishingProfileModel";
import { ItemTypeNotificationConfigLineModel } from "models/config_notifications/ConfigNotificationModel";
import { SelectValueNumberModel, SelectValueStringModel } from "models/BasicModel";
import { Description } from "@mui/icons-material";

const ConfItemTypeSection: React.FC<ConfItemTypeSectionProps> = ({ fishingProfileItemType, itemTypeNotificationConfigLineList, methodsForm }) => {
    const [t] = useTranslation('common');
    const {
        control,
        formState: { errors },
    } = methodsForm;

    const [fishingProfileItemTypeLineList, setFishingProfileItemTypeLineList] = useState<FishingProfileItemTypeLine[]>([]);

    useEffect(() => {
        if (fishingProfileItemType && fishingProfileItemType.lineList && fishingProfileItemType.lineList.length > 0) {
            setFishingProfileItemTypeLineList(fishingProfileItemType.lineList);
        }
    }, [fishingProfileItemType]);

    const RowModel = (row: FishingProfileItemTypeLine, index: number) => {
        var dataModel: ItemTypeNotificationConfigLineModel | null = itemTypeNotificationConfigLineList
            ? itemTypeNotificationConfigLineList.filter(it => it.lineNo == row.lineNo)[0]
            : null;
        if (row.type.trim().toUpperCase() == "DD") {
            return RowComboItem(row, index, dataModel);
        } else {
            switch (row.inputType as string) {
                case "STRING":
                    return RowTextBoxItem(row, index, dataModel);
                case "DECIMAL":
                    return RowNumberItem(row, true, index, dataModel);
                case "INTEGER":
                    return RowNumberItem(row, false, index, dataModel);
                default:
                    return (<></>);
            }
        }
    };

    /** Combos */
    const RowComboItem = (row: FishingProfileItemTypeLine, index: number, dataModel: ItemTypeNotificationConfigLineModel | null) => {

        var simpleStringSelectItem: SelectValueStringModel | null = null;
        if (dataModel?.comboValue) {
            var desc: string = (row.source as any[]).filter(s => s.item1 == dataModel?.comboValue)[0].item2;
            simpleStringSelectItem = {
                value: dataModel?.comboValue,
                description: desc
            };
        }
        //Combo de Species
        return (
            <SelectComponent
                size='small'
                sx={{ minWidth: '100%', mb: 2 }}
                control={control}
                defaultValue={simpleStringSelectItem}
                name={`lines.${index}.comboValue`}
                label={row.label}
                errors={undefined}
                optionsLabel={'description'}
                optionsValue={'value'}
                options={(row.source as any[]).map((s: any) => ({ value: s.item1, description: s.item2 }))}
                disabled={false}
                multiple={false}
                rules={undefined}
                chip={undefined}
            />
        );
    };

    /** Testos */
    const RowTextBoxItem = (row: FishingProfileItemTypeLine, index: number, dataModel: ItemTypeNotificationConfigLineModel | null) => {
        return (
            <TextFieldComponent
                sx={{ with: '100%', minWidth: '100%', mb: 2 }}
                control={control}
                name={`lines.${index}.tbLikeValue`}
                defaultValue={dataModel?.tbLikeValue}
                size={'small'}
                label={row.label}
                required={false}
                disabled={false}
                InputProps={undefined}
                autoComplete={undefined}
                autoFocus={undefined}
                errors={undefined}
                rules={undefined}
                type={undefined}
                rows={undefined}
            />
        );
    };

    /** Numeros */
    const RowNumberItem = (row: FishingProfileItemTypeLine, withDecimals: boolean, index: number, dataModel: ItemTypeNotificationConfigLineModel | null) => {
        return (
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: '40%', marginRight: '20%' }}>
                    <TextFieldComponent
                        control={control}
                        name={`lines.${index}.tbMinValue`}
                        defaultValue={dataModel?.tbMinValue}
                        label={`${row.label} Min`}
                        type={'number'}
                        size={'small'}
                        sx={{ with: '100%', minWidth: '100%' }}
                        disabled={false}
                        required={false}
                        InputProps={undefined}
                        autoComplete={undefined}
                        autoFocus={undefined}
                        errors={undefined}
                        rules={undefined}
                        rows={undefined}
                    />
                </Box>
                <Box sx={{ width: '40%' }}>
                    <TextFieldComponent
                        control={control}
                        name={`lines.${index}.tbMaxValue`}
                        type={'number'}
                        size={'small'}
                        sx={{ with: '100%', minWidth: '100%' }}
                        defaultValue={dataModel?.tbMaxValue}
                        label={`${row.label} Max`}
                        disabled={false}
                        required={false}
                        InputProps={undefined}
                        autoComplete={undefined}
                        autoFocus={undefined}
                        errors={undefined}
                        rules={undefined}
                        rows={undefined}
                    />
                </Box>
            </Box>
        );
    };

    /** Item Type section */
    return (
        <FieldsetComponent title={t('organization-itemtypes.form.itemType-fields')} style={{ width: '100%' }}>
            <Box style={{ width: '100%' }}>
                {
                    fishingProfileItemTypeLineList.map((row: FishingProfileItemTypeLine, index: number) => {
                        return (
                            <Box sx={{ Width: '100%', marginTop: '3px' }} key={`${row.lineNo}`}>
                                {/* <div>{row.label}</div> */}
                                <TextFieldComponent
                                    control={control}
                                    name={`lines.${index}.lineNo`}
                                    defaultValue={row.lineNo}
                                    type={'number'}
                                    size={'small'}
                                    sx={{ with: '100%', display: 'none' }}
                                    label={'Min'}
                                    disabled={true}
                                    required={false}
                                    InputProps={undefined}
                                    autoComplete={undefined}
                                    autoFocus={undefined}
                                    errors={undefined}
                                    rules={undefined}
                                    rows={undefined}
                                />
                                {RowModel(row, index)}
                            </Box>
                        );
                    })
                }
            </Box>
        </FieldsetComponent>
    );
};

export default ConfItemTypeSection;