import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../../../constants'
import { ApiClientContext } from '../../../ApiClientProvider'
import { prepareParams } from '../../../ServicesUtils'

export const useOrganizationVesselsFilterRequest = () => {
    const { client } = useContext(ApiClientContext)
    const [orgVesselsFilter, setOrgVesselsFilter] = useState(null)
    const [errorOrgVesselsFilter, setErrorOrgVesselsFilter] = useState(null)
    const [isLoadingOrgVesselsFilter, setIsLoadingOrgVesselsFilter] = useState(false)

    //Carga inicial
    const organizationFilterRequest = useCallback(
        (filter) => {
            setIsLoadingOrgVesselsFilter(true);
            client
                .get(baseUrl + 'horus/admin/organization/vessel/config/filter?organizationId=' + filter)
                .then((result) => {
                    setIsLoadingOrgVesselsFilter(false)
                    if (result.data.success) {
                        setOrgVesselsFilter(result.data.data)
                    } else {
                        setErrorOrgVesselsFilter({ statusText: result.data.error })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoadingOrgVesselsFilter(false)
                    setErrorOrgVesselsFilter(err.response)
                })
        }, [client])
    return {
        organizationFilterRequest,
        isLoadingOrgVesselsFilter,
        orgVesselsFilter,
        errorOrgVesselsFilter,
    }
}