import Box from '@mui/material/Box'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { jwtCookieName, modeTypesCRUD } from '../../constants'
import BasicDialog from '../comun/BasicDialog'
import SelectComponent from '../comun/form_fields/SelectComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'
import { isContainRequiredWord } from '../../utils/customFunctions'
import { readCookie } from '../../utils/cookie'

export default function UsersForm({ selectedUser, mode, onClose, open, onSave, rolesList }) {
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: 'onBlur' })

	useEffect(() => {
		reset(selectedUser)
	}, [selectedUser])

	const [t] = useTranslation('common')
	const nameMaxLength = 100
	const userNameMaxLength = 255
	const surnameMaxLength = 255
	const emaiMaxLength = 255
	let token = readCookie(jwtCookieName)
	const parseJwt = (token) => {
		var base64Url = token.split('.')[1]
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
				})
				.join('')
		)
		return JSON.parse(jsonPayload)
	}
	var requiredUserNameWord = parseJwt(token).org

	const getTitle = () => {
		if (modeTypesCRUD.UPDATE_MODE === mode) {
			return t('users.form.title-update')
		} else if (modeTypesCRUD.INSERT_MODE === mode) {
			return t('users.form.title-new')
		}
	}

	return (
		<Box component='form' onSubmit={handleSubmit(onSave)} id='global'>
			<BasicDialog
				title={getTitle()}
				onOpen={open}
				onClose={onClose}
				mode={mode}
				saveTxt={t('comun.actions.save')}>
				<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '80vh' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<TextFieldComponent
							sx={{ m: 1, width: '50%' }}
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								},
								maxLength: {
									value: userNameMaxLength,
									message: `${t('comun.form-errors.max-length')} ${userNameMaxLength}`,
								},
								validate: (currentUserName) =>
									isContainRequiredWord(currentUserName, requiredUserNameWord) ||
									t('users.form.contain-word') + requiredUserNameWord,
							}}
							control={control}
							label={t('users.form.username')}
							name='userName'
							size='small'
							errors={errors?.userName}
							disabled={mode === modeTypesCRUD.UPDATE_MODE}
						/>
						<TextFieldComponent
							sx={{ m: 1, width: '50%' }}
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								},
								maxLength: {
									value: nameMaxLength,
									message: `${t('comun.form-errors.max-length')} ${nameMaxLength}`,
								},
							}}
							control={control}
							label={t('users.form.name')}
							name='name'
							size='small'
							errors={errors?.name}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<TextFieldComponent
							sx={{ m: 1, width: '50%' }}
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								},
								maxLength: {
									value: surnameMaxLength,
									message: `${t('comun.form-errors.max-length')} ${surnameMaxLength}`,
								},
							}}
							control={control}
							label={t('users.form.surname')}
							name='surname'
							size='small'
							errors={errors?.surname}
						/>
						<TextFieldComponent
							sx={{ m: 1, width: '50%' }}
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								},
								maxLength: {
									value: emaiMaxLength,
									message: `${t('comun.form-errors.max-length')} ${emaiMaxLength}`,
								},
								pattern: {
									value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, //eslint-disable-line
									message: t('users.form.email-error'),
								},
							}}
							control={control}
							label={t('users.form.email')}
							name='email'
							size='small'
							errors={errors?.email}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<SelectComponent
							size='small'
							sx={{ m: 1, width: '100%' }}
							control={control}
							name='roles'
							label={t('users.form.roles')}
							errors={errors?.roles}
							multiple
							chip
							options={rolesList}
							optionsLabel={'description'}
							optionsValue={'value'}
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								},
							}}></SelectComponent>
					</Box>
				</Box>
			</BasicDialog>
		</Box>
	)
}

UsersForm.defaultProps = {
	mode: modeTypesCRUD.INSERT_MODE,
	open: false,
	close: () => {},
}
