import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, IconButton, Chip, Tooltip, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from '@mui/material/FormControl';
import FieldsetComponent from "../../comun/FieldsetComponent";
import BackspaceIcon from '@mui/icons-material/Backspace';

export default function MultipleSelectionCombo({ methodsForm, options, initialSelectedItems, title, label, valueName }) {

    const {
        register,
        setValue,
    } = methodsForm

    /* == VARIABLES== */
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [visibleOptions, setVisibleOptions] = useState(options.slice(0, 20));

    const [t] = useTranslation('common');

    const disableAddButton = !selectedOption;

    const optionsWithSelectAll =
        [
            { id: -1, description: t('comun.actions.select-all') },
            ...options.sort((a, b) => a.description.localeCompare(b.description))
        ];

    /* == FUNCTIONS == */
    const handleAddItem = () => {
        if (selectedOption) {
            const itemExist = selectedItems.some(item => item.id === selectedOption.id)
            if (selectedOption.id === -1) {
                // Si se seleccionó "Seleccionar todos"
                const allItems = options.filter(option => !selectedItems.includes(option));
                setSelectedItems([...selectedItems, ...allItems]);
            } else if (!itemExist) {
                // Si se selecciona una opción
                setSelectedItems([...selectedItems, selectedOption]);
            }
            setSelectedOption(null);
        }
    };

    useEffect(() => {
        console.log(options, initialSelectedItems, valueName)
    }, []);

    const handleDeleteItem = (itemToDelete) => {
        setSelectedItems(selectedItems.filter((item) => item !== itemToDelete));
    };

    const handleDeleteAll = () => {
        setSelectedItems([]);
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);

        // Si el input no está vacío, filtrar las opciones por coincidencias
        const filtered = optionsWithSelectAll.filter(option =>
            option.description && option.description.toLowerCase().includes(newInputValue.toLowerCase())
        );

        // Mostrar solo las primeras 20 opciones filtradas
        setVisibleOptions(filtered.slice(0, 20));
    };

    /** Función para cargar más opciones cuando se llegue al final del listado */
    const handleLoadMore = () => {
        const nextOptions = optionsWithSelectAll.slice(visibleOptions.length, visibleOptions.length + 20);
        setVisibleOptions((prevVisibleOptions) => [...prevVisibleOptions, ...nextOptions]);
    };


    /** Cargar elementos seleccionados al editar */
    useEffect(() => {
        if (initialSelectedItems && initialSelectedItems.length > 0) {
            setSelectedItems(initialSelectedItems);
        }
    }, [initialSelectedItems]);

    /** Efecto que carga el campo y lo resetea cuando añade una */
    useEffect(() => {
        setVisibleOptions(optionsWithSelectAll.slice(0, 20)); // Reiniciar las opciones visibles cuando cambien los datos
        setInputValue('')
    }, [options]);

    // useEffect(() => {
    //     setInputValue('')
    // }, [options]);

    useEffect(() => {
        setValue(valueName, selectedItems)
        methodsForm
    }, [selectedItems])

    return (
        <div>
            <FieldsetComponent title={title}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {/* Input Autocomplete */}
                    <Autocomplete sx={{ width: '100%' }}
                        size="small"
                        value={selectedOption}
                        onChange={(event, newValue) => setSelectedOption(newValue)}
                        inputValue={inputValue}
                        options={visibleOptions}
                        getOptionLabel={(option) => option.description}
                        onInputChange={handleInputChange}
                        ListboxProps={{
                            onScroll: (event) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                    handleLoadMore();
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label={label} />
                        )}
                    />
                    {/* Botones para añadir y borrar todos */}
                    <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        {/* Botón para añadir */}
                        <FormControl
                            variant="standard"
                            size="small"
                            sx={{ width: "40px", alignSelf: "flex-end" }}>
                            <Tooltip title={t('comun.actions.add')}>
                                <span>
                                    <IconButton
                                        color="primary"
                                        disabled={disableAddButton}
                                        onClick={handleAddItem}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </FormControl>

                        {/* Botón para borrar todos */}
                        <FormControl
                            variant="standard"
                            size="small"
                            sx={{ width: "40px", alignSelf: "flex-end" }}>
                            <Tooltip title={t('comun.actions.select-all')}>
                                <span>
                                    <IconButton
                                        color="secondary"
                                        disabled={selectedItems.length === 0}
                                        onClick={handleDeleteAll}>
                                        <BackspaceIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </FormControl>
                    </Stack>
                </div>
                {/* Listado de elementos seleccionados con opción para eliminar */}
                {selectedItems.length > 0 &&
                    <div {...register(valueName)} style={{
                        marginTop: "20px",
                        maxHeight: "100px",
                        overflowY: "auto",
                        border: '1px solid #ddd',
                        padding: '5px'
                    }}>
                        {selectedItems.map((item) => (
                            <Chip
                                key={item.id}
                                label={item.description}
                                onDelete={() => handleDeleteItem(item)}
                                deleteIcon={<DeleteIcon />}
                                style={{ margin: "5px" }}
                            />
                        ))}
                    </div>
                }
            </FieldsetComponent>
        </div>
    );
}
