import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useZoneAlertFilterRequest = () => {
	const { client } = useContext(ApiClientContext)
	const [zoneAlertFilter, setZoneAlertFilter] = useState(null)
	const [errorZoneAlertFilter, setErrorZoneAlertFilter] = useState(null)
	const [isLoadingZoneAlertFilter, setIsLoadingZoneAlertFilter] = useState(false)
	

	const zoneAlertFilterRequest = useCallback(() => {
		setIsLoadingZoneAlertFilter(true);
    	setZoneAlertFilter(null);
    	setErrorZoneAlertFilter(null);

		client
			.get(baseUrl + 'horus/f3/notification/zone/filter')
			.then((result) => {
				setIsLoadingZoneAlertFilter(false)

				if (result.data.success) {
					setZoneAlertFilter(result.data.data)
				} else {
					setErrorZoneAlertFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoadingZoneAlertFilter(false)
				setErrorZoneAlertFilter(err.response)
			})
	}, [client])
	return {
		zoneAlertFilterRequest,
		isLoadingZoneAlertFilter,
		zoneAlertFilter,
		errorZoneAlertFilter,
	}
}