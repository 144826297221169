import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ReplayIcon from '@mui/icons-material/Replay'
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import SailingIcon from '@mui/icons-material/Sailing';
import { useTranslation } from 'react-i18next'

import { modeTypesCRUD } from '../../../constants'

import TablePaginationComponent from '../../comun/TablePaginationComponent'
import { EnhancedTableHead } from '../../comun/TableUtils'


export default function OrganizationsTable({ organizationList, filter, onFilter, handleFormOrganizations, handleOpenNotificationConfiguration, handleOpenOrganizationVessels }) {
  const [t] = useTranslation('common')
  

  // Tabla
  const [order, setOrder] = useState(filter.orderDirection)
  const [orderBy, setOrderBy] = useState(filter.orderField)
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(filter.pageSize)

  useEffect(() => {
    if (filter) {
      setRowsPerPage(filter.pageSize)
      setOrder(filter.orderDirection)
      setOrderBy(filter.orderField)
    }
  }, [filter])

  const handleChangePage = (event, newPage) => {
    let newFilter = { ...filter }
    newFilter.pageNo = newPage + 1
    onFilter(newFilter)
  }

  const handleChangeRowsPerPage = (event) => {
    let newFilter = { ...filter }
    newFilter.pageSize = event.target.value
    newFilter.pageNo = 1
    onFilter(newFilter)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    let newFilter = { ...filter }
    newFilter.orderDirection = isAsc ? 'desc' : 'asc'
    newFilter.orderField = property
    newFilter.pageNo = 1
    onFilter(newFilter)
  }

  useEffect(() => {
    if (organizationList) {
      setPage(organizationList.pageNo - 1)
      setTotalCount(organizationList.totalCount)
    }
  }, [organizationList])

  const handleAddAction = () => {
    handleFormOrganizations(null, modeTypesCRUD.INSERT_MODE)
  }

  //Table coluns
  const columns = [
    {
      id: 'name',
      align: 'left',
      orderBy: true,
      label: t('organizations.table.header.name'),
    },
    {
      id: 'orgPrefix',
      align: 'left',
      orderBy: true,
      label: t('organizations.table.header.orgPrefix'),
    },
    {
      id: 'description',
      align: 'left',
      orderBy: true,
      label: t('organizations.table.header.description'),
    },
    {
      id: 'actions',
      align: 'center',
      label: '',
      excluirExcel: true,
    },
  ]

  return (
    <TableContainer>
      <Table
        id="table-organizations-list"
        aria-labelledby="tableTitle"
        size="small"
        sx={{ minWidth: 750 }}
        style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={columns}
          addAction={handleAddAction}
        />
        <TableBody>
          {organizationList
             ? organizationList.data.map((row, index) => (           
                <TableRow key={index}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.orgPrefix}</TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                  <TableCell align="center">
                    <Tooltip title={t('organizations.table.content.update')}>
                      <IconButton
                        color="primary"
                        onClick={() => handleFormOrganizations(row, modeTypesCRUD.UPDATE_MODE)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {!row.isDeleted ? (
                      <Tooltip title={t('organizations.table.content.delete')}>
                        <IconButton
                          color="error"
                          onClick={() => handleFormOrganizations(row, modeTypesCRUD.DELETE_MODE)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('organizations.table.content.enable')}>
                        <IconButton
                          color="primary"
                          onClick={() => handleFormOrganizations(row)}
                        >
                          <ReplayIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title={t('organizations.table.content.config-notif-alert')}>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenNotificationConfiguration(row)}>
                        <EditNotificationsIcon/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('organizations.table.content.redirect-organizations-vessel')}>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenOrganizationVessels(row)}>
                        <SailingIcon/>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
        <TableFooter className="excluir-excel">
          <TableRow style={{ height: 52 }}>
            <TablePaginationComponent
              rowsPerPageOptions={[5, 10, 25]}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              customActions={true}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
