import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../../services/ApiClientProvider'

export const useProjectItemUpdate = () => {
	const [dataProjectItemUpdate, setDataProjectItemUpdate] = useState(null)
	const [errorProjectItemUpdate, setErrorProjectItemUpdate] = useState(null)
	const [isLoadingProjectItemUpdate, setIsLoadingProjectItemUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemUpdate = useCallback(
		(props) => {
			//We don't need to send graphicDataList to the WS
			//and it's causing error, so we clear it and 
			//if call fails then restore it to props
			var graphicDataListRef = props.graphicDataList
			props.graphicDataList = []
			client
				.put(baseUrl + 'horus/f3/project/item', props)
				.then((result) => {
					setIsLoadingProjectItemUpdate(false)
					if (result.status === 200 && result.data.success) {
						setDataProjectItemUpdate(result.data.data)
					} else {
						props.graphicDataList = graphicDataListRef
						setErrorProjectItemUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					props.graphicDataList = graphicDataListRef
					setErrorProjectItemUpdate(error.response)
					setIsLoadingProjectItemUpdate(false)
				})

			setIsLoadingProjectItemUpdate(true)
			setDataProjectItemUpdate(null)
			setErrorProjectItemUpdate(null)
		},
		[client]
	)

	return {
		projectItemUpdate,
		dataProjectItemUpdate,
		errorProjectItemUpdate,
		isLoadingProjectItemUpdate,
	}
}
