import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../../../constants'
import { ApiClientContext } from '../../../ApiClientProvider'

export const useOrganizationVesselsFavFilterPost = () => {
    const [dataFilter, setDataFilter] = useState(null)
    const [errorPostFilter, setErrorFilter] = useState(null)
    const [isLoadingFilter, setIsLoadingFilter] = useState(false)

    const { client } = useContext(ApiClientContext)

    const saveFavouriteFilter = useCallback(
        (props) => {
            client
            .post(baseUrl+'horus/admin/organization/vessel/config/filter/favourite', props)
            .then((result) => {
                setIsLoadingFilter(false)

                if (result.status === 200 && result.data.success) {
                    setDataFilter({ data: result.data })
                } else {
                    setErrorFilter({ statusText: result.data.errorMsg })
                }
                
                setIsLoadingFilter(false)
            })
            .catch((error) => {
                setErrorFilter(error.response)
                setIsLoadingFilter(false)
            })

            setDataFilter(null)
            setErrorFilter(null)
            setIsLoadingFilter(true)
    },
    [client]
)

  return {
      dataFilter,
      errorPostFilter,
      isLoadingFilter,
      saveFavouriteFilter
  }
}