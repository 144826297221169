import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useVesselActivate = () => {
    const [vesselDataActivate, setVesselDataActivate] = useState(null)
    const [errorVesselActivate, setErrorVesselActivate] = useState(null)
    const [isLoadingVesselActivate, setIsLoadingVesselActivate] = useState(false)

    const { client } = useContext(ApiClientContext)
    const vesselActivate = useCallback(
        (vessel) => {
            client
                .put(baseUrl + 'horus/admin/undelete/vessel', vessel)
                .then((result) => {
                    setIsLoadingVesselActivate(false)
                    if (result.status === 200 && result.data.success) {
                        setVesselDataActivate({ data: result.data })
                    } else {
                        setErrorVesselActivate({ statusText: result.data.errorMsg })
                    }
                })
                .catch((error) => {
                    setErrorVesselActivate(error.response)
                    setIsLoadingVesselActivate(false)
                })

            setIsLoadingVesselActivate(true)
            setVesselDataActivate(null)
            setErrorVesselActivate(null)
        },
        [client]
    )

    return {
        vesselActivate,
        isLoadingVesselActivate,
        vesselDataActivate,
        errorVesselActivate,
        setErrorVesselActivate,
    }
}
