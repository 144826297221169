import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useVesselAlertFilterRequest = () => {
	const { client } = useContext(ApiClientContext)
	const [vesselAlertFilter, setVesselAlertFilter] = useState(null)
	const [errorVesselAlertFilter, setErrorVesselAlertFilter] = useState(null)
	const [isLoadingVesselAlertFilter, setIsLoadingVesselAlertFilter] = useState(false)
	

	const vesselAlertFilterRequest = useCallback(() => {
		setIsLoadingVesselAlertFilter(true);
    	setVesselAlertFilter(null);
    	setErrorVesselAlertFilter(null);

		client
			.get(baseUrl + 'horus/f3/notification/vessel/filter')
			.then((result) => {
				setIsLoadingVesselAlertFilter(false)

				if (result.data.success) {
					setVesselAlertFilter(result.data.data)
				} else {
					setErrorVesselAlertFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoadingVesselAlertFilter(false)
				setErrorVesselAlertFilter(err.response)
			})
	}, [client])
	return {
		vesselAlertFilterRequest,
		isLoadingVesselAlertFilter,
		vesselAlertFilter,
		errorVesselAlertFilter,
	}
}