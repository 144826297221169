import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import RateReviewIcon from '@mui/icons-material/RateReview'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { modeTypesCRUD } from '../../constants'
import '../../index.css'
import ConfirmationDialog from '../comun/ConfirmationDialog'
import MessageSnackBar from '../comun/MessageSnackBar'
import { StyledTableRow } from '../comun/StyleComponentUtils'

import { styled } from '@mui/material/styles'

import { getDeclarationIconAux } from '../comun/NotesIconHelper'

import {
	Badge,
	Chip,
	Divider,
	IconButton,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material'
import { useProjectItemCommentInsert } from '../../services/projectitem/comments/useProjectItemCommentInsert'
import { useProjectItemInsert } from '../../services/projectitem/useProjectItemInsert'
import { EnhancedTableHead } from '../comun/TableUtils'
import AddCommentDialog from './AddCommentDialog'

import { useEffect, useState } from 'react'
import { useProjectItemCommentDelete } from '../../services/projectitem/comments/useProjectItemCommentDelete'
import { useProjectItemCommentUpdate } from '../../services/projectitem/comments/useProjectItemCommentUpdate'
import { useProjectItemReadComments } from '../../services/projectitem/comments/useProjectItemReadComments'
import { useProjectItemUpdate } from '../../services/projectitem/useProjectItemUpdate'
import BasicDialog from '../comun/BasicDialog'

export default function FormAnotacionesDinamico({
	fishingProfileList,
	tipoNota,
	tipoNotaDisabled,
	onOpen,
	onClose,
	mode,
	noteProp,
	projectId,
	index,
	canCreateEditAnnotations,
	canReviewAnnotations,
	showReviewComments,
}) {
	const [t] = useTranslation('common')

	const [dialogTitle, setDialogTitle] = useState()
	const [dialogSaveButton, setDialogSaveButton] = useState()
	const [dialogCancelButton, setDialogCancelButton] = useState()
	const [dialogContent, setDialogContent] = useState()
	const [openConfirmacion, setOpenConfirmacion] = useState(false)
	const [openComentario, setOpenComentario] = useState(false)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeMessage] = useState(null)
	const [tipoNotaSelected, setTipoNota] = useState()
	const [values, setValues] = useState([])
	const [errors, setErrors] = useState([])
	const [errorsText, setErrorsText] = useState([])
	const [selectedCommentsList, setSelectedCommentsList] = useState([])
	const [selectedComment, setSelectedComment] = useState({})
	const [selectedCommentIdx, setSelectedCommentIdx] = useState()
	const [selectedIdx, setSelectedIdx] = useState()
	const [modeComments, setModeComments] = useState(null)
	const [note, setNote] = useState(noteProp)
	const [tipoNotaACambiar, setTipoNotaACambiar] = useState(null)
	const [tipoModal, setTipoModal] = useState(null)

	//Services
	const { projectItemInsert, isLoadingProjectItemInsert, dataProjectItemInsert, errorProjectItemInsert } = useProjectItemInsert()
	const { projectItemUpdate, dataProjectItemUpdate, errorProjectItemUpdate } = useProjectItemUpdate()

	//Comments Services
	const { projectItemCommentInsert, dataProjectItemCommentInsert, errorProjectItemCommentInsert } =
		useProjectItemCommentInsert()

	const { projectItemCommentUpdate, dataProjectItemCommentUpdate, errorProjectItemCommentUpdate } =
		useProjectItemCommentUpdate()

	const { projectItemCommentDelete, dataProjectItemCommentDelete, errorProjectItemCommentDelete } =
		useProjectItemCommentDelete()

	const { readComment } = useProjectItemReadComments()

	useEffect(() => {
		if (noteProp) {
			setNote(noteProp)
		}
	}, [noteProp])

	useEffect(() => {
		if (note && note.itemType) {
			let item = fishingProfileList.itemTypeList.find((item) => item.itemType == note.itemType)
			if (item.lineList.length == 0) {
				guardar()
			}
		}
	}, [note])

	useEffect(() => {
		if (dataProjectItemInsert) {
			onClose(index, true, dataProjectItemInsert)
		}
		if (dataProjectItemUpdate) {
			onClose(index, true, dataProjectItemUpdate)
		}
	}, [dataProjectItemInsert, dataProjectItemUpdate])

	useEffect(() => {
		if (errorProjectItemInsert) {
			setErrorMsg(errorProjectItemInsert)
			setOpenError(true)
		} else if (errorProjectItemUpdate) {
			setErrorMsg(errorProjectItemUpdate)
			setOpenError(true)
		} else if (errorProjectItemCommentInsert) {
			setErrorMsg(errorProjectItemCommentInsert)
			setOpenError(true)
		} else if (errorProjectItemCommentUpdate) {
			setErrorMsg(errorProjectItemCommentUpdate)
			setOpenError(true)
		} else if (errorProjectItemCommentDelete) {
			setErrorMsg(errorProjectItemCommentDelete)
			setOpenError(true)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [
		errorProjectItemInsert,
		errorProjectItemUpdate,
		errorProjectItemCommentInsert,
		errorProjectItemCommentUpdate,
		errorProjectItemCommentDelete,
	])

	useEffect(() => {
		if (dataProjectItemCommentUpdate) {
			let auxComments = [...selectedCommentsList]
			auxComments[selectedCommentIdx] = dataProjectItemCommentUpdate
			setSelectedCommentsList(auxComments)
			let aux = { ...note }
			aux.lineList[selectedIdx].commentList[selectedCommentIdx] = dataProjectItemCommentUpdate
			setNote(aux)
		}
	}, [dataProjectItemCommentUpdate])

	useEffect(() => {
		if (dataProjectItemCommentInsert) {
			let auxComments = [...selectedCommentsList]
			auxComments.push(dataProjectItemCommentInsert)
			setSelectedCommentsList(auxComments)
			let aux = { ...note }
			aux.lineList[selectedIdx].commentList.push(dataProjectItemCommentInsert)
			setNote(aux)
		}
	}, [dataProjectItemCommentInsert])

	useEffect(() => {
		if (dataProjectItemCommentDelete) {
			let auxComments = [...selectedCommentsList]
			auxComments.splice(selectedCommentIdx, 1)
			setSelectedCommentsList(auxComments)
			let aux = { ...note }
			aux.lineList[selectedIdx].commentList.splice(selectedCommentIdx, 1)
			setNote(aux)
		}
	}, [dataProjectItemCommentDelete])

	const [order, setOrder] = useState('asc')
	const [orderBy, setOrderBy] = useState('id')

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const columns = [
		{
			id: 'author',
			label: t('anotaciones-form.table.header.author'),
		},
		{
			id: 'comment',
			label: t('anotaciones-form.table.header.comment'),
		},
		{
			id: 'actions',
			label: '',
			excluirExcel: true,
		},
	]

	const handleCloseError = () => {
		setOpenError(false)
	}

	useEffect(() => {
		setTipoNota(tipoNota)
	}, [tipoNota])

	const handleAddAction = () => {
		openModalComentario(null, null)
		setModeComments('INSERT_COMMENTS')
	}

	const handleCommentAction = (row, mode, idx) => {
		openModalComentario(row, idx)
		setSelectedCommentIdx(idx)
		setModeComments(mode)
	}

	const handleDeleteCommentAction = (row, idx) => {
		setSelectedCommentIdx(idx)
		setSelectedComment(row)
		projectItemCommentDelete({ projectItemLineCommentId: row.projectItemLineCommentId })
	}

	const openModalComentario = (row, idx) => {
		setSelectedComment(row ? row : null)
		setSelectedCommentIdx(idx)
		setOpenComentario(true)
	}

	const closeModalComentario = (continuar, row, idx, mode) => {
		setOpenComentario(false)
		if (continuar) {
			saveNewComment(row, mode)
		}
	}

	const openModalConfirmacion = (tipoModalParam) => {
		setTipoModal(tipoModalParam)
		if (tipoModalParam) {
			if (tipoModalParam === 1) {
				setDialogTitle(t('anotaciones-form.validacion.dialog.title-error'))
				setDialogSaveButton(t('anotaciones-form.validacion.dialog.save-button'))
				setDialogCancelButton(t('anotaciones-form.validacion.dialog.cancel-button'))
				setDialogContent(t('anotaciones-form.validacion.dialog.content-error'))
			} else if (tipoModalParam === 2) {
				setDialogTitle(t('anotaciones-form.validacion.dialog.title-data-lost'))
				setDialogSaveButton(t('anotaciones-form.validacion.dialog.ok-button'))
				setDialogCancelButton(t('anotaciones-form.validacion.dialog.cancel-button'))
				setDialogContent(t('anotaciones-form.validacion.dialog.content-data-lost'))
			}
			setOpenConfirmacion(true)
		}
	}

	const closeModalConfirmacion = (continuar) => {
		setOpenConfirmacion(false)
		if (continuar) {
			if (tipoModal === 1) {
				guardar(true)
			} else if (tipoModal === 2) {
				cambiarTipoNota(tipoNotaACambiar)
			}
		}
	}

	function getMultiline(param) {
		if (param) {
			return { multiline: true }
		}
	}

	function esDecimal(valor) {
		var regex = /(^[+-]?\d+(?:[.,]\d+)?$)/i
		return valor.match(regex)
	}

	function esNumero(valor) {
		var regex = /^(\d*)$/i
		return valor.match(regex)
	}

	function cumpleRegex(valor, pattern) {
		if (pattern != null) {
			let regex = new RegExp('^[' + pattern + ']$')
			return regex.test(valor)
		} else {
			return true
		}
	}

	function validarForm() {
		let errores = []
		let textoErrores = []
		fishingProfileList.itemTypeList.map((item) => {
			if (item.itemType === tipoNotaSelected) {
				item.lineList.map((elemento, idx) => {
					if (!values[idx]) {
						if (elemento.isRequired) {
							textoErrores[idx] = t('anotaciones-form.validacion.campo-obligatorio')
						}
					} else {
						if (elemento.inputType === 'DECIMAL') {
							if (!esDecimal(values[idx])) {
								errores[idx] = true
								textoErrores[idx] = t('anotaciones-form.validacion.formato-decimal')
							}
						}
						if (elemento.inputType === 'INTEGER') {
							if (!esNumero(values[idx])) {
								errores[idx] = true
								textoErrores[idx] = t('anotaciones-form.validacion.formato-entero')
							}
						}
						if (elemento.allowedChars || elemento.allowedChars != '') {
							if (!cumpleRegex(values[idx], elemento.allowedChars)) {
								errores[idx] = true
								textoErrores[idx] = t('anotaciones-form.validacion.valor-invalido')
							}
						}
					}
				})
				setErrors(errores)
				setErrorsText(textoErrores)
			}
		})
		if (textoErrores.length > 0) {
			openModalConfirmacion(1)
		}
		return !(textoErrores.length > 0)
	}

	function validarCampo(idx, elemento, value) {
		let errores = [...errors]
		let textoErrores = [...errorsText]
		if (!value) {
			if (elemento.isRequired) {
				textoErrores[idx] = t('anotaciones-form.validacion.campo-obligatorio')
			} else {
				errores.splice(idx, 1)
				textoErrores[idx] = ''
			}
		} else {
			errores[idx] = undefined
			textoErrores[idx] = ''
			if (elemento.inputType === 'DECIMAL') {
				if (!esDecimal(value)) {
					errores[idx] = true
					textoErrores[idx] = t('anotaciones-form.validacion.formato-decimal')
				} else {
					errores.splice(idx, 1)
					textoErrores[idx] = ''
				}
			}
			if (elemento.inputType === 'INTEGER') {
				if (!esNumero(value)) {
					errores[idx] = true
					textoErrores[idx] = t('anotaciones-form.validacion.formato-entero')
				} else {
					errores.splice(idx, 1)
					textoErrores[idx] = ''
				}
			}
			if (elemento.allowedChars || elemento.allowedChars != '') {
				if (!cumpleRegex(value, elemento.allowedChars)) {
					errores.splice(idx, 1)
					textoErrores[idx] = t('anotaciones-form.validacion.valor-invalido')
				} else {
					errores.splice(idx, 1)
					textoErrores[idx] = ''
				}
			}
		}
		setErrors(errores)
		setErrorsText(textoErrores)

		isFormValido(textoErrores)

		return !(textoErrores.length > 0)
	}

	function guardar(continueWithErrors) {
		if (validarForm() || continueWithErrors == true) {
			let lineList = fishingProfileList.itemTypeList.find(
				(item) => item.itemType == tipoNotaSelected || item.itemType == tipoNota
			).lineList
			lineList = lineList.map((l, idx) => {
				let line = {
					lineNo: l.lineNo,
					ediField: l.ediField,
					value: values[idx],
					commentList: selectedCommentsList.filter((comment) => comment.label === l.label),
				}
				return line
			})

			let auxNote = {}
			if (mode === modeTypesCRUD.UPDATE_MODE) {
				auxNote = note
				auxNote.lineList = lineList
			} else {
				auxNote = {
					projectId: projectId,
					itemType: tipoNotaSelected ? tipoNotaSelected : tipoNota,
					camNo: note.camNo,
					offset: Math.floor(note.offset),
					itemDateTime: note.itemDateTime,
					fileId: note.videoId,
					lineList: lineList,
					fileType: 'VI',
					course: note.course,
					latitude: note.latitude,
					longitude: note.longitude,
					speed: note.speed,
				}
			}
			mode === modeTypesCRUD.INSERT_MODE ? projectItemInsert(auxNote) : projectItemUpdate(auxNote)
		}
	}

	const changeNoteType = (event) => {
		if (values.length > 0) {
			setTipoNotaACambiar(event.target.value)
			openModalConfirmacion(2)
		} else {
			cambiarTipoNota(event.target.value)
		}
	}

	function cambiarTipoNota(value) {
		setTipoNota(value)
		resetForm()
	}

	function resetForm() {
		setValues([])
		setErrorsText([])
		setErrors([])
	}
	useEffect(() => {
		if (mode === modeTypesCRUD.UPDATE_MODE) {
			let aux = note.lineList.map((line) => {
				return line.value
			})
			setValues(aux)
			openComments(0)
			// setSelectedIdx(0)
			// setSelectedCommentsList(note.lineList.length != 0 ? note.lineList[0].commentList : null)
		}
	}, [])

	function changeValue(target, idx, elemento) {
		let newValues = [...values]
		newValues[idx] = target.value
		setValues(newValues)
		setSelectedIdx(idx)
		if (mode === modeTypesCRUD.INSERT_MODE) {
			validarCampo(idx, elemento, target.value)
		}
	}

	function saveNewComment(row, mode) {
		if (mode === 'INSERT_COMMENTS') {
			let commentInsert = {
				projectItemLineCommentId: '999',
				projectItemId: note.projectItemId,
				lineNo: note.lineList[selectedIdx].lineNo,
				comment: row,
			}
			projectItemCommentInsert(commentInsert)
		} else if (mode === 'UPDATE_COMMENTS') {
			let commentUpdate = {
				projectItemLineCommentId: selectedComment.projectItemLineCommentId,
				projectItemId: selectedComment.projectItemId,
				lineNo: selectedComment.lineNo,
				comment: row,
			}
			projectItemCommentUpdate(commentUpdate)
		}
	}

	function openComments(idx) {
		if (mode === modeTypesCRUD.UPDATE_MODE) {
			readComment(projectId, note.projectItemId, idx + 1)
			setSelectedIdx(idx)
			setSelectedCommentsList(note.lineList[idx] != null ? note.lineList[idx].commentList : [])
		}
	}

	function getFocusProps(idx) {
		if ((!errors[idx] && errorsText[idx]) || selectedIdx === idx) {
			return {
				focused: true,
			}
		}
	}

	function isFormValido(textoErrores) {
		let resultado = true
		if (textoErrores && textoErrores.length > 0) {
			textoErrores.forEach((elemento) => {
				if (elemento && elemento.length > 0) {
					resultado = false
				}
			})
		}
		if (values && values.length > 0) {
			values.forEach((elemento) => {
				if (elemento && elemento.length > 0) {
					//Se puede guardar sin que se rellene ningún campo
					resultado = false
				}
			})
		}
		return resultado
	}

	const StyledBadge = styled(Badge)(({ theme }) => ({
		'& .MuiBadge-badge': {
			right: 0,
			top: 2,
			border: `2px solid ${theme.palette.background.paper}`,
			padding: '0 3px',
		},
	}))

	const LineBadge = ({ children, idx }) => {
		let content =
			mode == modeTypesCRUD.UPDATE_MODE
				? note.lineList?.length != 0
					? note.lineList[idx]?.commentList?.length
					: 0
				: 0
		return (
			<StyledBadge
				badgeContent={content}
				color={
					mode == modeTypesCRUD.UPDATE_MODE && note.lineList[idx]?.commentList?.some((c) => c.isRead == false)
						? 'error'
						: 'secondary'
				}>
				{children}
			</StyledBadge>
		)
	}

	return (
		<>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={typeMessage}
				handleCloseError={handleCloseError}
			/>
			<BasicDialog
				paperProps={{
					sx: { position: 'fixed', top: 16, right: -30, width: showReviewComments ? '55vw' : '40vw' },
				}}
				annotationModal
				title={
					modeTypesCRUD.UPDATE_MODE != mode ? t('anotaciones-form.title') : t('anotaciones-form.title-update')
				}
				onOpen={onOpen}
				onClose={onClose}
				onSave={guardar}
				disabled={isLoadingProjectItemInsert}
				saveTxt={t('comun.actions.save')}>
				<Box
					component='div'
					className='content'
					sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
					<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
						<FormControl sx={{ m: 1, mb: 2, width: '98%' }}>
							<TextField
								select
								onChange={changeNoteType}
								size='small'
								value={tipoNotaSelected ? tipoNotaSelected : ''}
								disabled={tipoNotaDisabled}
								label={t('anotaciones-form.note-type')}>
								{fishingProfileList && fishingProfileList.itemTypeList
									? fishingProfileList.itemTypeList.map((item) => {
										return (
											<MenuItem key={'keyMenuNoteType' + item.itemType} value={item.itemType}>
												<Box sx={{ display: 'flex' }}>
													<SvgIcon
														component={getDeclarationIconAux(item.itemType, false)}
													/>
													<Typography sx={{ ml: 2 }}>{item.description}</Typography>
												</Box>
											</MenuItem>
										)
									})
									: ''}
							</TextField>
						</FormControl>
					</Box>
					<Divider sx={{ mb: 1 }} flexItem />
					<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<Box sx={{ width: showReviewComments ? '42%' : '100%' }}>
							{fishingProfileList && fishingProfileList.itemTypeList && tipoNotaSelected
								? fishingProfileList.itemTypeList.map((item) => {
									return item.itemType === tipoNotaSelected
										? item.lineList.map((elemento, idx) => {
											switch (elemento.type) {
												case 'TB':
													if (
														elemento.inputType == 'INTEGER' ||
														elemento.inputType == 'DECIMAL'
													) {
														return (
															<Box
																sx={{ display: 'flex', width: '100%' }}
																key={elemento.type + '-' + idx}>
																<FormControl sx={{ width: '100%', m: 1 }}>
																	<TextField
																		disabled={!canCreateEditAnnotations}
																		value={values[idx] || ''}
																		error={errors[idx]}
																		color={errorsText[idx] ? 'warning' : ''}
																		{...getFocusProps(idx)}
																		helperText={errorsText[idx]}
																		onChange={(event) =>
																			changeValue(
																				event.target,
																				idx,
																				elemento
																			)
																		}
																		key={'keyElemento' + idx}
																		size='small'
																		required={elemento.isRequired}
																		{...getMultiline(elemento.isMultiLine)}
																		label={elemento.label}
																	// inputProps={
																	//   { readOnly: mode === modeTypesCRUD.UPDATE_MODE }
																	// }
																	/>
																</FormControl>
																{showReviewComments ? (
																	<Tooltip
																		title={t(
																			'preview.annotation.show-comments'
																		)}>
																		<IconButton
																			edge='end'
																			aria-label='delete'
																			color='primary'
																			onClick={() => openComments(idx)}>
																			<LineBadge idx={idx}>
																				<RateReviewIcon />
																			</LineBadge>
																		</IconButton>
																	</Tooltip>
																) : null}
															</Box>
														)
													} else if (elemento.allowedChars === 'ynYN') {
														return (
															<Box
																sx={{ display: 'flex', width: '100%' }}
																key={elemento.type + '-' + idx}>
																<FormControl sx={{ width: '100%', m: 1 }}>
																	<TextField
																		disabled={!canCreateEditAnnotations}
																		select
																		key={'keySelectElemento' + idx}
																		size='small'
																		value={values[idx] || ''}
																		error={errors[idx]}
																		color={errorsText[idx] ? 'warning' : ''}
																		{...getFocusProps(idx)}
																		helperText={errorsText[idx]}
																		onChange={(event) =>
																			changeValue(
																				event.target,
																				idx,
																				elemento
																			)
																		}
																		required={elemento.isRequired}
																		label={elemento.label}
																	// inputProps={
																	//   { readOnly: mode === modeTypesCRUD.UPDATE_MODE }
																	// }
																	>
																		<MenuItem value='Y'>
																			{t(
																				'anotaciones-form.combo-si-no.si'
																			)}
																		</MenuItem>
																		<MenuItem value='N'>
																			{t(
																				'anotaciones-form.combo-si-no.no'
																			)}
																		</MenuItem>
																	</TextField>
																</FormControl>
																{showReviewComments ? (
																	<Tooltip
																		title={t(
																			'preview.annotation.show-comments'
																		)}>
																		<IconButton
																			edge='end'
																			aria-label='delete'
																			color='primary'
																			onClick={() => openComments(idx)}>
																			<LineBadge idx={idx}>
																				<RateReviewIcon />
																			</LineBadge>
																		</IconButton>
																	</Tooltip>
																) : null}
															</Box>
														)
													} else {
														return (
															<Box
																sx={{ display: 'flex', width: '100%' }}
																key={elemento.type + '-' + idx}>
																<FormControl
																	key={'keyFormControlElemento' + idx}
																	sx={{ width: '100%', m: 1 }}>
																	<TextField
																		disabled={!canCreateEditAnnotations}
																		value={values[idx] || ''}
																		error={errors[idx]}
																		color={errorsText[idx] ? 'warning' : ''}
																		{...getFocusProps(idx)}
																		helperText={errorsText[idx]}
																		onChange={(event) =>
																			changeValue(
																				event.target,
																				idx,
																				elemento
																			)
																		}
																		key={'keyElemento' + idx}
																		size='small'
																		required={elemento.isRequired}
																		multiline={elemento.isMultiLine}
																		label={elemento.label}
																	// inputProps={
																	//   { readOnly: mode === modeTypesCRUD.UPDATE_MODE }
																	// }
																	/>
																</FormControl>
																{showReviewComments ? (
																	<Tooltip
																		title={t(
																			'preview.annotation.show-comments'
																		)}>
																		<IconButton
																			edge='end'
																			aria-label='delete'
																			color='primary'
																			onClick={() => openComments(idx)}>
																			<LineBadge idx={idx}>
																				<RateReviewIcon />
																			</LineBadge>
																		</IconButton>
																	</Tooltip>
																) : null}
															</Box>
														)
													}
												case 'DD':
													return (
														<Box
															sx={{ display: 'flex', width: '100%' }}
															key={elemento.type + '-' + idx}>
															<FormControl
																key={'keyFormControlElemento' + idx}
																required={elemento.isRequired}
																sx={{ width: '100%', m: 1 }}>
																<TextField
																	disabled={!canCreateEditAnnotations}
																	select
																	key={'keySelectElemento' + idx}
																	size='small'
																	value={values[idx] || ''}
																	error={errors[idx]}
																	color={errorsText[idx] ? 'warning' : ''}
																	{...getFocusProps(idx)}
																	helperText={errorsText[idx]}
																	onChange={(event) =>
																		changeValue(event.target, idx, elemento)
																	}
																	required={elemento.isRequired}
																	label={elemento.label}>
																	{elemento.source
																		? elemento.source.map((item) => {
																			return (
																				<MenuItem
																					key={
																						'keyIdSelectElemento' +
																						idx +
																						item.item1
																					}
																					value={item.item1}>
																					{item.item2}
																				</MenuItem>
																			)
																		})
																		: ''}
																</TextField>
															</FormControl>
															{showReviewComments ? (
																<Tooltip
																	title={t(
																		'preview.annotation.show-comments'
																	)}>
																	<IconButton
																		edge='end'
																		aria-label='delete'
																		color='primary'
																		onClick={() => openComments(idx)}>
																		<LineBadge idx={idx}>
																			<RateReviewIcon />
																		</LineBadge>
																	</IconButton>
																</Tooltip>
															) : null}
														</Box>
													)
												case 'CL':
													return (
														<Box
															sx={{ display: 'flex', width: '100%' }}
															key={elemento.type + '-' + idx}>
															<FormControl
																key={'keyFormControlElemento' + idx}
																required={elemento.isRequired}
																sx={{ width: '100%', m: 1 }}>
																<TextField
																	disabled={!canCreateEditAnnotations}
																	select
																	key={'keySelectElemento' + idx}
																	size='small'
																	value={values[idx] || []}
																	SelectProps={{
																		multiple: true,
																		renderValue: (option) => (
																			<Box
																				sx={{
																					display: 'flex',
																					flexWrap: 'wrap',
																					gap: 0.5,
																				}}>
																				{option.map((value) => (
																					<Chip
																						size='small'
																						key={value}
																						label={value}
																					/>
																				))}
																			</Box>
																		),
																	}}
																	error={errors[idx]}
																	color={errorsText[idx] ? 'warning' : ''}
																	{...getFocusProps(idx)}
																	helperText={errorsText[idx]}
																	onChange={(event) =>
																		changeValue(event.target, idx, elemento)
																	}
																	required={elemento.isRequired}
																	label={elemento.label}>
																	{elemento.source
																		? elemento.source.map((item) => {
																			return (
																				<MenuItem
																					key={
																						'keyIdSelectElemento' +
																						idx +
																						item.item1
																					}
																					value={item.item1}>
																					{item.item2}
																				</MenuItem>
																			)
																		})
																		: ''}
																</TextField>
															</FormControl>
															{showReviewComments ? (
																<Tooltip
																	title={t(
																		'preview.annotation.show-comments'
																	)}>
																	<IconButton
																		edge='end'
																		aria-label='delete'
																		color='primary'
																		onClick={() => openComments(idx)}>
																		<LineBadge idx={idx}>
																			<RateReviewIcon />{' '}
																		</LineBadge>
																	</IconButton>
																</Tooltip>
															) : null}
														</Box>
													)
											}
										})
										: null
								})
								: null}
						</Box>
						{mode == modeTypesCRUD.UPDATE_MODE && showReviewComments && (
							<Box container sx={{ width: '54%' }}>
								<TableContainer sx={{ mt: 0.5 }}>
									<Table
										id='table-comment-list'
										aria-labelledby='tableTitle'
										size='small'
										style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
										<EnhancedTableHead
											order={order}
											orderBy={orderBy}
											onRequestSort={handleRequestSort}
											headCells={columns}
											addAction={() => handleAddAction()}
											addActionDisabled={!note?.lineList[selectedIdx]?.allowNewComments}
										/>

										<TableBody>
											{selectedCommentsList &&
												selectedCommentsList.length > 0 &&
												selectedCommentsList.map((row, index) => {
													return (
														<StyledTableRow key={index}>
															<TableCell align='left' sx={{ width: '30%' }}>
																<Tooltip title={row.authorToolTips}>
																	<Typography>{row.author}</Typography>
																</Tooltip>
															</TableCell>
															<TableCell align='left' sx={{ width: '50%' }}>
																<Typography>{row.comment}</Typography>
															</TableCell>
															{canReviewAnnotations ? (
																<TableCell align='center' sx={{ width: '20%', p: 0 }}>
																	{row.canEdit && (
																		<Tooltip title={t('comun.actions.update')}>
																			<IconButton
																				color='primary'
																				sx={{ height: '40px', width: '40px' }}
																				onClick={() =>
																					handleCommentAction(
																						row,
																						'UPDATE_COMMENTS',
																						index
																					)
																				}>
																				<EditIcon />
																			</IconButton>
																		</Tooltip>
																	)}

																	{row.canDelete && (
																		<Tooltip title={t('comun.actions.delete')}>
																			<IconButton
																				sx={{ height: '40px', width: '40px' }}
																				color='error'
																				onClick={() =>
																					handleDeleteCommentAction(
																						row,
																						index
																					)
																				}>
																				<DeleteIcon />
																			</IconButton>
																		</Tooltip>
																	)}
																</TableCell>
															) : null}
														</StyledTableRow>
													)
												})}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						)}
					</Box>
				</Box>
			</BasicDialog>
			<ConfirmationDialog
				onOpen={openConfirmacion}
				onClose={closeModalConfirmacion}
				dialogTitle={dialogTitle}
				dialogContent={dialogContent}
				dialogSaveButton={dialogSaveButton}
				dialogCancelButton={dialogCancelButton}></ConfirmationDialog>
			{/* Dialogo añadir comentario */}
			<AddCommentDialog
				onOpen={openComentario}
				onClose={closeModalComentario}
				commentValue={selectedComment ? selectedComment.comment : ''}
				idx={selectedCommentIdx}
				mode={modeComments}
				paperProps={{ sx: { position: 'fixed', top: 60, right: 130, width: '25%' } }}></AddCommentDialog>
		</>
	)
}
