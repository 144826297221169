import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Chip } from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";

import FieldsetComponent from "components/comun/FieldsetComponent";
import ListChipComponent from "components/comun/ListChipComponent";
import TextFieldComponent from "components/comun/form_fields/TextFieldComponent";

import DeleteIcon from "@mui/icons-material/Delete";

import { ConfEmailSectionProps } from "models/config_notifications/ConfigNotificationComponentsModel";
import { ItemTypeNotificationConfigEmailModel } from "models/config_notifications/ConfigNotificationModel";
import ChipComponent from "components/comun/ChipComponent";

const ConfEmailSection: React.FC<ConfEmailSectionProps> = ({ listMails, methodsForm }) => {
    const [t] = useTranslation('common');

    const addText: string = t('comun.actions.add');
    const {
        control,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = methodsForm;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'emails', // Vincular al array en defaultValues
    });

    const mailsFormated = watch('emails') || []; // Observa los cambios en emails

    function onRemoveMail(item: string, index: number) {
        remove(index); // Usa el índice para eliminar
    }

    function onSubmitNewMail(email: string) {
        if (!email) {
            console.warn("Email is empty");
            return;
        }
        append({ email }); // Agrega un nuevo objeto al array
    }

    return (
        <FieldsetComponent title={t('comun.otherTexts.email')} style={{ width: '100%', display: 'block' }}>
            <Box sx={{ width: '100%', display: 'inline' }}>
                <Box sx={{ width: '100%', display: 'row', mt: '5px' }}>
                    <TextFieldComponent
                        sx={{ width: '80%' }}
                        control={control}
                        size={'small'}
                        label={t('comun.otherTexts.email')}
                        disabled={false}
                        defaultValue={''}
                        name={'mail_add'}
                        type={undefined}
                        rules={undefined}
                        required={undefined}
                        errors={undefined}
                        autoFocus={undefined}
                        InputProps={undefined}
                        autoComplete={undefined}
                        rows={undefined}
                    />
                    <Button
                        variant="contained"
                        size="medium"
                        sx={{ width: '15%', marginLeft: '5%' }}                          
                        onClick={() => {
                            const newEmail = getValues('mail_add'); // Obtiene el valor del campo
                            onSubmitNewMail(newEmail);
                            setValue('mail_add', ''); // Limpia el campo después de agregar
                        }}
                    >
                        {addText}
                    </Button>
                </Box>
            </Box>

            <Box sx={{ paddingTop: '10px', display: 'inline' }}>
                {fields.map((field, index) => (
                    <ChipComponent
                    sx={{ marginTop: '10px', marginRight: '10px' }}
                        key={index}
                        control={control}
                        defaultValue={''}
                        name={`emails.${index}.email`}
                        errors={errors}
                        disabled={false}
                        deleteIcon={<DeleteIcon />}
                        onRemove={() => onRemoveMail((field as any).email, index)} // Usar el índice para eliminar
                    />
                    // <Chip
                    // sx={{ marginTop: '10px', marginRight: '10px' }}
                    //     key={index}
                    //     label={`emails.${index}.email`}
                    //     deleteIcon={<DeleteIcon />}
                    //     onDelete={() => onRemoveMail((field as any).email, index)} // Usar el índice para eliminar
                    // />
                ))}
            </Box>
        </FieldsetComponent>
    );
};

export default ConfEmailSection;