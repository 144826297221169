import React, { useState, useEffect } from 'react'
import { Box, Grid, Paper, Divider } from '@mui/material'
import OrganizationVesselFilter from './organization/organization_vessels/OrganizationVesselFilter'
import OrganizationVesselTable from './organization/organization_vessels/OrganizationVesselTable'
import { baseFilter, modeTypesCRUD } from '../../constants'
import { useTranslation } from 'react-i18next'
import { useOrganizationVesselsFilterRequest } from '../../services/admin/organizations/organization_vessels/useOrganizationVesselsFilterRequest'
import { useOrganizationVesselsRequest } from '../../services/admin/organizations/organization_vessels/useOrganizationVesselsRequest'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import OrganizationVesselForm from './organization/organization_vessels/OrganizationVesselForm'
import { useOrganizationVesselsUpdate } from '../../services/admin/organizations/organization_vessels/useOrganizationVesselsUpdate'
import BackdropComponent from '../comun/utils/BackdropComponent'
import MessageSnackBar from '../comun/MessageSnackBar'
import { messageDialogTypes } from '../../constants'


const initialFilter = { ...baseFilter, orderDirection: 'asc', orderField: 'id' }
export default function OrganizationsVesselContainer() {

  const [t] = useTranslation('common')
  const { id } = useParams();

  /* == Filter == */
  const [filterOrgVessel, setfilterOrgVessel] = useState()
  const [favouriteFilters, setFavourites] = useState(null)
  const [filter, setFilter] = useState(initialFilter)
  const [vessels, setVessels] = useState()

  /* == Organization Vessels Table == */
  const [open, setOpen] = useState(false)
  const [mode, setMode] = React.useState(modeTypesCRUD.UPDATE_MODE)
  const [selectedVessel, setSelectedVessel] = useState(null)
  const [vesselList, setVesselList] = useState(null)

  /* == Control de errores == */
  const [openError, setOpenError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [typeMessage, setTypeMessage] = useState(null)

  /* == Form == */
  const [processTypes, setProcessTypes] = useState()

  /* == CRUD == */
  const { organizationVesselsRequest, organizationVesselsData, errorOrganizationVesselsData, isLoading } = useOrganizationVesselsRequest()
  const { organizationFilterRequest, orgVesselsFilter, errorOrgVesselsFilter, isLoadingOrgVesselsFilter } = useOrganizationVesselsFilterRequest()
  const { organizationVesselsUpdate, organizationVesselsDataUpdate, errorOrgVesselsUpdate, isLoadingUpdate } = useOrganizationVesselsUpdate()

  /** Efecto de la llamada a la carga inicial */
  useEffect(() => {
    organizationFilterRequest(id)
  }, []);

  /** Efecto que guarda los datos de la llamada con filtro */
  useEffect(() => {
    if (orgVesselsFilter != null) {
      setVesselList(orgVesselsFilter.resultFilter)
      setVessels(orgVesselsFilter.vessels)
      setFavourites(orgVesselsFilter.favouritesDic)
      setfilterOrgVessel(orgVesselsFilter.filterVesselConfig)
      setProcessTypes(orgVesselsFilter.mlProcessTypes)
    }
  }, [orgVesselsFilter]);

  /** Efecto que carga los datos en la tabla */
  useEffect(() => {
    if (organizationVesselsData) {
      setVesselList(organizationVesselsData)
    }
  }, [organizationVesselsData]);

  /** Efecto que recarga al actualizar los datos */
  useEffect(() => {
    setFilter({ ...filter, organizationId: id })
    organizationVesselsRequest(filter)
  }, [organizationVesselsDataUpdate]);

  /** Efecto para manejar errores de la carga inicial */
  useEffect(() => {
    if (errorOrganizationVesselsData) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorOrganizationVesselsData)
    }
  }, [errorOrganizationVesselsData]);

  /** Efecto para manejar errores de la carga con filtro */
  useEffect(() => {
    if (errorOrgVesselsFilter) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorOrgVesselsFilter)
    }
  }, [errorOrgVesselsFilter]);

  /** Efecto para manejar errores de update */
  useEffect(() => {
    if (errorOrgVesselsUpdate) {
      setOpenError(true)
      setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
      setErrorMsg(errorOrgVesselsUpdate)
    }
  }, [errorOrgVesselsUpdate]);

  /** Cuando hace una busqueda*/
  const onFilter = (filter) => {
    setFilter(filter)
    organizationVesselsRequest(filter)
  }

  /** CRUD en modales*/
  const handleFormVessels = (row, mode) => {
    setMode(mode)
    if (modeTypesCRUD.UPDATE_MODE === mode)
      handleOpenForm(row)
  }

  const handleCloseError = () => {
    setOpenError(false)
    organizationFilterRequest(id)
}

  /** Metodo Para abir la modal y set datos de la fila seleccionada */
  const handleOpenForm = (row) => {
    setSelectedVessel(row)
    setOpen(true)
  }

  /** Campos del filtro*/
  const vesselFields = [
    { name: 'vessels', label: 'Barcos' }
  ]

  const onClose = () => {
    setOpen(false)
  }

  const parseData = (data, row) => {
    const formatVessel = {
      organizationId: row.organizationId,
      vesselApiId: row.vesselApiId,
      liveCamIsEnabled: data.liveCam.id,
      mlProcessIsEnabled: data.mlProcess.id,
      mlProcessType: data.mlProcess.id === false ? '' : (data?.mlTypeProcess?.id ?? ''),
      lastMessageReadDate: typeof data?.lastMessageReadDate === 'string'
        ? data.lastMessageReadDate
        : data?.lastMessageReadDate?.toISOString(),
      vessel: row.vessel,
      organization: row.organization
    }

    return formatVessel
  }

  /** Método para save edit */
  const onSave = (data) => {
    const row = { ...selectedVessel }
    const vessel = parseData(data, row)
    if (modeTypesCRUD.UPDATE_MODE) {
      organizationVesselsUpdate(vessel)
      setOpen(false)
    }

  }

  return (
    <Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>

      <BackdropComponent
        open={
          isLoadingUpdate ||
          isLoading ||
          isLoadingOrgVesselsFilter
        }
      />

      <MessageSnackBar
        openError={openError}
        errorMsg={errorMsg}
        typeMsg={typeMessage}
        handleCloseError={handleCloseError}
      />

      <Box sx={{ width: '100%' }}>
        <Grid>
          <OrganizationVesselFilter
            vessels={vessels}
            filterVessel={filterOrgVessel}
            onFilter={onFilter}
            filter={filter}
            fields={vesselFields}
            favouriteFilters={favouriteFilters}
          ></OrganizationVesselFilter>
          <Divider sx={{ m: 2 }} variant='middle' />
          <Paper>
            <OrganizationVesselTable
              vesselList={vesselList}
              filter={filter}
              onFilter={onFilter}
              handleFormVessels={handleFormVessels}
              mlProcessType={processTypes}
            ></OrganizationVesselTable>
          </Paper>
        </Grid>

        {open && (
          <OrganizationVesselForm
            selectedVessel={selectedVessel}
            onClose={onClose}
            onSave={onSave}
            mode={mode}
            open={open}
            mlProcessType={processTypes}
          />
        )}
      </Box>
    </Box>
  )
}
