import React from 'react'
import { Controller } from 'react-hook-form'

import FormControl from '@mui/material/FormControl'
import Chip from '@mui/material/Chip'

export default function ChipComponent({
    control,
	defaultValue,
	name,
    errors,
	sx,
    disabled,
    onRemove
}){
    function OnDelete(data){
        onRemove(defaultValue);
    }

    return(
        <FormControl sx={sx} error={!!errors}>
            <Controller
				defaultValue={defaultValue ? defaultValue : ''}
				name={name}
				control={control} 
                render={({ field: {  value } }) => (
                    <Chip 
                        label={value}
                        onDelete={disabled ? undefined : OnDelete}
                    />
                )}
            />
        </FormControl>
    )
}