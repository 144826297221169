import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'
import { prepareParams } from '../../ServicesUtils'

export const useOrganizationFilterRequest = () => {
	const { client } = useContext(ApiClientContext)
	const [orgFilter, setOrgFilter] = useState(null)
	const [errorOrgFilter, setErrorOrgFilter] = useState(null)
	const [isLoadingOrgFilter, setIsLoadingOrgFilter] = useState(false)
	
	//Carga inicial
	const organizationFilterRequest = useCallback(() => {
		setIsLoadingOrgFilter(true);
		client
			.get(baseUrl + 'horus/admin/organization/filter')
			.then((result) => {
				setIsLoadingOrgFilter(false)
				if (result.data.success) {
					setOrgFilter(result.data.data)
				} else {
					setErrorOrgFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				console.log(err)
				setIsLoadingOrgFilter(false)
				setErrorOrgFilter(err.response)
			})
	}, [client])
	return {
		organizationFilterRequest,
		isLoadingOrgFilter,
		orgFilter,
		errorOrgFilter,
	}
}