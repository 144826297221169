import { Table, TableBody, TableContainer, TableFooter, TableRow, TableCell, Tooltip, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import CircleIcon from '@mui/icons-material/Circle'
import { green, red } from '@mui/material/colors'
import { modeTypesCRUD } from '../../../../constants'
import { EnhancedTableHead } from '../../../comun/TableUtils'
import TablePaginationComponent from '../../../comun/TablePaginationComponent'

import { useTranslation } from 'react-i18next'

export default function OrganizationVesselTable({ vesselList, filter, onFilter, handleFormVessels, mlProcessType }) {
    const [t] = useTranslation('common')

    /* == TABLE == */
    const [order, setOrder] = useState(filter.orderDirection)
    const [orderBy, setOrderBy] = useState(filter.orderField)
    const [page, setPage] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(filter.pageSize)
    const [openRow, setOpenRow] = useState({});

    useEffect(() => {
        if (filter) {
            setRowsPerPage(filter.pageSize)
            setOrder(filter.orderDirection)
            setOrderBy(filter.orderField)
        }
    }, [filter])

    const handleChangePage = (event, newPage) => {
        let newFilter = { ...filter }
        newFilter.pageNo = newPage + 1
        onFilter(newFilter)
    }

    const handleChangeRowsPerPage = (event) => {
        let newFilter = { ...filter }
        newFilter.pageSize = event.target.value
        newFilter.pageNo = 1
        onFilter(newFilter)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        let newFilter = { ...filter }
        newFilter.orderDirection = isAsc ? 'desc' : 'asc'
        newFilter.orderField = property
        newFilter.pageNo = 1
        onFilter(newFilter)
    }

    useEffect(() => {
        if (vesselList) {
            setPage(vesselList.pageNo - 1)
            setTotalCount(vesselList.totalCount)
        }
    }, [vesselList])

    function formatDate(isoString) {
        const date = new Date(isoString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    const columns = [
        {
            id: 'description',
            align: 'left',
            orderBy: true,
            label: t('organization-vessels.table.name'),
        },
        {
            id: 'liveCamIsEnabled',
            align: 'left',
            orderBy: true,
            label: t('organization-vessels.table.liveCam'),
        },
        {
            id: 'mlProcessIsEnabled',
            align: 'left',
            orderBy: true,
            label: t('organization-vessels.table.mlProcess'),
        },
        {
            id: 'mlProcessType',
            align: 'left',
            orderBy: true,
            label: t('organization-vessels.table.mlProcessType'),
        },
        {
            id: 'lastMessageReadDate',
            align: 'left',
            orderBy: true,
            label: t('organization-vessels.table.lastCommentRead'),
        },
        {
            id: 'actions',
            align: 'center',
            orderBy: true,
            label: '',
            excluirExcel: true,
        }
    ]

    return (
        <TableContainer>
            <Table
                id="table-organization-vessels-list"
                aria-labelledby="tableTitle"
                size="small"
                sx={{ minWidth: 750 }}
                style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={columns}
                />
                <TableBody>
                    {vesselList && vesselList.data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{row.vessel.description}</TableCell>
                            <TableCell align='center'>
                                <CircleIcon
                                    sx={{
                                        fill: row.liveCamIsEnabled === false ? red[500] : green[500],
                                    }}
                                />
                            </TableCell>
                            <TableCell align='center'>
                                <CircleIcon
                                    sx={{
                                        fill: row.mlProcessIsEnabled === false ? red[500] : green[500],
                                    }}
                                />
                            </TableCell>
                            <TableCell align='center'>{mlProcessType.find((o) => o.id === row.mlProcessType)?.description }</TableCell>
                            <TableCell align="center">{formatDate(row.lastMessageReadDate)}</TableCell>
                            <TableCell align='center'>
                                <Tooltip title={t('vessels.table.content.update')}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleFormVessels(row, modeTypesCRUD.UPDATE_MODE)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter className='excluir-excel'>
                    <TableRow style={{ height: 52 }}>
                        <TablePaginationComponent
                            rowsPerPageOptions={[5, 10, 25]}
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            customActions={true}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}
