import { useEffect } from "react"
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TextFieldComponent from "../../comun/form_fields/TextFieldComponent"

export default function FormGeneralDataOrganization({ methodsForm, selected }) {
    const {
        control,
        reset,
        formState: { errors },
    } = methodsForm

    const [t] = useTranslation('common')

    /** Efecto que rellena los campos de la org seleccionada */
    useEffect(() => {
        if (selected != null) {
            reset(selected)
        }
    }, [selected])

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    label={t('organizations.form.name')}
                    name="name"
                    size="small"
                    errors={errors?.name}
                    fullWidth
                />
                <TextFieldComponent sx={{ m: 1, width: '50%' }}
                    control={control}
                    label={t('organizations.form.orgPrefix')}
                    name="orgPrefix"
                    size="small"
                    errors={errors?.orgPrefix}
                    fullWidth
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                <TextFieldComponent sx={{ m: 1, width: '100%' }}
                    control={control}
                    label={t('organizations.form.description')}
                    name="description"
                    size="small"
                    errors={errors?.description}
                    fullWidth
                />
            </Box>
        </Box>
    )
}
