import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import RedoIcon from '@mui/icons-material/Redo'
import { Box, Collapse, IconButton, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { datetimeGMTToLocaleInISOFormat, datetimeUTCtoString } from '../../../comun/DateUtils'
import NotificationFilter from './NotificationFilter'
import { useEffect } from 'react'

export default function NotificationTab({ notificationsInfo, updateCurrentTime }) {
	const [t] = useTranslation('common')
	const [notifications, setNotifications] = useState(notificationsInfo)
	const [showFilter, setShowFilter] = useState(false)
	const [initialDate, setInitialDate] = useState()

	const handleShowFilter = () => {
		setShowFilter(!showFilter)
	}

	useEffect(() => {
		setInitialDate(notificationsInfo[0]?.startDate ?? null)
	}, []);

	const onFilter = (name, startDate, endDate) => {
		startDate = datetimeGMTToLocaleInISOFormat(startDate)
		endDate = datetimeGMTToLocaleInISOFormat(endDate)
		const filteredNotifications = notificationsInfo.filter(n => {
			let isValid = true

			if (name != null && n.name.toUpperCase().indexOf(name.toUpperCase()) === -1) {
				isValid = false
			}

			if (startDate !== null && n.startDate < startDate) {
				isValid = false
			}

			if (endDate !== null && n.endDate > endDate) {
				isValid = false
			}

			return isValid
		})
		setNotifications(filteredNotifications)
	}

	const handleGoToMoment = (notification) => {
		updateCurrentTime(notification.startDate)
	}
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				maxHeight: '99.7%',
				width: '100%',
				boxShadow:
					'0px 1px 2px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
			}}>
			{/* Filter row */}
			<Collapse in={showFilter} timeout='auto' sx={{ width: '100%', p: 1, minHeight: 'auto !important' }}>
				<NotificationFilter t={t} onFilter={onFilter} dateFilter={initialDate} />
			</Collapse>

			{/* Table */}
			<TableContainer sx={{ height: showFilter ? '90%' : '100%' }}>
				<Table sx={{ width: '100%', height: '100%' }} size='small' stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>
								{t('preview.notification.name')}
							</TableCell>
							<TableCell sx={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>
								{t('preview.notification.start-date')}
							</TableCell>
							<TableCell sx={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>
								{t('preview.notification.end-date')}
							</TableCell>
							<TableCell sx={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>
								{/* <Tooltip
									placement='top'
									title={
										showFilter
											? t('preview.notification.hide-filter')
											: t('preview.notification.show-filter')
									}> */}
								<IconButton
									size='small'
									sx={{ height: '20px' }}
									color='inherit'
									onClick={handleShowFilter}>
									{showFilter ? <FilterAltOffIcon /> : <FilterAltIcon />}
								</IconButton>
								{/* </Tooltip> */}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{notifications.map((notification, idx) => (
							<TableRow
								key={notification.name + '-' + notification.startDate + '-' + idx}
								sx={{
									'&:last-child td, &:last-child th': { border: 0 },
								}}>
								<TableCell align='center'>{notification.name}</TableCell>
								<TableCell align='center'>{datetimeUTCtoString(notification.startDate)}</TableCell>
								<TableCell align='center'>{datetimeUTCtoString(notification.endDate)}</TableCell>
								<TableCell align='right'>
									<Tooltip title={t('preview.annotation.go-to-moment')}>
										<IconButton color='primary' onClick={() => handleGoToMoment(notification)}>
											<RedoIcon />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}
