import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import AdminVesselsContainer from '../components/admin/AdminVesselsContainer'

const AdminVesselsPage = () => {
    const history = useHistory()

    return(
        <ErrorBoundary history={history}>
            <AdminVesselsContainer></AdminVesselsContainer>
        </ErrorBoundary>
    )
}

export default AdminVesselsPage