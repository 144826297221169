import { ApiClientContext } from "../../ApiClientProvider"
import React, { useCallback, useState } from 'react'
import { baseUrl } from "../../../constants"
import { prepareParams } from "../../ServicesUtils"


export const useOrganizationRequest = () => {
    const [organizationData, setOrganizationData] = useState(null)
    const [errorOrganizationData, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
    const { client } = React.useContext(ApiClientContext)

    const organizationRequest = useCallback(
        (filter) => {
            client
                .get(baseUrl + 'horus/admin/organization?' + prepareParams(filter))
                .then((result) => {
                    setIsLoading(false)
                    if(result.data.success){
                        setOrganizationData(result.data)
                    }else{
                        setError({ statusText: result.data.errorMsg })
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    setError(err.response) 
                }) 

                setOrganizationData(null)
                setError(null)
                setIsLoading(true)
        },
        [client]
    )
    return{
        organizationRequest,
        isLoading,
        organizationData,
        errorOrganizationData
    }
}