import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form'

import { Box , Checkbox, FormControlLabel} from "@mui/material";

import SelectComponent from 'components/comun/form_fields/SelectComponent';
import TextFieldComponent from 'components/comun/form_fields/TextFieldComponent';
import FieldsetComponent from 'components/comun/FieldsetComponent';
import MessageSnackBar from'components/comun/MessageSnackBar';
import FilterFunctions from "components/comun/FilterFunctions";

const {messageDialogTypes} = require('constants');
const {assignFilter} = require('utils/customFunctions');

import { ConfigNotificationsFilterProps } from "models/config_notifications/ConfigNotificationComponentsModel";
import { SelectValueNumberModel } from "models/BasicModel";
import { ConfigNotificationTableFilterModel, FilterItemTypeFavourite } from "models/config_notifications/ConfigNotificationTableModel";


const ConfigNotificationsFilter: React.FC<ConfigNotificationsFilterProps> = ({fishingsProfiles, onFilter, filterModel, onChangeFishingProfile, itemTypes}) => {
    const [t] = useTranslation('common');
    const {
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
		formState: { errors },
	} = useForm({ mode: 'onTouched' });

    const selectFishingProfile: SelectValueNumberModel = watch('fishingProfileId');

    const [checked, setChecked] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>('');
    const [favFilters, setFavFilters] = useState<FilterItemTypeFavourite[]>([])// Array de filtros guardados del boton de la vista
    const [filterData, setFilterData] = useState<FilterItemTypeFavourite | null>(null) //Guarda los nuevos filtrosFavoritos insertados

    useEffect(() => {
        if(filterModel){
            setValue('fishingProfileId', filterModel.fishingProfileId ? filterModel.fishingProfileId : '');
            setValue('itemType', filterModel.itemType ? filterModel.itemType : '');
        }
    }, [filterModel]);

    useEffect(() => {
        if(selectFishingProfile){
            setValue('itemType', '');
            onChangeFishingProfile(selectFishingProfile.value);
        }
    }, [selectFishingProfile]);

    const handleChangeCheck = (e: any) => {
        setChecked(e.target.checked)
    }

    const onSearch = (data: any) => {
        var dataForm : ConfigNotificationTableFilterModel = {
            fishingProfileId: data.fishingProfileId.value,
            itemType: data.itemType.value,
            mail: data.mail,
            removed: checked,
            orderDirection:'',
            orderField:'',
            pageNo:-1,
            pageSize:-1,
            organizationId:-1
        };
        assignFilter(dataForm, filterModel);
        onFilter(dataForm);
    };

    const onClickSavedFilter = (selectedFilter : string) => {
		setSelectedFilter(selectedFilter)
	};
    const onDeleteSavedFilter = (event : any, filterName : string) => {
		//deleteFavouriteFilter(filterName)
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)

		if (event instanceof Event) {
			event.stopPropagation()
		}
	};
    const resetFilter = () => {
		reset()
		setSelectedFilter('')
	};

    const saveFilter = (filterName : string) => {
		let formValues = getValues()

        let filterDataAux : FilterItemTypeFavourite = {
            organizationId: filterModel.organizationId,
            filterName: filterName,
            fishingProfileId: formValues.fishingProfileId,
            itemType: formValues.itemType,
            includeDeleted: formValues.includeDeleted,
            email: formValues.email,
            pageInfo: {
                orderDirection: filterModel.orderDirection,
                orderField: filterModel.orderField,
                pageNo: filterModel.pageNo,
                pageSize: filterModel.pageSize
            }
        };


		//saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux);
	}

    // Set errors
	const [openError, setOpenError] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
    const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	};

    return(
        <FieldsetComponent title={t('organization-itemtypes.filter.title')} style={{ width: '100%' }}>
            <MessageSnackBar
                openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
            />

            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} component='form' onSubmit={handleSubmit(onSearch)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0', m: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <SelectComponent
                            rules={undefined}
                            chip={undefined}
                            multiple={false}
                            errors={undefined}
                            disabled={undefined}
                            size='small'
                            sx={{ width: '50%', m: 1 }}
                            label={t('comun.otherTexts.fishingProfile')}
                            defaultValue={fishingsProfiles ? fishingsProfiles[0] : null}
                            name='fishingProfileId'
                            control={control}
                            options={fishingsProfiles}
                            optionsLabel={'description'}
                            optionsValue={'value'}
                        />
                        <SelectComponent
                            size='small'
                            sx={{ width: '50%', m: 1 }}
                            label={'Item Type'}
                            defaultValue={itemTypes ? itemTypes[0] : null}
                            name='itemType'
                            control={control}
                            options={itemTypes}
                            optionsLabel={'description'}
                            optionsValue={'value'}
                            multiple={false}
                            disabled={!selectFishingProfile}
                            rules={undefined}
                            chip={undefined}
                            errors={undefined}
                        />
        
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextFieldComponent
                            defaultValue={undefined}
                            sx={{ width: '50%', m: 1 }}
                            name='mail'
                            label={t('comun.otherTexts.email')}
                            control={control}
                            size='small'
                            rules={undefined}
                            type={undefined}
                            InputProps={undefined}
                            autoComplete={undefined}
                            autoFocus={undefined}
                            disabled={undefined}
                            rows={undefined}
                            required={undefined}
                            errors={undefined}
                        />
                        <FormControlLabel
                            label={t('organization-itemtypes.filter.removed') as string}
                            labelPlacement='end'
                            sx={{ width: '50%', ml: 1 }}
                            control={
                                <Checkbox
                                    name='removed'
                                    id='removed'
                                    checked={checked}
                                    onChange={handleChangeCheck}
                                />
                            }>
                        </FormControlLabel>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
                    <FilterFunctions
                        filters={favFilters}
                        handleClearClick={() => reset()}
                        onClickSavedFilter={onClickSavedFilter}
                        resetFilter={resetFilter}
                        saveFilter={saveFilter}
                        onDeleteSavedFilter={onDeleteSavedFilter}
                        selectedFilter={selectedFilter}
                        threeLines={undefined}
                    />
                </Box>
            </Box>


        </FieldsetComponent>
    );
};

export default ConfigNotificationsFilter;