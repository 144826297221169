import React from "react";
import { useTranslation } from "react-i18next";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton, TableFooter, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import EditIcon from '@mui/icons-material/Edit';

const {modeTypesCRUD} = require("constants"); 

const { EnhancedTableHead, getComparator, stableSort } = require("components/comun/TableUtils");
import TablePaginationComponent from "components/comun/TablePaginationComponent";

import { ConfigNotificationsTableProps } from "models/config_notifications/ConfigNotificationComponentsModel";
import { ConfigNotificationTableColumnModel, ConfigNotificationTableFilterModel, ConfigNotificationViewTableModel } from "models/config_notifications/ConfigNotificationTableModel";
import { StyledTableRow } from "components/comun/StyleComponentUtils";

const ConfigNotificationsTable: React.FC<ConfigNotificationsTableProps> = ({dataTable, onFilter, filterModel, rowAction}) => {
    const [t] = useTranslation('common');

    //This block should be the same in every table component ----------------------------------
	const [order, setOrder] = React.useState<string>(filterModel.orderDirection);
	const [orderBy, setOrderBy] = React.useState<string>(filterModel.orderField);
	const [page, setPage] = React.useState<number>(filterModel.pageNo);
	const [totalCount, setTotalCount] = React.useState<number>(dataTable ? dataTable.totalCount : 0);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(filterModel.pageSize);
	const [modelList, setModelList] = React.useState<ConfigNotificationViewTableModel[]>(dataTable ? dataTable.data : []);

	React.useEffect(() => {
		if (filterModel) {
			setRowsPerPage(filterModel.pageSize);
			setPage(filterModel.pageNo);
			setOrder(filterModel.orderDirection);
			setOrderBy(filterModel.orderField);
		}
	}, [filterModel]);

	React.useEffect(() => {
		if(dataTable){
			setTotalCount(dataTable.totalCount);
			setModelList(dataTable.data);
		}
	}, [dataTable]);

    const handleAddAction = () => {
		rowAction(0, modeTypesCRUD.INSERT_MODE);
	};

    const handleRequestSort = (event: any, property: string) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter: ConfigNotificationTableFilterModel = { ...filterModel }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	};

	const handleChangePage = (event : any, newPage : number) => {
		let newFilter = { ...filterModel }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event : any) => {
		let newFilter = { ...filterModel }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

    const columns : ConfigNotificationTableColumnModel[] = [
        { id:'fishingProfileName', label:t('comun.otherTexts.fishingProfile'), orderBy: false },
        { id:'itemTypeName', label:'Item Type', orderBy: false },
		{ id:'description', label:t('comun.otherTexts.description'), orderBy: false },
		{ id:'actions', label:'', orderBy: false }
    ];

	/*function Row(props : any){
		const { row } = props;
		const [open, setOpen] = React.useState(false)
		
		return(
			<>
				<StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
					<TableCell width={50}>
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell align='center'>{row.fishingProfile}</TableCell>
					<TableCell align='center'>{row.itemType}</TableCell>
					<TableCell align='center'>
						<Tooltip title={!t('programs.table.content.update')}>
							<span>
								<IconButton
									color='primary'
									onClick={() => rowAction(row, modeTypesCRUD.UPDATE_MODE)}>
									<EditIcon />
								</IconButton>
							</span>
						</Tooltip>
						<Tooltip title={!t('programs.table.content.delete')}>
							<span>
								<IconButton
									color='error'
									onClick={() => rowAction(row, modeTypesCRUD.DELETE_MODE)}>
									<DeleteIcon />
								</IconButton>
							</span>
						</Tooltip>
					</TableCell>
				</StyledTableRow>
			</>
		);
	}
*/
	const tooltipUpdate : string = t('organization-itemtypes.table.content.update'); //t('programs.table.content.update')
	const tooltipRemove : string = t('organization-itemtypes.table.content.delete');
	const tooltipUnRemove : string = t('organization-itemtypes.table.content.undelete');

    return (
        <TableContainer>
            <Table id='table-item-config-list'
				sx={{ minWidth: 750, border: '1.5px solid rgba(224, 224, 224, 1)', borderRadius: '5px' }}
				aria-labelledby='tableTitle'
				size='small'>
                <EnhancedTableHead 
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={columns}
                    addAction={handleAddAction} />
                <TableBody>
					{
						!modelList ? [] : modelList.map((row: ConfigNotificationViewTableModel, index: number) => {
							return (
								<StyledTableRow key={index}>
									<TableCell align='center'>{row.fishingProfileName}</TableCell>
									<TableCell align='center'>{row.itemTypeName}</TableCell>
									<TableCell align='center'>{row.description}</TableCell>
									<TableCell align='center'>
										<Tooltip title={tooltipUpdate}>
											<span>
												<IconButton
													color='primary'
													onClick={() => rowAction(row.id, modeTypesCRUD.UPDATE_MODE)}>
													<EditIcon  />
												</IconButton>
											</span>
										</Tooltip>
										{
											row.isDeleted 
											?
											<Tooltip title={tooltipUnRemove}>
												<span>
													<IconButton
														color='success'
														onClick={() => rowAction(row.id, modeTypesCRUD.DELETE_MODE)}>
														<RestoreFromTrashIcon />
													</IconButton>
												</span>
											</Tooltip>
											:
											<Tooltip title={tooltipRemove}>
												<span>
													<IconButton
														color='error'
														onClick={() => rowAction(row.id, modeTypesCRUD.DELETE_MODE)}>
														<DeleteIcon />
													</IconButton>
												</span>
											</Tooltip>
										}
									</TableCell>
								</StyledTableRow>
							);
						})				
					}
				</TableBody>
                <TableFooter>
					<TableRow>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
            </Table>
        </TableContainer>
    );
};

export default ConfigNotificationsTable;