import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../../constants'
import { ApiClientContext } from '../../../ApiClientProvider'

export const useOrganizationVesselsUpdate = () => {
	const [organizationVesselsDataUpdate, setOrganizationVesselsDataUpdate] = useState(null)
	const [errorOrgVesselsUpdate, serErrorOrgVesselsUpdate] = useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const organizationVesselsUpdate = useCallback(
		(config) => {
			client
				.put(baseUrl + 'horus/admin/organization/vessel/config', config)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setOrganizationVesselsDataUpdate({ data: result.data })
					} else {
						serErrorOrgVesselsUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					serErrorOrgVesselsUpdate(error.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setOrganizationVesselsDataUpdate(null)
			serErrorOrgVesselsUpdate(null)
		},
		[client]
	)

	return {
		organizationVesselsUpdate,
		isLoadingUpdate,
		organizationVesselsDataUpdate,
		setOrganizationVesselsDataUpdate,
		errorOrgVesselsUpdate,
		serErrorOrgVesselsUpdate,
	}
}