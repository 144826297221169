import { ApiClientContext } from "../../../ApiClientProvider"
import React, { useCallback, useState } from 'react'
import { baseUrl } from "../../../../constants"
import { prepareParams } from "../../../ServicesUtils"


export const useOrganizationVesselsRequest = () => {
    const [organizationVesselsData, setOrganizationVesselsData] = useState(null)
    const [errorOrganizationVesselsData, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
    const { client } = React.useContext(ApiClientContext)

    const organizationVesselsRequest = useCallback(
        (filter) => {
            client
                .get(baseUrl + 'horus/admin/organization/vessel/config?' + prepareParams(filter))
                .then((result) => {
                    setIsLoading(false)
                    if(result.data.success){
                        setOrganizationVesselsData(result.data)
                    }else{
                        setError({ statusText: result.data.errorMsg })
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    setError(err.response) 
                }) 

                setOrganizationVesselsData(null)
                setError(null)
                setIsLoading(true)
        },
        [client]
    )
    return{
        organizationVesselsRequest,
        isLoading,
        organizationVesselsData,
        errorOrganizationVesselsData
    }
}