import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'
import { prepareParams } from '../../ServicesUtils'

export const useVesselFilterRequest = () => {
	const { client } = useContext(ApiClientContext)
	const [vesselFilter, setVesselFilter] = useState(null)
	const [errorVesselFilter, setErrorVesselFilter] = useState(null)
	const [isLoadingVesselFilter, setIsLoadingVesselFilter] = useState(false)
	
	//Carga inicial
	const vesselFilterRequest = useCallback(() => {
		setIsLoadingVesselFilter(true);
		client
			.get(baseUrl + 'horus/admin/vessel/filter')
			.then((result) => {
				setIsLoadingVesselFilter(false)
				if (result.data.success) {
					setVesselFilter(result.data.data)
				} else {
					setErrorVesselFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				console.log(err)
				setIsLoadingVesselFilter(false)
				setErrorVesselFilter(err.response)
			})
	}, [client])
	return {
		vesselFilterRequest,
		isLoadingVesselFilter,
		vesselFilter,
		errorVesselFilter,
	}
}