import React from 'react'
import { useEffect } from "react"
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TextFieldComponent from "../../comun/form_fields/TextFieldComponent"
import DatePickerComponent from '../../comun/form_fields/DatePickerComponent'

export default function FormGeneralDataVessel({ methodsForm, selected }) {
    const {
        control,
        reset,
        onSave,
        setValue,
        formState: { errors },
    } = methodsForm

    const [t] = useTranslation('common')

    /** Efecto que rellena los campos de la org seleccionada */
    useEffect(() => {
        if (selected != null) {
            reset(selected)
            setValue('terminalId', selected.terminalId)
            setValue('albacoraId', -1)
            setValue('seatubeId', -1)
            setValue('recMode', 0)
        }
    }, [selected])

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    label={t('vessels.form.name')}
                    name="name"
                    size="small"
                    errors={errors?.name}
                    fullWidth
                    rules={{
                        required: {
                            value: true,
                            message: t('comun.form-errors.required')
                        }
                    }}
                />
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    label={t('vessels.form.owner')}
                    name="owner"
                    size="small"
                    errors={errors?.owner}
                    fullWidth
                />
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    label={t('vessels.form.camNo')}
                    name="camNo"
                    size="small"
                    errors={errors?.camNo}
                    fullWidth
                    rules={{
                        required: {
                            value: true,
                            message: t('comun.form-errors.required')
                        }
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    label={t('vessels.form.terminalId')}
                    name="terminalId"
                    size="small"
                    errors={errors?.terminalId}
                    fullWidth
                    rules={{
                        required: {
                            value: true,
                            message: t('comun.form-errors.required')
                        }
                    }}
                />
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    type="number"
                    label={t('vessels.form.albacoraId')}
                    name="albacoraId"
                    size="small"
                    errors={errors?.albacoraId}
                    fullWidth
                    rules={{
                        required: {
                            value: true,
                            message: t('comun.form-errors.required')
                        }
                    }}
                />
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    type="number"
                    label={t('vessels.form.seatubeId')}
                    name="seatubeId"
                    size="small"
                    errors={errors?.seatubeId}
                    fullWidth
                    rules={{
                        required: {
                            value: true,
                            message: t('comun.form-errors.required')
                        }
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    type="number"
                    label={t('vessels.form.recMode')}
                    name="recMode"
                    size="small"
                    errors={errors?.recMode}
                    fullWidth
                    rules={{
                        required: {
                            value: true,
                            message: t('comun.form-errors.required')
                        }
                    }}
                />
                <TextFieldComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    control={control}
                    label={t('vessels.form.imo')}
                    name="imo"
                    size="small"
                    errors={errors?.imo}
                    fullWidth
                />
                <DatePickerComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    defaultValue={null}
                    control={control}
                    label={t('vessels.form.lastHeartBeatDate')}
                    name="lastHeartBeatDate"
                    size="small"
                    errors={errors?.lastHeartBeatDate}
                    fullWidth
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <DatePickerComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    defaultValue={null}
                    control={control}
                    label={t('vessels.form.lastHeartBeatSent')}
                    name="lastHeartBeatSent"
                    size="small"
                    errors={errors?.lastHeartBeatSent}
                    fullWidth
                />
                <DatePickerComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    defaultValue={null}
                    control={control}
                    label={t('vessels.form.dataFrom')}
                    name="dataFrom"
                    size="small"
                    errors={errors?.dataFrom}
                    fullWidth
                    rules={{
                        required: {
                            value: true,
                            message: t('comun.form-errors.required')
                        }
                    }}
                />
                <DatePickerComponent sx={{ m: 1, marginRight: '10px', width: '50%' }}
                    defaultValue={null}
                    control={control}
                    label={t('vessels.form.lastPing')}
                    name="lastPing"
                    size="small"
                    errors={errors?.lastPing}
                    fullWidth
                    rules={{
                        required: {
                            value: true,
                            message: t('comun.form-errors.required')
                        }
                    }}
                /></Box>
        </Box>
    )
}
