import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useForm } from 'react-hook-form'
import { assignFilter } from '../../../../utils/customFunctions'
import { messageDialogTypes } from '../../../../constants'
import MessageSnackBar from '../../../comun/MessageSnackBar'
import FilterFunctions from '../../../comun/FilterFunctions'
import FieldsetComponent from '../../../comun/FieldsetComponent'
import TextFieldComponent from '../../../comun/form_fields/TextFieldComponent'
import AutoCompleteComponent from '../../../comun/form_fields/AutoCompleteComponent'
import { useOrganizationVesselsFavFilterDelete } from '../../../../services/admin/organizations/organization_vessels/useOrganizationVesselsFavFilterDelete'
import { useOrganizationVesselsFavFilterPost } from '../../../../services/admin/organizations/organization_vessels/useOrganizationVesselsFavFilterPost'

export default function OrganizationVesselFilter({ vessels, onFilter, filter, fields, filterVessel, favouriteFilters }) {
    const [t] = useTranslation('common')
    const { register, control, handleSubmit, getValues, setValue, reset } = useForm({ mode: 'onTouched' })

    const { id } = useParams();

    const [checked, setChecked] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState('')
    const [favFilters, setFavFilters] = useState([])// Array de filtros guardados del boton de la vista
    const [filterData, setFilterData] = useState({}) //Guarda los nuevos filtrosFavoritos insertados

    const [openError, setOpenError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [typeMessage, setTypeMessage] = useState(null)

    //Delete & Update
    const { saveFavouriteFilter, successDataFilter, errorPostFilter } = useOrganizationVesselsFavFilterPost()
    const { deleteFavouriteFilter, dataFilter, errorDeleteFilter, isLoadingFilter } = useOrganizationVesselsFavFilterDelete()

    /** Metodo lanzado al buscar, se guardan los valores de los filtros y se mandan a llamar a la API */
    const onSubmit = (data) => {

        var filterData = {
            organizationId: parseInt(id),
            vesselApiId: data?.vessels?.id
        }

        assignFilter(filterData, filter)
        onFilter(filterData)
    }

    const handleCloseError = () => {
        setOpenError(false)
        setErrorMsg(null)
    }

    //FILTROS FAVORITOS
    //Guardar filtro favorito al guardar
    const saveFilter = (filterName) => {

        var formValues = getValues()

        var saveDataFilter = {
            filterName: filterName,
            vesselApiId: formValues.vessels.id
        }

        saveFavouriteFilter(saveDataFilter)
        setFilterData(saveDataFilter)
    }

    useEffect(() => {
        if (dataFilter && filterData) {
            setFavFilters((prevState) => [...prevState, filterData])
        }
    }, [dataFilter, filterData])

    //Carga los filtrosFav
    useEffect(() => {
        if (favouriteFilters) {
            const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
                return {
                    ...filter,
                    filterName,
                }
            })
            setFavFilters(favFiltersArray) // Array de filtros guardados del boton de la vista
        }
    }, [favouriteFilters])

    useEffect(() => {
        if (filterVessel) {
            var vessel = vessels.find((v) => v.id == filterVessel.vesselApiId)
            setValue('vessels', vessel)
        }
    }, [filterVessel]);

    useEffect(() => {
        if (errorPostFilter) {
            setOpenError(true)
            setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
            setErrorMsg(errorPostFilter)
        } else if (errorDeleteFilter) {
            setOpenError(true)
            setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
            setErrorMsg(errorDeleteFilter)
        }
    }, [errorPostFilter, errorDeleteFilter]);

    //Guarda el filtro Fav
    const onClickSavedFilter = (selectedFilter) => {
        setSelectedFilter(selectedFilter)
    }

    /** Función que elimina los filtros favoritos guardados */
    const onDeleteSavedFilter = (event, filterName) => {
        var deleteFavFilter = { organizationId: id, filtername: filterName }
        deleteFavouriteFilter(deleteFavFilter)

        let filters = favFilters.filter((f) => f.filterName != filterName)
        setFavFilters(filters)

        if (event instanceof Event) {
            event.stopPropagation()
        }
    }

    //Limpiar el filtro
    const resetFilter = () => {
        reset()
        setSelectedFilter('')
    }

    return (
        <FieldsetComponent title={t('organization-vessels.filter')}>
            <MessageSnackBar
                openError={openError}
                errorMsg={errorMsg}
                handleCloseError={handleCloseError}
                typeMsg={typeMessage}
            />

            <Box sx={{ display: 'flex' }} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0', m: 1 }}>
                    {fields.map((field) => (
                        <Box sx={{ display: 'flex', width: '100%' }} key={field.name}>
                            <AutoCompleteComponent
                                control={control}
                                defaultValue={null}
                                sx={{ width: '100%', m: 1 }}
                                size='small'
                                label={t('alert.view.filter.vessel')}
                                name='vessels'
                                options={vessels || []}
                                optionsLabel={'description'}
                                keyProperty={'id'}
                                evalMethod={(opt, val) => {
                                    return opt.vesselApiId === val.vesselApiId
                                }}
                            />
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>

                    </Box>
                </Box>
                <Box sx={{ mr: 1 }}>
                    <FilterFunctions
                        filters={favFilters}
                        handleClearClick={() => reset()}
                        onClickSavedFilter={onClickSavedFilter}
                        resetFilter={resetFilter}
                        saveFilter={saveFilter}
                        onDeleteSavedFilter={onDeleteSavedFilter}
                        selectedFilter={selectedFilter}
                    />
                </Box>
            </Box>
        </FieldsetComponent>
    )
}
