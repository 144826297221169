import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Box, Divider } from "@mui/material";

const { modeTypesCRUD, typeOriginIcons } = require('constants');

import BasicDialog from "components/comun/BasicDialog";
import TextFieldComponent from "components/comun/form_fields/TextFieldComponent";
import AutoCompleteComponent from "components/comun/form_fields/AutoCompleteComponent";

import ConfEmailSection from "./ConfigFormSections/ConfEmailSection";
import ConfItemTypeSection from "./ConfigFormSections/ConfItemTypeSection";

import { ConfigNotificationsItemTypeFormProps } from "models/config_notifications/ConfigNotificationComponentsModel";
import { ConfigNotificationModel, ItemTypeNotificationConfigLineModel } from "models/config_notifications/ConfigNotificationModel";
import { SelectValueNumberModel, SelectValueStringIconModel, SelectValueStringModel } from "models/BasicModel";
import SelectIconComponent from "components/comun/form_fields/SelectIconComponent";
import { FishingProfileItemType, FishingProfileItemTypeLine } from "models/fishing_profile/FishingProfileModel";

const ConfNotificationsItemTypeForm: React.FC<ConfigNotificationsItemTypeFormProps> = ({ open, modelSelected, mode, onSave, onClose, fishingProfiles }) => {
    const [t] = useTranslation('common');

    const methodsForm = useForm({
        mode: 'onSubmit',
        defaultValues: {
            description: modelSelected?.description,
            organizationId: 0,
            fishingProfileId: {
                value: mode == modeTypesCRUD.INSERT_MODE ? 0
                    : modelSelected!.fishingProfileId,
                description: mode == modeTypesCRUD.INSERT_MODE ? ''
                    : fishingProfiles.filter(fp => fp.fishingProfileId == modelSelected!.fishingProfileId)[0].description
            },
            itemType: {
                value: mode == modeTypesCRUD.INSERT_MODE ? ''
                    : modelSelected!.itemType,
                description: mode == modeTypesCRUD.INSERT_MODE ? ''
                    : fishingProfiles.filter(fp => fp.fishingProfileId == modelSelected!.fishingProfileId)[0].itemTypeList.filter(it => it.itemType == modelSelected!.itemType)[0].description
            },
            isDeleted: false,
            lines: [],
            emails: modelSelected?.emails // Array inicial con el campo "email"
        },
    });
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = methodsForm;

    const selectFishingProfile: SelectValueNumberModel = watch('fishingProfileId');
    const selectItemType: SelectValueStringModel = watch('itemType');
    //MODELS
    const [modelForm] = useState<ConfigNotificationModel>(modelSelected!);
    const [fishingProfileItemType, setFishingProfileItemType] = useState<FishingProfileItemType | null>(null);
    //OPTIONS LIST MODELS
    const [optionsFishingProfiles, setOptionsFishingProfiles] = useState<SelectValueNumberModel[]>([]);
    const [optionsItemTypes, setOptionsItemTypes] = useState<SelectValueStringIconModel[]>([]);

    const [showOptionsItemTypes, setShowOptionsItemTypes] = useState<boolean>(true);
    const [showItemTypeLines, setShowItemTypeLines] = useState<boolean>(true);

    useEffect(() => {
        if (fishingProfiles && fishingProfiles.length > 0) {
            setOptionsFishingProfiles(fishingProfiles.map(fp => ({ value: fp.fishingProfileId, description: fp.description })));
            onChangeFishingProfile(modelForm.fishingProfileId);
            if (modelForm.id > 0) {
                setFishingProfileItemType(fishingProfiles.filter(fp => fp.fishingProfileId == modelSelected?.fishingProfileId)[0]
                    .itemTypeList.filter(it => it.itemType == modelSelected?.itemType)[0]);
            }

        }
    }, [fishingProfiles]);

    useEffect(() => {
        if (selectFishingProfile) {
            onChangeFishingProfile(selectFishingProfile.value);
            if (selectFishingProfile.value !== modelSelected!.fishingProfileId) {
                setValue('itemType', { value: '', description: '' });
            }
            setShowOptionsItemTypes(true);
        } else if (modelForm.id <= 0) {
            setShowOptionsItemTypes(false);
            setShowItemTypeLines(false);
            setFishingProfileItemType(null);
            setValue('itemType', { value: '', description: '' });
        }
    }, [selectFishingProfile]);

    useEffect(() => {
        if (selectItemType && selectItemType.value !== '') {
            onChangeItemType(selectItemType.value);
        }
    }, [selectItemType]);

    const onChangeFishingProfile = (idFishingProfile: number) => {
        setOptionsItemTypes(!fishingProfiles ? []
            : !fishingProfiles.find(fp => fp.fishingProfileId == idFishingProfile) ? []
                : fishingProfiles?.filter(fp => fp.fishingProfileId == idFishingProfile)[0]
                    .itemTypeList.map(it => ({
                        value: it.itemType,
                        description: it.description,
                        icon: it.itemType
                    })));

        if (modelForm.id <= 0) {
            setShowOptionsItemTypes(true);
            setShowItemTypeLines(false);
            setFishingProfileItemType(null);
        }
    };

    const onChangeItemType = (itemType: string) => {
        if (modelForm.id <= 0) {
            if (itemType && itemType !== "") {
                setFishingProfileItemType(fishingProfiles.filter(fp => fp.fishingProfileId == selectFishingProfile.value)[0]
                    .itemTypeList.filter(it => it.itemType == itemType)[0]);
                setShowItemTypeLines(true);
            } else {
                setFishingProfileItemType(null);
                setShowItemTypeLines(false);
            }
        }
    };

    const OnSubmit = (data: any) => {
        var finalLines: ItemTypeNotificationConfigLineModel[] = [];
        var finalModel: ConfigNotificationModel | null = modelForm;

        if (finalModel && finalModel?.id > 0) {
            finalModel.description = data.description;
            finalModel.emails = data.emails;
        } else {
            finalModel =
            {
                organizationId: 0,
                isDeleted: false,
                id: modelForm!.id,
                description: data.description,
                fishingProfileId: data.fishingProfileId.value,
                itemType: data.itemType.value,
                lines: [],
                emails: data.emails
            };
        }

        var linesNo: FishingProfileItemTypeLine[] = fishingProfiles
            .filter(fp => fp.fishingProfileId == finalModel!.fishingProfileId)[0].itemTypeList
            .filter(it => it.itemType == finalModel!.itemType)[0].lineList.sort(l => l.lineNo);

        for (var idx = 0; idx < linesNo.length; idx++) {
            var configLine: ItemTypeNotificationConfigLineModel = {
                inputType: linesNo[idx].type,
                lineNo: linesNo[idx].lineNo,
                comboValue: null,
                tbLikeValue: null,
                tbMinValue: null,
                tbMaxValue: null
            };

            var auxLine: any = (data.lines as any[]).filter(L => L.lineNo == configLine.lineNo)[0];
            if (linesNo[idx].type.trim().toUpperCase() == "DD") {
                configLine.comboValue = auxLine.comboValue.value;
            } else {
                switch (linesNo[idx].inputType as string) {
                    case "STRING":
                        configLine.tbLikeValue = auxLine.tbLikeValue;
                        break;
                    case "DECIMAL":
                        configLine.tbMinValue = auxLine.tbMinValue;
                        configLine.tbMaxValue = auxLine.tbMaxValue;
                        break;
                    case "INTEGER":
                        configLine.tbMinValue = auxLine.tbMinValue;
                        configLine.tbMaxValue = auxLine.tbMaxValue;
                        break;
                    default:
                        break;
                }
            }

            if ((configLine.comboValue != null && configLine.comboValue !== '')
                || (configLine.tbLikeValue != null && configLine.tbLikeValue !== '')
                || (configLine.tbMaxValue != null && configLine.tbMaxValue !== '')
                || (configLine.tbMinValue != null && configLine.tbMinValue !== '')) {
                finalLines.push(configLine);
            }

        }

        finalModel!.lines = finalLines;

        onSave(finalModel!);
    }

    const getTitle = () => {
        if (modeTypesCRUD.UPDATE_MODE === mode) {
            return t('organization-itemtypes.form.title-update')
        } else if (modeTypesCRUD.INSERT_MODE === mode) {
            return t('organization-itemtypes.form.title-new')
        }
    }

    return (
        <Box>
            {open
                ? (
                    <Box component="form" onSubmit={handleSubmit((data) => OnSubmit(data))}>
                        <BasicDialog title={getTitle()}
                            onOpen={open}
                            onClose={onClose}
                            saveTxt={t('comun.actions.save')}
                            closeTxt={undefined}
                            onSave={undefined}
                            disabled={undefined}
                            noactions={undefined}
                            fullScreen={undefined}
                            hideBackdrop={undefined}
                            closeColor={undefined}
                            annotationModal={undefined}
                            paperProps={undefined}
                        >
                            <Box sx={{ minWidth: '1000px', maxHeight: '600px', width: '100%', overflowY: 'auto' }}>
                                <Box sx={{ width: '95%' }}>
                                    <Box sx={{ width: '100%', mb: 1 }}>
                                        <TextFieldComponent
                                            defaultValue={modelForm?.description}
                                            sx={{ minWidth: '100%', mt: 1 }}
                                            rules={undefined}
                                            control={control}
                                            label={t('comun.otherTexts.description')}
                                            name='description'
                                            size='small'
                                            errors={errors?.description}
                                            InputProps={undefined}
                                            autoComplete={undefined}
                                            autoFocus={undefined}
                                            disabled={undefined}
                                            required={undefined}
                                            type={undefined}
                                            rows={undefined}
                                        />
                                    </Box>
                                    <Box sx={{ width: '100%', mb: 1 }}>
                                        {optionsFishingProfiles && optionsFishingProfiles.length > 0
                                            ?
                                            <AutoCompleteComponent
                                                size='small'
                                                sx={{ minWidth: '100%', mt: 1 }}
                                                control={control}
                                                defaultValue={modelForm?.fishingProfileId
                                                    ? {
                                                        value: modelForm!.fishingProfileId,
                                                        description: optionsFishingProfiles.filter(op => op.value == modelForm?.fishingProfileId)[0].description
                                                    } as SelectValueNumberModel
                                                    : null}
                                                name='fishingProfileId'
                                                label={t('comun.otherTexts.fishingProfile')}
                                                errors={errors?.fishingProfileId}
                                                optionsLabel={'description'}
                                                keyProperty={'value'}
                                                options={optionsFishingProfiles}
                                                disabled={modelForm?.id > 0 ? true : false}
                                                evalMethod={undefined}
                                                multiple={false}
                                                required={undefined}
                                                rules={undefined}
                                            />
                                            : null
                                        }
                                    </Box>
                                    <Box sx={{ width: '100%', mb: 1 }}>
                                        {/* {showOptionsItemTypes */}
                                            {/* ? */}
                                            <SelectIconComponent
                                                size='small'
                                                sx={{ minWidth: '100%', mt: 1 }}
                                                control={control}
                                                defaultValue={modelForm?.itemType
                                                    ? {
                                                        value: modelForm!.itemType,
                                                        description: optionsItemTypes.filter(op => op.value == modelForm?.itemType)[0]?.description,
                                                        icon: modelForm!.itemType
                                                    } as SelectValueStringIconModel
                                                    : null}
                                                name='itemType'
                                                label={t('organization-itemtypes.form.itemType')}
                                                errors={errors?.itemType}
                                                optionsLabel={'description'}
                                                optionsValue={'value'}
                                                optionsIcon={'icon'}
                                                iconsSource={typeOriginIcons.LOCAL_ICON_BASE}
                                                options={optionsItemTypes}
                                                disabled={modelForm?.id > 0 || !selectFishingProfile}
                                                multiple={false}
                                                rules={undefined}
                                                chip={undefined}
                                                key={'value'}
                                            />
                                            {/* : null */}
                                        {/* } */}
                                    </Box>
                                </Box>

                                {
                                    showItemTypeLines
                                        ?
                                        (<Box sx={{ width: '95%' }}>
                                            <ConfItemTypeSection fishingProfileItemType={fishingProfileItemType}
                                                itemTypeNotificationConfigLineList={modelForm ? modelForm.lines : []}
                                                methodsForm={methodsForm}
                                            />
                                        </Box>)
                                        : null
                                }


                                <Box sx={{ width: '95%' }}>
                                    <ConfEmailSection listMails={modelForm.emails} methodsForm={methodsForm} />
                                </Box>
                            </Box>
                        </BasicDialog>

                    </Box>
                )
                : null
            }
        </Box>
    );
};
export default ConfNotificationsItemTypeForm;