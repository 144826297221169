import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { Box, Divider, Grid, Paper } from '@mui/material'

import { baseFilter, messageDialogTypes, modeTypesCRUD, codesActivityTimer } from '../../constants'
import EntityFilter from './organization/EntityFilter'
import { appRoutes } from '../../constants'
import OrganizationsTable from './organization/OrganizationsTable'
import OrganizationsForm from './organization/OrganizationsForm'
import { useOrganizationRequest } from '../../services/admin/organizations/useOrganizationRequest'
import { useOrganizationFilterRequest } from '../../services/admin/organizations/useOrganizationFilterRequest'
import { useOrganizationUpdate } from '../../services/admin/organizations/useOrganizationUpdate'
import { useOrganizationInsert } from '../../services/admin/organizations/useOrganizationInsert'
import { useorganizationDelete } from '../../services/admin/organizations/useOrganizationDelete'
import { useOrganizationActivate } from '../../services/admin/organizations/useOrganizationActivate'
import ConfirmationDialog from '../comun/ConfirmationDialog'
import BackdropComponent from '../comun/utils/BackdropComponent'
import MessageSnackBar from '../comun/MessageSnackBar'

/** Filtros iniciales (orderDirection,orderField,pageNo,pageSize) */
const initialFilter = { ...baseFilter, orderDirection: 'desc', orderField: 'id' }

export default function AdminOrganizationsContainer() {
    const [t] = useTranslation('common')
    const history = useHistory();

    const [selectedOrg, setSelectedOrg] = useState(null)
    const [vessels, setVessels] = useState([])
    const [fishingProfiles, setFishingProfiles] = useState([])

    const [organizationList, setOrganizationList] = useState(null)

    /* == Form == */
    const [open, setOpen] = useState(false)
    const [mode, setMode] = React.useState(modeTypesCRUD.INSERT_MODE)
    const [openDelete, setOpenDelete] = useState(false)
    const [openClear, setOpenClear] = useState(false)
    const [openReactivate, setOpenReactivate] = useState(false)

    /** Filtro*/
    const [filter, setFilter] = useState(initialFilter)
    const [favouriteFilters, setFavourites] = React.useState(null)
    const [filterOrganization, setFilterOrganization] = useState('')

    /* == Control de errores == */
    const [openError, setOpenError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [typeMessage, setTypeMessage] = useState(null)

    /* == CRUD == */
    const { organizationRequest, isLoading, organizationData, errorOrganizationData } = useOrganizationRequest()
    const { organizationFilterRequest, isLoadingOrgFilter, orgFilter, errorOrgFilter } = useOrganizationFilterRequest()
    const { organizationUpdate, organizationDataUpdate, isLoadingUpdate, errorOrgsUpdate } = useOrganizationUpdate()
    const { organizationInsert, organizationDataInsert, isLoadingInsert, errorOrgInsert } = useOrganizationInsert()
    const { organizationDelete, isLoadingDelete, organizationDataDelete, errorOrganizationDelete } = useorganizationDelete()
    const { orgActivate, isLoadingOrgActivate, orgDataActivate, errorOrgActivate } = useOrganizationActivate()


    /** Método de busqueda */
    const onFilter = (filter) => {
        setFilter(filter)
        organizationRequest(filter)
    }

    /** Efecto 1ª llamada de carga inicial*/
    useEffect(() => {
        organizationFilterRequest()
    }, [])

    /** Efecto de la carga con el filtro de busqueda*/
    useEffect(() => {
        if (organizationData != null) {
            setOrganizationList(organizationData)
        }
    }, [organizationData])

    /** Efecto que carga los datos de la API call */
    useEffect(() => {
        if (orgFilter != null) {
            setOrganizationList(orgFilter.resultFilter)
            setFilterOrganization(orgFilter.filter)
            setFavourites(orgFilter.favouritesDic)
            setFishingProfiles(orgFilter.fishingProfiles)
            setVessels(orgFilter.vessels)
        }
    }, [orgFilter])

    /** Efecto que recarga la vista si se realiza CRUD */
    useEffect(() => {
        if (organizationDataDelete || orgDataActivate || organizationDataUpdate || organizationDataInsert) {
            organizationRequest(filter)
        }
        setOpen(false)
    }, [organizationDataDelete, orgDataActivate, organizationDataUpdate, organizationDataInsert])

    /**Efecto que recoge los errores de la primera carga */
    useEffect(() => {
        if (errorOrgFilter) {
            setOpenError(true)
            setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
            setErrorMsg(errorOrgFilter)
        }
    }, [orgFilter]);

    /**Efecto que recoge los errores de la carga con filtro */
    useEffect(() => {
        if (errorOrganizationData) {
            setOpenError(true)
            setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
            setErrorMsg(errorOrganizationData)
        }
    }, [organizationData]);

    /** Efecto que recoge los errores del CRUD */
    useEffect(() => {
        if (errorOrganizationDelete) {
            setOpenError(true)
            setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
            setErrorMsg(errorOrganizationDelete)
        } else if (errorOrgInsert) {
            setOpenError(true)
            setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
            setErrorMsg(errorOrgInsert)
        } else if (errorOrgsUpdate) {
            setOpenError(true)
            setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
            setErrorMsg(errorOrgsUpdate)
        } else if (errorOrgActivate) {
            setOpenError(true)
            setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
            setErrorMsg(errorOrgActivate)
        }
    }, [errorOrganizationDelete, errorOrgInsert, errorOrgsUpdate, errorOrgActivate]);

    //Revisar en teoria busca cuando hace borrado de org duplicado arriba
    useEffect(() => {
        if (organizationDataDelete) {
            onClose()
            organizationRequest(filter)
        }
    }, [])

    //Redirect
    //const goToNotificationsConfig = (id) =>  history.push(appRoutes.ADMIN_CONFIG_NOTIFICATIONS)

    /*== Eventos de modal ==*/
    const handleOpenForm = (row) => {
        setSelectedOrg(row)
        setOpen(true)
    }

    const handleCloseError = () => {
        setOpenError(false)
        organizationRequest(filter)
    }

    const onClose = () => {
        setOpen(false)
    }

    /** CRUD en modales*/
    const handleFormOrganizations = (row, mode) => {
        setMode(mode)
        if (modeTypesCRUD.UPDATE_MODE === mode) {
            handleOpenForm(row)
        } else if (modeTypesCRUD.DELETE_MODE === mode) {
            setOpenDelete(true)
            setSelectedOrg(row)
        } else if (modeTypesCRUD.INSERT_MODE === mode) {
            handleOpenForm(defaultRow)
        } else if (modeTypesCRUD.CLEAR_MODE === mode) {
            setOpenClear(true)
            setSelectedOrg(row)
        } else if (modeTypesCRUD.ENABLE_MODE === mode) {
            setOpenReactivate(true)
            setSelectedOrg(row)
        }
    }

    /** Redirect */
    const handleOpenNotificationConfiguration = (row) => {
        var route = appRoutes.ADMIN_CONFIG_NOTIFICATIONS.replace(':id', row.id);
        history.push(route);
    };

    const handleOpenOrganizationVessels = (row) => {
        var route = appRoutes.ADMIN_ORGANIZATIONS_VESSELS.replace(':id', row.id)
        history.push(route, { name: row.name });
    }

    const parseData = (data) => {
        const { name, orgPrefix, description, fishingProfiles, vessels } = data;
        const organization = {
            name,
            orgPrefix,
            description,
            isDeleted: false,
            fishingProfiles,
            vessels: vessels
            //vessels: vessels.map(vessel => ({ id: vessel.id, name: vessel.description }))
        }

        return organization
    }

    /*== CRUD ==*/
    const onSave = (data) => {
        var org = parseData(data)

        if (modeTypesCRUD.UPDATE_MODE === mode) {
            org.id = data.id
            organizationUpdate(org)
            setOpen(false)
        } else if (modeTypesCRUD.INSERT_MODE === mode) {
            organizationInsert(org)
            setOpen(false)
        }
    }

    /** DELETE */
    const onCloseDelete = (confirmed) => {
        setOpenDelete(false)
        if (confirmed) {
            organizationDelete(selectedOrg)
        }
    }

    /** Método que llama a la API para reactivar la org*/
    const onCloseReactivate = (confirmed) => {
        setOpenReactivate(false)
        if (confirmed) {
            orgActivate(selectedOrg)
        }
    }

    /** Campos del filtro*/
    const orgFields = [
        { name: 'filter', label: 'Filtro' }
    ]

    /** Campos para rellenar una nueva inserción*/
    const defaultRow = {
        name: '',
        orgPrefix: '',
        description: '',
        vessels: [],
        fishingProfiles: []
    }

    return (
        <Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>

            <BackdropComponent
                open={
                    isLoading ||
                    isLoadingOrgFilter ||
                    isLoadingInsert ||
                    isLoadingUpdate ||
                    isLoadingDelete ||
                    isLoadingOrgActivate
                }
            />

            <MessageSnackBar
                openError={openError}
                errorMsg={errorMsg}
                typeMsg={typeMessage}
                handleCloseError={handleCloseError}
            />

            <Box sx={{ width: '100%' }}>
                <Grid>
                    <EntityFilter
                        filterOrganization={filterOrganization}
                        onFilter={onFilter}
                        filter={filter}
                        fields={orgFields}
                        favouriteFilters={favouriteFilters}></EntityFilter>
                    <Divider sx={{ m: 2 }} variant='middle' />
                    <Paper sx={{ width: '100%' }}>
                        <OrganizationsTable
                            organizationList={organizationList}
                            filter={filter}
                            onFilter={onFilter}
                            handleFormOrganizations={handleFormOrganizations}
                            handleOpenNotificationConfiguration={handleOpenNotificationConfiguration}
                            handleOpenOrganizationVessels={handleOpenOrganizationVessels}>
                        </OrganizationsTable>
                    </Paper>
                </Grid>

                {open && (
                    <OrganizationsForm
                        selectedOrg={selectedOrg}
                        vessels={vessels}
                        fishingProfiles={fishingProfiles}
                        onClose={onClose}
                        mode={mode}
                        open={open}
                        onSave={onSave}
                    //rolesList={rolesList}
                    />
                )}

                <ConfirmationDialog
                    onOpen={openDelete}
                    onClose={onCloseDelete}
                    dialogTitle={t('organizations.form.title-delete')}
                    dialogContent={t('organizations.form.delete-content')}
                    dialogSaveButton={t('comun.actions.delete')}
                    dialogCancelButton={t('comun.actions.close')}></ConfirmationDialog>

                <ConfirmationDialog
                    onOpen={openReactivate}
                    onClose={onCloseReactivate}
                    dialogTitle={t('organizations.form.title-reactivate')}
                    dialogContent={t('organizations.form.reactivate-content')}
                    dialogSaveButton={t('comun.actions.enable')}
                    dialogCancelButton={t('comun.actions.close')}></ConfirmationDialog>
            </Box>
        </Box>

    )
}