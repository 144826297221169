import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useConfigAlertInsert = () => {
	const [configAlertData, setConfigAlertData] = useState(null)
	const [errorConfigAlertInsert, setErrorConfigAlertInsert] = useState(null)
	const [isLoadingInsert, setIsLoadingInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const configAlertInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/notification/config', props)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setConfigAlertData({ data: result.data })
					} else {
						setErrorConfigAlertInsert({ statusText: result.data.errorMsg })
					}
					setIsLoadingInsert(false)
				})
				.catch((error) => {
					setErrorConfigAlertInsert(error.response)
					setIsLoadingInsert(false)
				})

			setConfigAlertData(null)
			setErrorConfigAlertInsert(null)
			setIsLoadingInsert(true)
		},
		[client]
	)

	return {
		configAlertInsert,
		isLoadingInsert,
		configAlertData,
		setConfigAlertData,
		errorConfigAlertInsert,
		setErrorConfigAlertInsert,
	}
}
