import React from 'react'
import { Controller } from 'react-hook-form'
import { Box, Chip, FormHelperText, Typography, SvgIcon } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import {typeOriginIcons} from 'constants';
import { getDeclarationIconAux } from 'components/comun/NotesIconHelper'

export default function SelectIconComponent({
	rules,
	control,
	defaultValue,
	name,
	label,
	errors,
	sx,
	size,
	multiple,
	disabled,
	chip,
	options,
	optionsLabel,
	optionsValue,
    optionsIcon,
	iconsSource
}) {
	const Chips = ({ values }) => {
		return (
			<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
				{values.map((value, index) => (
					<Chip key={index} label={optionsLabel ? value[optionsLabel] : value} />
				))}
			</Box>
		)
	}

	const renderResult = (values) => {
		return chip ? <Chips values={values}></Chips> : (optionsLabel ? values[optionsLabel] : values) + ' '
	}

	const findOptionByValue = (value) => {
		return options.find((option) => (optionsValue ? option[optionsValue] == value : option == value))
	}

	const getIcon = (iconKey) => {
		switch(iconsSource){
			case typeOriginIcons.LOCAL_ICON_BASE:
					return getDeclarationIconAux(iconKey, false);
			default:
				return null;
		}
	};

	return (
		<FormControl sx={sx} error={!!errors} size={size}>
			<Controller
				rules={rules}
				defaultValue={defaultValue || ''}
				name={name}
				control={control}
				render={({
					field: { onChange, value, onBlur },
					fieldState: { invalid, isTouched, isDirty, error },
					formState,
				}) => {
					const handleChange = (e) => {
						const selectedValue = e.target.value

						if (multiple) {
							const selectedValues = Array.isArray(selectedValue) ? selectedValue : []
							const selectedOptions = selectedValues.map((value) => findOptionByValue(value))
							onChange(selectedOptions)
						} else {
							const selectedOption = findOptionByValue(selectedValue)
							onChange(selectedOption ? selectedOption : null)
						}
					}

					return (
						<>
							<InputLabel id={'label-' + name}>{label}</InputLabel>
							<Select
								onBlur={onBlur}
								multiple={multiple}
								disabled={disabled}
								value={
									(value &&
										(multiple
											? value.map((val) => (optionsValue ? val[optionsValue] : val))
											: optionsValue
											? value[optionsValue]
											: value)) ||
									''
								}
								onChange={handleChange}
								labelId={'label-' + name}
								input={<OutlinedInput label={label} />}
								renderValue={(selected) => {
									if (multiple) {
										const selectedOptions = selected.map((val) => findOptionByValue(val))
										return renderResult(selectedOptions)
									} else {
										const selectedOption = findOptionByValue(selected)
										return selectedOption ? selectedOption[optionsLabel] : ''
									}
								}}
								MenuProps={{
									style: {
										maxHeight: 400,
									},
								}}>
								{options ? (
									options.map((opt, idx) => (
										<MenuItem key={idx} value={optionsValue ? opt[optionsValue] : opt}>					
                                            <Box sx={{ display: 'flex' }}>
                                                <SvgIcon component={getIcon(optionsIcon ? opt[optionsIcon] : '')}/>
                                                <Typography sx={{ ml: 2 }}>{optionsLabel ? opt[optionsLabel] : opt}</Typography>
                                            </Box>
										</MenuItem>
									))
								) : (
									<MenuItem key={null} value={''}>
										N/A
									</MenuItem>
								)}
							</Select>
						</>
					)
				}}
			/>
			<FormHelperText>{errors?.message}</FormHelperText>
		</FormControl>
	)
}
