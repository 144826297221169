import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useOrganizationUpdate = () => {
	const [organizationDataUpdate, setOrganizationDataUpdate] = useState(null)
	const [errorOrgsUpdate, serErrorOrgsUpdate] = useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const organizationUpdate = useCallback(
		(organization) => {
			client
				.put(baseUrl + 'horus/admin/organization', organization)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setOrganizationDataUpdate({ data: result.data })
					} else {
						serErrorOrgsUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					serErrorOrgsUpdate(error.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setOrganizationDataUpdate(null)
			serErrorOrgsUpdate(null)
		},
		[client]
	)

	return {
		organizationUpdate,
		isLoadingUpdate,
		organizationDataUpdate,
		setOrganizationDataUpdate,
		errorOrgsUpdate,
		serErrorOrgsUpdate,
	}
}