import React from "react";
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import ErrorBoundary from "../components/ErrorBoundary";
import SensorConfigContainer from "../components/admin/vessels/configuration/SensorConfigContainer";

const SensorConfigPage = () => {
    const history = useHistory()
  return (
    <ErrorBoundary history={history}>
        <SensorConfigContainer></SensorConfigContainer>
    </ErrorBoundary>
  )
}

export default SensorConfigPage
