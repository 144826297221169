import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../../constants'
import { prepareParams } from '../../ServicesUtils'
import { ApiClientContext } from '../../ApiClientProvider'

export const useVesselAlertRequest = () => {
	const [vesselAlertData, setVesselAlertData] = useState(null)
	const [errorVesselAlert, setErrorVesselAlert] = useState(null)
	const [isLoadingVesselAlert, setIsLoadingVesselAlert] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const vesselAlertRequest = useCallback(
		(filter) => {
			client
				.get(baseUrl + 'horus/f3/notification/vessel?' + prepareParams(filter))
				.then((result) => {
					setIsLoadingVesselAlert(false)
					if (result.data.success) {
						setVesselAlertData(result.data)
					} else {
						setErrorVesselAlert({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingVesselAlert(false)
					setErrorVesselAlert(err.response)
				})

			setVesselAlertData(null)
			setErrorVesselAlert(null)
			setIsLoadingVesselAlert(true)
		},
		[client]
	)
	return {
		vesselAlertRequest,
		vesselAlertData,
		errorVesselAlert,
		isLoadingVesselAlert,
	}
}
