import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useorganizationDelete = () => {
	const [organizationDataDelete, setOrganizationDataDelete] = useState(null)
	const [errorOrganizationDelete, setErrorOrganizationDelete] = useState(null)
	const [isLoadingDelete, setIsLoadingDelete] = useState(false)

	const { client } = useContext(ApiClientContext)

	const organizationDelete = useCallback(
		(organization) => {
			client
				.delete(baseUrl + 'horus/admin/organization', { data: organization })
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setOrganizationDataDelete({ data: result.data })
					} else {
						setErrorOrganizationDelete({ statusText: result.data.errorMsg })
					}
					setIsLoadingDelete(false)
				})
				.catch((error) => {
					setErrorOrganizationDelete(error.response)
					setIsLoadingDelete(false)
				})

			setOrganizationDataDelete(null)
			setErrorOrganizationDelete(null)
			setIsLoadingDelete(true)
		},
		[client]
	)

	return {
		organizationDelete,
		isLoadingDelete,
		organizationDataDelete,
		setOrganizationDataDelete,
		errorOrganizationDelete,
		setErrorOrganizationDelete,
	}
}