import { useCallback, useState, useContext } from "react";
import { AxiosInstance } from "axios";

const { baseUrl } = require('constants');

import {ApiClientContext} from"services/ApiClientProvider";

import { BasicResponse, BasicResponseData, PagedResponseList } from "models/BasicModel";
import { ConfigNotificationModel } from "models/config_notifications/ConfigNotificationModel";
import { ConfigNotificationFilterDataResponse, ConfigNotificationFilterResponse, ConfigNotificationTableFilterModel} from "models/config_notifications/ConfigNotificationTableModel";

export const getConfigNotificationsFilterData = ()  => {
    const [configNotificationsFilterRequestRes, setConfigNotificationsFilterRequestRes] = useState<ConfigNotificationFilterDataResponse | null>(null);
    const [isLoadingFilterRequest, setIsLoadingFilterRequest] = useState<boolean>(false);
    const [errorMsgFilterRequest, setErrorMsgFilterRequest] = useState<string | null>(null);

    const context : any = useContext(ApiClientContext)!;
    const client : AxiosInstance = context.client;
    
    const configNotificationFilterRequest = useCallback((orgId: string) => {
        client.get<ConfigNotificationFilterResponse>(baseUrl + 'horus/admin/organization/itemtype/config/filter?organizationId='+orgId)
        .then((response) => {
            console.log(response);
            const filter_response : ConfigNotificationFilterResponse = response.data;
            if(filter_response.success){
                setConfigNotificationsFilterRequestRes(filter_response.data);
            }else{
                setErrorMsgFilterRequest(filter_response.errorMsg);
            }
            setIsLoadingFilterRequest(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoadingFilterRequest(false);
		    setErrorMsgFilterRequest(err.response);
        });
        
        setConfigNotificationsFilterRequestRes(null);
        setErrorMsgFilterRequest(null);
        setIsLoadingFilterRequest(true);
    },[client]);

    return {configNotificationFilterRequest,isLoadingFilterRequest,configNotificationsFilterRequestRes,errorMsgFilterRequest};
};

export const configNotificationsDataTableGetDataTable = () => {
    const [configNotificationsDataTableGetRes, setConfigNotificationsDataTableGetRes] = useState<PagedResponseList<ConfigNotificationModel> | null>(null);
    const [isLoadingDataTableGet, setIsLoadingDataTableGet] = useState<boolean>(false);
    const [errorMsgDataTableGet, setErrorMsgDataTableGet] = useState<string | null>(null);

    const context : any = useContext(ApiClientContext)!;
    const client : AxiosInstance = context.client;

    const configNotificationsDataTableGetReq = useCallback((dataModel : ConfigNotificationTableFilterModel) => {   
        //const requestData = `organizationId=${dataModel.organizationId}&fishingProfileId=${dataModel.fishingProfileId}&itemType=${dataModel.itemType}&email=${dataModel.mail}&includeDeleted=${dataModel.removed}&pageNo=${dataModel.pageNo}&pageSize=${dataModel.pageSize}&orderField=${dataModel.orderField}&orderDirection=${dataModel.orderDirection}`;
        var requestData = `organizationId=${dataModel.organizationId}`;
        if(dataModel.fishingProfileId){
            requestData += `&fishingProfileId=${dataModel.fishingProfileId}`;
        }
        requestData += ``;
        if(dataModel.itemType){
            requestData += `&itemType=${dataModel.itemType}`;
        }
        if(dataModel.mail){
            requestData += `&email=${dataModel.mail}`;
        }
        requestData += `&includeDeleted=${dataModel.removed}&pageNo=${dataModel.pageNo}&pageSize=${dataModel.pageSize}&orderField=${dataModel.orderField}&orderDirection=${dataModel.orderDirection}`;

        client.get<PagedResponseList<ConfigNotificationModel>>(baseUrl + 'horus/admin/organization/itemtype/config?'+ requestData)
        .then((response) => {
            console.log(response);
            const get_response : PagedResponseList<ConfigNotificationModel> = response.data;
            if(get_response.success){
                setConfigNotificationsDataTableGetRes(get_response);
            }else{
                setErrorMsgDataTableGet(get_response.errorMsg);
            }
            setIsLoadingDataTableGet(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoadingDataTableGet(false);
		    setErrorMsgDataTableGet(err.response);
        });
        
        setConfigNotificationsDataTableGetRes(null);
        setErrorMsgDataTableGet(null);
        setIsLoadingDataTableGet(true);
    },[client]);

    return {configNotificationsDataTableGetReq,isLoadingDataTableGet,configNotificationsDataTableGetRes,errorMsgDataTableGet};
};

export const configNotificationDelete = () => {
    const [configNotificationsDeleteRes, setConfigNotificationsDeleteRes] = useState<BasicResponse | null>(null);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [errorMsgDelete, setErrorMsgDelete] = useState<string | null>(null);

    const context : any = useContext(ApiClientContext)!;
    const client : AxiosInstance = context.client;

    const configNotificationsDeleteReq = useCallback((itemConfig: ConfigNotificationModel) => {   
        if(itemConfig.isDeleted)
        {
            client.put<BasicResponse>(baseUrl + 'horus/admin/organization/itemtype/undelete/config', itemConfig )
            .then((response) => {
                //console.log(response);
                const get_response : BasicResponse = response.data;
                if(get_response.success){
                    setConfigNotificationsDeleteRes(get_response);
                }else{
                    setErrorMsgDelete(get_response.errorMsg);
                }
                setIsLoadingDelete(false);
            })
            .catch((err) => {
                //console.log(err);
                setIsLoadingDelete(false);
                setErrorMsgDelete(err.response);
            });
        }
        else
        {
            client.delete<BasicResponse>(baseUrl + 'horus/admin/organization/itemtype/config', { data: itemConfig })
            .then((response) => {
                console.log(response);
                const get_response : BasicResponse = response.data;
                if(get_response.success){
                    setConfigNotificationsDeleteRes(get_response);
                }else{
                    setErrorMsgDelete(get_response.errorMsg);
                }
                setIsLoadingDelete(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoadingDelete(false);
                setErrorMsgDelete(err.response);
            });
        }

        setConfigNotificationsDeleteRes(null);
        setErrorMsgDelete(null);
        setIsLoadingDelete(true);
        },[client]);

    return {configNotificationsDeleteReq,isLoadingDelete,configNotificationsDeleteRes,errorMsgDelete};
};

export const configNotificationUpdate = () => {
    const [configNotificationsUpdateRes, setConfigNotificationsUpdateRes] = useState<BasicResponse | null>(null);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [errorMsgUpdate, setErrorMsgUpdate] = useState<string | null>(null);

    const context : any = useContext(ApiClientContext)!;
    const client : AxiosInstance = context.client;

    const configNotificationsUpdateReq = useCallback((itemConfig: ConfigNotificationModel) => {   
        client.put<BasicResponse>(baseUrl + 'horus/admin/organization/itemtype/config', itemConfig)
        .then((response) => {
            console.log(response);
            const get_response : BasicResponse = response.data;
            if(get_response.success){
                setConfigNotificationsUpdateRes(get_response);
            }else{
                setErrorMsgUpdate(get_response.errorMsg);
            }
            setIsLoadingUpdate(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoadingUpdate(false);
		    setErrorMsgUpdate(err.response);
        });
        
        setConfigNotificationsUpdateRes(null);
        setErrorMsgUpdate(null);
        setIsLoadingUpdate(true);
        },[client]);

    return {configNotificationsUpdateReq,isLoadingUpdate,configNotificationsUpdateRes,errorMsgUpdate};
};

export const configNotificationAdd = () => {
    const [configNotificationsAddRes, setConfigNotificationsAddRes] = useState<BasicResponse | null>(null);
    const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>(false);
    const [errorMsgAdd, setErrorMsgAdd] = useState<string | null>(null);

    const context : any = useContext(ApiClientContext)!;
    const client : AxiosInstance = context.client;

    const configNotificationsAddReq = useCallback((itemConfig: ConfigNotificationModel) => {   
        client.post<BasicResponseData<ConfigNotificationModel>>(baseUrl + 'horus/admin/organization/itemtype/config', itemConfig)
        .then((response) => {
            console.log(response);
            const get_response : BasicResponseData<ConfigNotificationModel> = response.data;
            if(get_response.success){
                setConfigNotificationsAddRes(get_response);
            }else{
                setErrorMsgAdd(get_response.errorMsg);
            }
            setIsLoadingAdd(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoadingAdd(false);
		    setErrorMsgAdd(err.response);
        });
        
        setConfigNotificationsAddRes(null);
        setErrorMsgAdd(null);
        setIsLoadingAdd(true);
        },[client]);

    return {configNotificationsAddReq,isLoadingAdd,configNotificationsAddRes,errorMsgAdd};
};