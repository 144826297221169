import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { modeTypesCRUD } from '../../constants'
import BasicDialog from '../comun/BasicDialog'
import AnalysisTypeProgramForm from './AnalysisTypeProgramForm'
import FormGeneralDataProgram from './GeneralDataProgramForm'
import UsersProgramForm from './UsersProgramForm'
import VesselsProgramForm from './VesselsProgramForm'

export default function ProgramForm({
	programSelected,
	users,
	clients,
	vessels,
	roles,
	fishingProfiles,
	exports,
	mode,
	onclose,
	open,
	onSave,
}) {
	const methodsForm = useForm({ mode: 'onSubmit',
		defaultValues: {
			description: "",
			startDate: new Date(),
			endDate: new Date(),
		},
	 })

	const [t] = useTranslation('common')

	// const [typeAnalysis, setTypeAnalysis] = useState(programSelected?.typeAnalysis)

	// useEffect(() => {
	// 	setTypeAnalysis(programSelected?.typeAnalysis)
	// }, [programSelected?.typeAnalysis])

	const getTitle = () => {
		if (modeTypesCRUD.UPDATE_MODE === mode) {
			return t('programs.form.title-update')
		} else if (modeTypesCRUD.INSERT_MODE === mode) {
			return t('programs.form.title-new')
		}
	}

	return (
		<Box>
			{open ? (
				<Box component='form' onSubmit={methodsForm.handleSubmit((data) => onSave(data))}>
					<BasicDialog title={getTitle()} onOpen={open} saveTxt={t('programs.form.save')} onClose={onclose}>
						<Box sx={{ minWidth: '1000px', maxHeight: '600px', width: '100%', overflowY: 'auto' }}>
							<FormGeneralDataProgram
								methodsForm={methodsForm}
								selected={programSelected}
								clients={clients}></FormGeneralDataProgram>

							<Box sx={{ p: 1, width: '100%', display: 'flex' }}>
								<VesselsProgramForm
									methodsForm={methodsForm}
									selected={programSelected.vessels}
									vessels={vessels}></VesselsProgramForm>
							</Box>
							<Box sx={{ p: 1, width: '100%', display: 'flex' }}>
								<AnalysisTypeProgramForm
									methodsForm={methodsForm}
									typeAnalysisList={programSelected?.typeAnalysis}
									fishingProfiles={fishingProfiles}
									exports={exports}></AnalysisTypeProgramForm>
							</Box>

							<Box sx={{ p: 1, width: '100%', display: 'flex' }}>
								<UsersProgramForm
									methodsForm={methodsForm}
									selected={programSelected.users}
									users={users}
									roles={roles}></UsersProgramForm>
							</Box>
						</Box>
					</BasicDialog>
				</Box>
			) : null}
		</Box>
	)
}
