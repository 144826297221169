import React, { useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import MessageSnackBar from '../../comun/MessageSnackBar'
import FilterFunctions from '../../comun/FilterFunctions'
import FieldsetComponent from '../../comun/FieldsetComponent'
import { messageDialogTypes } from '../../../constants'
import { assignFilter } from '../../../utils/customFunctions'
import { useOrganizationFavFilterPost } from '../../../services/admin/organizations/useOrganizationFavFilterPost'
import { useOrganizationFavFilterDelete } from '../../../services/admin/organizations/useOrganizationFavFilterDelete'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'

export default function EntityFilter({
  onFilter,
  filter,
  fields,
  filterOrganization,
  favouriteFilters
}) {
  const [t] = useTranslation('common')
  const { register, control, handleSubmit, getValues, setValue, reset } = useForm({ mode: 'onTouched' })

  const [checked, setChecked] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [favFilters, setFavFilters] = useState([])// Array de filtros guardados del boton de la vista
  const [filterData, setFilterData] = useState({}) //Guarda los nuevos filtrosFavoritos insertados

  //Limpiar el filtro
  const resetFilter = () => {
    reset()
    setChecked(false)
    setSelectedFilter('')
  }

  //Delete Filter & Post FavFilter
  const { dataFilter, saveFavouriteFilter, errorPostFilter } = useOrganizationFavFilterPost()
  const { deleteFavouriteFilter, errorDeleteFilter } = useOrganizationFavFilterDelete()

  // Set errrors
  const [openError, setOpenError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const handleChange = (e) => {
    setChecked(e.target.checked)
  }

  const handleCloseError = () => {
    setOpenError(false)
    setErrorMsg(null)
  }

  /** Metodo lanzado al buscar, se guardan los valores de los filtros y se mandan a llamar a la API */
  const onSubmit = (data) => {
    var filterInput = data.filter != null ? data.filter : ''

    var filterData = {
      filter: filterInput,
      includeDisabledOrgs: data.includeDisabledOrgsArg,
    }

    assignFilter(filterData, filter)
    onFilter(filterData)
  }

  //Setear los valores guardados de los filtros cuando volvemos de otra pagina
  useEffect(() => {
    if (filterOrganization) {
      setValue('filter', filterOrganization.filter)
    }
  }, [filterOrganization])

  //Guardar filtro favorito al guardar
  const saveFilter = (filterName) => {

    let formValues = getValues()

    let saveDataFilter = {
      filterName: filterName,
      filter: formValues.filter,
      includeDisabledOrgs: formValues.includeDisabledOrgsArg,
    }

    saveFavouriteFilter(saveDataFilter) //Llamada post para meter en BD el favFilter nuevo
    setFilterData(saveDataFilter) //useState que Guarda los nuevos filtrosFavoritos insertados
  }

  //Cuando dataFilter o filterData cambian llama al efecto para actualizar los filtros fav
  useEffect(() => {
    if (dataFilter && filterData) {
      setFavFilters((prevState => [...prevState, filterData]))
    }
  }, [dataFilter, filterData])

  //Carga los filtrosFav
  useEffect(() => {
    if (favouriteFilters) {
      const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
        return {
          ...filter,
          filterName,
        }
      })
      setFavFilters(favFiltersArray) // Array de filtros guardados del boton de la vista
    }
  }, [favouriteFilters])

  //Rellena los campos del filtro con los valores guardados en favFilter
  useEffect(() => {
    if (selectedFilter) {
      setValue('filter', selectedFilter.filter)
      setSelectedFilter('')
    }
  }, [selectedFilter])

  //Guarda el filtro Fav
  const onClickSavedFilter = (selectedFilter) => {
    setSelectedFilter(selectedFilter)
  }

  /** Función que elimina los filtros favoritos guardados */
  const onDeleteSavedFilter = (event, filterName) => {
    deleteFavouriteFilter(filterName)
    let filters = favFilters.filter((f) => f.filterName != filterName)
    setFavFilters(filters)

    if (event instanceof Event) {
      event.stopPropagation()
    }
  }

  return (
    <FieldsetComponent title={t('organizations.fieldset')}>
      <MessageSnackBar
        openError={openError}
        errorMsg={errorMsg}
        handleCloseError={handleCloseError}
        typeMsg={messageDialogTypes.ERROR_MESSAGE}
      />

      <Box sx={{ display: 'flex' }} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0', m: 1 }}>
          {fields.map((field) => (
            <Box sx={{ display: 'flex', width: '100%' }} key={field.name}>
              <TextFieldComponent
                {...register(field.name)}
                name="filter"
                control={control}
                defaultValue={''}
                sx={{ mr: 1, width: '100%' }}
                size="small"
                label={field.label}
              />
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
            <FormControlLabel
              label={t('organizations.filter.includeDisabledOrgsArg')}
              labelPlacement='end'
              sx={{ mr: 1 }}
              control={
                <Checkbox
                  {...register('includeDisabledOrgsArg')}
                  name='includeDisabledOrgsArg'
                  id='includeDisabledOrgsArg'
                  checked={checked}
                  onChange={handleChange}
                />
              }>
            </FormControlLabel>
          </Box>
        </Box>
        <Box sx={{ mr: 1 }}>
          <FilterFunctions
            filters={favFilters}
            handleClearClick={() => reset()}
            onClickSavedFilter={onClickSavedFilter}
            resetFilter={resetFilter}
            saveFilter={saveFilter}
            onDeleteSavedFilter={onDeleteSavedFilter}
            selectedFilter={selectedFilter}
          />
        </Box>
      </Box>
    </FieldsetComponent>
  )
}
