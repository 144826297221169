import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { messageDialogTypes } from '../../../constants'

import { useTranslation } from 'react-i18next'
import { useUserProfileUpdate } from '../../../services/usersprofile/useUserProfileUpdate'
import { useUserClearCache } from '../../../services/usersprofilecache/useUserProfileCacheClear'
import FieldsetComponent from '../../comun/FieldsetComponent'
import MessageSnackBar from '../../comun/MessageSnackBar'
import SelectComponent from '../../comun/form_fields/SelectComponent'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'
import BackdropComponent from '../../comun/utils/BackdropComponent'
import CustomSnackBar from '../../comun/CustomSnackBar'

export default function UsersProfileForm({ userProfile }) {
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onTouched',
	})

	const [t] = useTranslation('common')
	const editedByMaxLength = 50

	const { userProfileUpdate, isLoadingUpdate, userProfileDataUpdate, errorUserProfileUpdate } = useUserProfileUpdate()
	const { userClearCache, isLoadingClear, userProfileClearCacheData, errorUserClearCache } = useUserClearCache()

	const [typeMessage, setTypeMessage] = useState(null)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [openSnack, setOpenSnack] = useState(false)

	const handleCloseSnack = () => {
		setOpenSnack(false)
	}

	const names = [
		{
			description: 'UserAdmin',
			value: 1,
		},
		{
			description: 'FleetAdmin',
			value: 2,
		},
		{
			description: 'FleetStaff',
			value: 3,
		},
		{
			description: 'EMAdmin',
			value: 4,
		},
		{
			description: 'EMStaff',
			value: 5,
		},
		{
			description: 'Admin',
			value: 6,
		},
	]

	useEffect(() => {
		if (userProfile) {
			reset(userProfile)
		}
	}, [userProfile])

	useEffect(() => {
		setOpenError(false)
		setErrorMsg(null)
	}, [userProfileDataUpdate])

	useEffect(() => {
		setOpenSnack(false)
		userProfileClearCacheData?.data?.success ? setOpenSnack(true) : setOpenSnack(false)
	}, [userProfileClearCacheData])

	useEffect(() => {
		if (errorUserProfileUpdate || errorUserClearCache) {
			setOpenError(true)
			setErrorMsg(errorUserProfileUpdate || errorUserClearCache)
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
		} else {
			setOpenError(false)
			setErrorMsg(null)
			setTypeMessage(null)
		}
	}, [errorUserProfileUpdate, errorUserClearCache])

	const onSubmit = (data) => {
		userProfileUpdate(data)
	}

	const clearCache = () => {
		userClearCache()
	}

	const onError = () => {
		//Función si hay errores de validación
	}

	return (
		// <>
		// 	{userProfile != null && (
		<Box
			component='form'
			onSubmit={handleSubmit(onSubmit, onError)}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				mt: '5%',
			}}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={typeMessage}
				handleCloseError={() => setOpenError(false)}
			/>
			<CustomSnackBar
				open={openSnack}
				handleClose={handleCloseSnack}
				message={t('anotaciones-form.annotation-saved-success')}
				autoHideDuration={5000}
			/>
			<BackdropComponent open={isLoadingUpdate || isLoadingClear}></BackdropComponent>
			<FieldsetComponent title={t('profile.personal-data')} style={{ width: '50%' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<TextFieldComponent
						size='small'
						sx={{ m: 1, width: '99%' }}
						control={control}
						defaultValue={'userName'}
						disabled={true}
						name='userName'
						label={t('users.form.username')}
						errors={errors?.userName}
						rules={{
							maxLength: {
								value: editedByMaxLength,
								message: `${t('comun.form-errors.max-length')} ${editedByMaxLength}`,
							},
						}}
					/>

					<TextFieldComponent
						size='small'
						sx={{ m: 1, width: '99%' }}
						control={control}
						defaultValue={'tf-name'}
						name='name'
						label={t('users.form.name')}
						errors={errors?.name}
						rules={{
							required: {
								value: true,
								message: t('comun.form-errors.required'),
							},
						}}
					/>

					<TextFieldComponent
						size='small'
						sx={{ m: 1, width: '99%' }}
						control={control}
						defaultValue={'tf-surname'}
						name='surname'
						label={t('users.form.surname')}
						errors={errors?.surname}
						rules={{
							required: {
								value: true,
								message: t('comun.form-errors.required'),
							},
						}}
					/>

					<TextFieldComponent
						size='small'
						sx={{ m: 1, width: '99%' }}
						control={control}
						defaultValue={'tf-email'}
						name='email'
						label={t('users.form.email')}
						errors={errors?.email}
						rules={{
							required: {
								value: true,
								message: t('comun.form-errors.required'),
							},
							pattern: {
								value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, //eslint-disable-line
								message: 'Email no válido',
							},
						}}
					/>

					<SelectComponent
						size='small'
						sx={{ m: 1, width: '99%' }}
						control={control}
						defaultValue={userProfile?.roles ? userProfile?.roles : []}
						name='roles'
						label={t('users.form.roles')}
						errors={errors?.roles}
						multiple
						disabled
						optionsLabel={'description'}
						optionsValue={'value'}
						chip
						options={userProfile?.allRoles ? userProfile?.allRoles : names}
					/>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button onClick={() => clearCache()} sx={{ ml: '8px' }} autoFocus variant='outlined'>
						{t('comun.actions.clear-cache')}
					</Button>
					<Button type='submit' autoFocus variant='outlined'>
						{t('comun.actions.save')}
					</Button>
				</Box>
			</FieldsetComponent>
		</Box>
	)
}

UsersProfileForm.defaultProps = {
	//mode: modeTypesCRUD.INSERT_MODE,
	open: false,
	close: () => {},
}
