import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, Box, Button, SvgIcon, TextField, Tooltip } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useState } from 'react'
import { getDeclarationIconAux } from '../../../comun/NotesIconHelper'
import TextFieldComponent from '../../../comun/form_fields/TextFieldComponent'

export default function AnnotationFilter({ onFilter, t, itemTypeList, cameraEnum }) {
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [itemType, setItemType] = useState(null)
	const [camera, setCamera] = useState(null)
	const [hasComments, setHasComments] = useState(null)
	const [comments, setComments] = useState(null)

	const handleChangeStart = (newValue) => {
		setStartDate(newValue)
	}
	const handleChangeEnd = (newValue) => {
		setEndDate(newValue)
	}
	const handleClearFilter = () => {
		setStartDate(null)
		setEndDate(null)
		setItemType(null)
		setCamera(null)
		setHasComments(null)
		setComments('')
		onFilter(null, null, null, null, null, null)
	}

	const hasCommentsOptions = [
		{ label: t('comun.actions.yes'), value: 1 },
		{ label: t('comun.actions.no'), value: 0 },
	]

	return (
		<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', p: 0.5 }}>
			<Box sx={{ width: '100%', display: 'flex', mb: 0.75 }}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateTimePicker
						clearable
						minTime={0}
						disableFuture={true}
						onChange={handleChangeStart}
						label={t('preview.annotation.from')}
						value={startDate}
						inputFormat='dd/MM/yyyy HH:mm'
						renderInput={(params) => <TextField size='small' {...params} sx={{ width: '33%', mr: 0.5 }} />}
					/>
				</LocalizationProvider>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateTimePicker
						clearable
						minTime={0}
						disableFuture={true}
						onChange={handleChangeEnd}
						label={t('preview.annotation.to')}
						value={endDate}
						inputFormat='dd/MM/yyyy HH:mm'
						renderInput={(params) => <TextField size='small' {...params} sx={{ width: '33%', mr: 0.5 }} />}
					/>
				</LocalizationProvider>
				<Autocomplete
					sx={{ width: '33%' }}
					options={hasCommentsOptions}
					value={hasComments}
					onChange={(event, newValue) => {
						setHasComments(newValue)
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('preview.annotation.has-comments')}
							variant='outlined'
							size='small'
						/>
					)}
				/>
			</Box>
			<Box sx={{ width: '100%', display: 'flex' }}>
				<Autocomplete
					sx={{ width: '35%', mr: 0.5 }}
					getOptionLabel={(option) => option.description}
					options={itemTypeList}
					value={itemType}
					onChange={(event, newValue) => {
						setItemType(newValue)
					}}
					renderOption={(props, option) => (
						<Box {...props} component='li' key={option.itemType}>
							<SvgIcon component={getDeclarationIconAux(option.itemType, false)} />
							{' ' + option.description}
						</Box>
					)}
					renderInput={(params) => (
						<TextField {...params} label={t('preview.annotation.type')} variant='outlined' size='small' />
					)}
				/>
				<Autocomplete
					sx={{ width: '13%', mr: 0.5 }}
					getOptionLabel={(option) => option.toString()}
					options={cameraEnum}
					value={camera}
					onChange={(event, newValue) => {
						setCamera(newValue)
					}}
					renderInput={(params) => (
						<TextField {...params} label={t('preview.annotation.camera')} variant='outlined' size='small' />
					)}
				/>
				<TextField
					sx={{ width: '45%', mr: 0.5 }}
					size='small'
					label={t('preview.annotation.comment')}
					name='comments'
					onChange={(event) => {
						setComments(event.target.value)
					}}
					value={comments}
				/>
				<Box sx={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
					<Tooltip title={t('comun.actions.clear')} placement='top'>
						<Button
							color='secondary'
							variant='outlined'
							sx={{ height: '40px', maxWidth: '30%', minWidth: '0px', m: 0, mr: 0.5 }}
							onClick={handleClearFilter}>
							<BackspaceOutlinedIcon />
						</Button>
					</Tooltip>
					<Tooltip title={t('comun.actions.search')} placement='top'>
						<Button
							variant='outlined'
							sx={{ height: '40px', maxWidth: '30%', minWidth: '0px', m: 0 }}
							onClick={() => onFilter(startDate, endDate, hasComments, itemType, camera, comments)}>
							<SearchIcon />
						</Button>
					</Tooltip>
				</Box>
			</Box>
		</Box>
	)
}
