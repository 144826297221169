import React, { useState, useEffect } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Table, TableBody, TableCell, TableHead, TableContainer, TableFooter, TableRow, IconButton, Tooltip } from '@mui/material'
import TablePaginationComponent from '../../comun/TablePaginationComponent'
import { EnhancedTableHead } from '../../comun/TableUtils'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ReplayIcon from '@mui/icons-material/Replay'

import ModeStandbyIcon from '@mui/icons-material/ModeStandby'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { useTranslation } from 'react-i18next'
import { appRoutes, modeTypesCRUD } from '../../../constants'
import { Collapse } from '@mui/material'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function VesselsTable({ vesselList, filter, onFilter, handleFormVessels }) {
    const [t] = useTranslation('common')
    const history = useHistory()

    /** REDIRECT */
    const goToSensorConfig = () => history.push(appRoutes.SENSOR_CONFIG)
    const goToRequestConfig = () => history.push(appRoutes.REQUEST_CONFIG)

    /* == TABLE == */ 
    const [order, setOrder] = useState(filter.orderDirection)
    const [orderBy, setOrderBy] = useState(filter.orderField)
    const [page, setPage] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(filter.pageSize)
    const [openRow, setOpenRow] = useState({});

    /** Método para actualizar el icono del desplegable */
    const toggleRow = (id) => {
        setOpenRow((prevOpenRow) => ({
            ...prevOpenRow,
            [id]: !prevOpenRow[id]
        }));
    };

    useEffect(() => {
        if (filter) {
            setRowsPerPage(filter.pageSize)
            setOrder(filter.orderDirection)
            setOrderBy(filter.orderField)
        }
    }, [filter])

    const handleChangePage = (event, newPage) => {
        let newFilter = { ...filter }
        newFilter.pageNo = newPage + 1
        onFilter(newFilter)
    }

    const handleChangeRowsPerPage = (event) => {
        let newFilter = { ...filter }
        newFilter.pageSize = event.target.value
        newFilter.pageNo = 1
        onFilter(newFilter)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        let newFilter = { ...filter }
        newFilter.orderDirection = isAsc ? 'desc' : 'asc'
        newFilter.orderField = property
        newFilter.pageNo = 1
        onFilter(newFilter)
    }

    useEffect(() => {
        if (vesselList) {
            setPage(vesselList.pageNo - 1)
            setTotalCount(vesselList.totalCount)
        }
    }, [vesselList])

    const handleAddAction = () => {
        handleFormVessels(null, modeTypesCRUD.INSERT_MODE)
    }

    /** Table columns */
    const columns = [
        // { "Id", "Name", "Owner", "CamNo" y "TerminalId"}, 
        // { "AlbacoraId", "SeatubeId", "RecMode", "IMO", "LastHeartBeatDate", "LastHeartBeatSent", "DataFrom", "LastPing"}
        {
            id: '',
            align: 'left',
            orderBy: true,
            label: '',
        },
        {
            id: 'id',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.id'),
        },
        {
            id: 'name',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.name'),
        },
        {
            id: 'owner',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.owner'),
        },
        {
            id: 'camNo',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.camNo'),
        },
        {
            id: 'terminalId',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.terminalId'),
        },
        {
            id: 'actions',
            align: 'center',
            orderBy: true,
            label: '',
            excluirExcel: true,
        }
    ]

    /** Extra columns */
    const extraColumns = [
        {
            id: 'albacoraId',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.albacoraId'),
        },
        {
            id: 'seatubeId',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.seatubeId'),
        },
        {
            id: 'recMode',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.recMode'),
        },
        {
            id: 'imo',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.camNo'),
        },
        {
            id: 'lastHeartBeatDate',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.lastHeartBeatDate'),
        },
        {
            id: 'lastHeartBeatSent',
            align: 'center',
            orderBy: true,
            excluirExcel: true,
            label: t('vessels.table.header.lastHeartBeatSent'),
        },
        {
            id: 'dataFrom',
            align: 'left',
            orderBy: true,
            label: t('vessels.table.header.dataFrom'),
        },
        {
            id: 'lastPing',
            align: 'center',
            orderBy: true,
            excluirExcel: true,
            label: t('vessels.table.header.lastPing'),
        }
    ]

    return (
        <TableContainer>
            <Table
                id="table-vessels-list"
                aria-labelledby="tableTitle"
                size="small"
                sx={{ minWidth: 750 }}
                style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}
            >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={columns}
                    addAction={handleAddAction}
                />
                <TableBody>
                    {vesselList && vesselList.data.map((row, index) => (
                        <React.Fragment key={row.id}>
                            <TableRow key={index}>
                                <TableCell style={{width: '50px'}}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => toggleRow(row.id)}
                                    >
                                        {openRow[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell align="left">{row.id}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.owner}</TableCell>
                                <TableCell align="left">{row.camNo}</TableCell>
                                <TableCell align="left">{row.terminalId}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title={t('vessels.table.content.update')}>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleFormVessels(row, modeTypesCRUD.UPDATE_MODE)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {row.isActive ? (
                                        <Tooltip title={t('vessels.table.content.delete')}>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleFormVessels(row, modeTypesCRUD.DELETE_MODE)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={t('vessels.table.content.enable')}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleFormVessels(row, modeTypesCRUD.ENABLE_MODE)}
                                            >
                                                <ReplayIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={t('vessels.table.content.sensorConfig')}>
                                        <IconButton
                                        color='primary'
                                        onClick={goToSensorConfig}>
                                            <ModeStandbyIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('vessels.table.content.requestConfig')}>
                                        <IconButton
                                        color='primary'
                                        onClick={goToRequestConfig}>
                                            <DisplaySettingsIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            {/* Fila colapsable */}
                            <TableRow >
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                    <Collapse in={openRow[row.id]} timeout="auto" unmountOnExit>
                                        <Box margin={1} style={{ width: "100%" }}>
                                            <Table size="small" aria-label="details">
                                                <TableHead>
                                                    <TableRow >
                                                        {extraColumns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align='right'
                                                                style={{ fontWeight: 'bold' }}>
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="right" style={{ borderBottom: "none" }} >{row.albacoraId}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "none" }} >{row.seatubeId}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "none" }} >{row.recMode}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "none" }} >{row.imo}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "none" }} >{row.lastHeartBeatDate}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "none" }} >{row.lastHeartBeatSent}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "none" }} >{row.dataForm}</TableCell>
                                                        <TableCell align="right" style={{ borderBottom: "none" }} >{row.lastPing}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
                <TableFooter className="excluir-excel">
                    <TableRow style={{ height: 52 }}>
                        <TablePaginationComponent
                            rowsPerPageOptions={[5, 10, 25]}
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            customActions={true}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}
