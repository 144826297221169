import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { baseFilter, messageDialogTypes, modeTypesCRUD, codesActivityTimer } from '../../../constants'
import { useZoneAlertFilterRequest } from '../../../services/alerts/zonealert/useZoneAlertFilterRequest'
import { useZoneActivate } from '../../../services/alerts/zonealert/useZoneActivate'
import { useZoneAlertInsert } from '../../../services/alerts/zonealert/useZoneAlertInsert'
import { useZoneAlertRequest } from '../../../services/alerts/zonealert/useZoneAlertRequest'
import { useZoneDesactivate } from '../../../services/alerts/zonealert/useZoneDesactivate'
import { useUserLog } from '../../../services/timer/useUserLog'
import MessageSnackBar from '../../comun/MessageSnackBar'
import BackdropComponent from '../../comun/utils/BackdropComponent'
import AlertZonesFilter from './AlertZonesFilter'
import AlertZonesForm from './AlertZonesForm'
import AlertZonesMapPopup from './AlertZonesMapPopup'
import AlertZonesTable from './AlertZonesTable'
import { getGeneralActivity } from './../../../utils/TimerDataUtils'


//Set de column to sort and the direction for the first search, baseFilter contains the rowsPerPage and pageNo
const initialFilter = { ...baseFilter, orderDirection: 'asc', orderField: 'name' }

export default function AlertZonesContainer() {
	const [t] = useTranslation('common')

	//Services
	const { userLogRequest } = useUserLog()
	const { zoneAlertFilterRequest, isLoadingZoneAlertFilter, zoneAlertFilter, errorZoneAlertFilter } = useZoneAlertFilterRequest()
	const { zoneAlertRequest, dataZoneAlert, errorZoneAlert, isLoadingZoneAlert } = useZoneAlertRequest()
	const { dataZoneActivate, errorZoneActivate, isLoadingZoneActivate, zoneActivate } = useZoneActivate()
	const { dataZoneDesactivate, errorZoneDesactivate, isLoadingZoneDesactivate, zoneDesactivate } = useZoneDesactivate()
	const { zoneAlertInsert, dataZoneAlertInsert, errorZoneAlertInsert, isLoadingZoneAlertInsert } = useZoneAlertInsert()

	//Filter
	const [filter, setFilter] = useState(initialFilter)

	const [alertZoneFilter, setAlertZoneFilter] = useState(null)
	const [alertZoneList, setAlertZoneList] = useState(null)
	const [favouriteFilters, setFavourites] = useState(null)
	const [statusOptions, setStatusOptions] = useState(null)

	//Errors control
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	//Modals
	const [openForm, setOpenForm] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)
	const [openMap, setOpenMap] = useState(false)

	const onFilter = (data) => {
		setFilter(data)
		zoneAlertRequest(data)
	}

	useEffect(() => {
		zoneAlertFilterRequest()
		userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_ZONE_VIEW, "ALERTS_ZONE_VIEW"))
	}, [])

	useEffect(() => {
		if (dataZoneAlert) {
			setAlertZoneList(dataZoneAlert)
		}
	}, [dataZoneAlert])

	useEffect(() => {
		if (zoneAlertFilter) {
			setAlertZoneFilter(zoneAlertFilter.filterNotificationsZone)
			setAlertZoneList(zoneAlertFilter.resultFilter)
			setFavourites(zoneAlertFilter.favouritesDic)
			setStatusOptions(zoneAlertFilter.statusOptions)
		}
	}, [zoneAlertFilter])


	useEffect(() => {
		if (dataZoneActivate || dataZoneDesactivate || dataZoneAlertInsert) {
			onFilter(filter)

			if (dataZoneActivate) {
				userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_ZONE_ACT, "ALERTS_ZONE_ACT"))
			}
			if (dataZoneDesactivate) {
				userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_ZONE_DEACT, "ALERTS_ZONE_DEACT"))
			}
		}

	}, [dataZoneActivate, dataZoneDesactivate, dataZoneAlertInsert])

	useEffect(() => {
		if (errorZoneAlertFilter) {
			setOpenError(true)
			setErrorMsg(errorZoneAlertFilter)
		} else if (errorZoneAlert) {
			setOpenError(true)
			setErrorMsg(errorZoneAlert)
		} else if (errorZoneActivate) {
			setOpenError(true)
			setErrorMsg(errorZoneActivate)
		} else if (errorZoneDesactivate) {
			setOpenError(true)
			setErrorMsg(errorZoneDesactivate)
		} else if (errorZoneAlertInsert) {
			setOpenError(true)
			setErrorMsg(errorZoneAlertInsert)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorZoneAlertFilter, errorZoneAlert, errorZoneActivate, errorZoneDesactivate, errorZoneAlertInsert])

	const handleShowMap = (row) => {
		setSelectedRow(row)
		setOpenMap(true)
	}

	const onCloseMap = () => {
		setSelectedRow(null)
		setOpenMap(false)
	}

	const handleAddAction = () => {
		setOpenForm(true)
	}
	const onClose = () => {
		setOpenForm(false)
	}

	const onSave = (data, file) => {
		zoneAlertInsert(data, file)
		userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_ZONE_CREATE))

		setOpenForm(false)
	}

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<BackdropComponent
				open={isLoadingZoneAlertFilter || isLoadingZoneAlert || isLoadingZoneActivate || isLoadingZoneDesactivate || isLoadingZoneAlertInsert}
			/>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box sx={{ width: '100%' }}>
				<AlertZonesFilter
					alertZoneFilter={alertZoneFilter}
					onFilter={onFilter}
					filter={filter}
					favouriteFilters={favouriteFilters}
					statusOptions={statusOptions}
				></AlertZonesFilter>
			</Box>

			<Divider sx={{ my: 2 }} variant='middle' />

			<Box>
				<AlertZonesTable
					alertZonesList={alertZoneList}
					filter={filter}
					onFilter={onFilter}
					handleAddAction={handleAddAction}
					zoneActivate={zoneActivate}
					zoneDesactivate={zoneDesactivate}
					handleShowMap={handleShowMap}
				/>
			</Box>
			{openForm && (
				<AlertZonesForm
					title={t('alert.zones.form.title')}
					mode={modeTypesCRUD.INSERT_MODE}
					open={openForm}
					onClose={onClose}
					saveTxt={t('comun.actions.save')}
					onSave={onSave}
				/>
			)}

			<AlertZonesMapPopup open={openMap} onClose={onCloseMap} zone={selectedRow} />
		</Box>
	)
}