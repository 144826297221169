import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useConfigAlertFilterRequest = () => {
	const { client } = useContext(ApiClientContext)
	const [configAlertFilter, setConfigAlertFilter] = useState(null)
	const [errorConfigAlertFilter, setErrorConfigAlertFilter] = useState(null)
	const [isLoadingConfigAlertFilter, setIsLoadingConfigAlertFilter] = useState(false)
	

	const configAlertFilterRequest = useCallback(() => {
		setIsLoadingConfigAlertFilter(true);
    	setConfigAlertFilter(null);
    	setErrorConfigAlertFilter(null);

		client
			.get(baseUrl + 'horus/f3/notification/config/filter')
			.then((result) => {
				setIsLoadingConfigAlertFilter(false)

				if (result.data.success) {
					setConfigAlertFilter(result.data.data)
				} else {
					setErrorConfigAlertFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoadingConfigAlertFilter(false)
				setErrorConfigAlertFilter(err.response)
			})
	}, [client])
	return {
		configAlertFilterRequest,
		isLoadingConfigAlertFilter,
		configAlertFilter,
		errorConfigAlertFilter,
	}
}