import React from "react";
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

import ErrorBoundary from "../components/ErrorBoundary";
import AdminConfigNotificationsContainer from '../components/admin/AdminConfigNotificationsContainer';


const AdminConfigNotifications = () => {
    const history = useHistory();

    return(
        <ErrorBoundary history={history}>
            <AdminConfigNotificationsContainer></AdminConfigNotificationsContainer>
        </ErrorBoundary>
    );
};

export default AdminConfigNotifications;