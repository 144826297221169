
import { Approval } from "@mui/icons-material";
import { appRoutes, rolesConstants } from "../constants";
import { hasAdminRole, hasUserAdminRole, hasEMAdminRole, hasEMStaffRole, hasFleetAdminRole, hasFleetStaffRole } from "./roles";

export const validateRolesAppRoutes = (roles, route) => {

	switch (route) {
		case appRoutes.HOME:
			return roles.includes(rolesConstants.FLEET_ADMIN, rolesConstants.FLEET_STAFF);
		case appRoutes.ANOTACIONES:
			return roles.includes(rolesConstants.EM_STAFF);
		case appRoutes.PROJECTS:
			return roles.includes(rolesConstants.EM_ADMIN, rolesConstants.EM_STAFF);
		case appRoutes.REPORTS:
			return roles.includes(rolesConstants.EM_ADMIN, rolesConstants.EM_STAFF);
		case appRoutes.CALIBRATIONS:
			return roles.includes(rolesConstants.ADMIN, rolesConstants.FLEET_ADMIN, rolesConstants.FLEET_STAFF, rolesConstants.EM_ADMIN, rolesConstants.EM_STAFF);
		case appRoutes.TIMER:
			return roles.includes(rolesConstants.ADMIN);
		case appRoutes.PLANIFICATIONS:
			return roles.includes(rolesConstants.EM_ADMIN);
		case appRoutes.USERS_MANAGEMENT:
			return roles.includes(rolesConstants.ADMIN);
		case appRoutes.USERS_PROFILE:
			return roles.includes(rolesConstants.ADMIN, rolesConstants.FLEET_ADMIN, rolesConstants.FLEET_STAFF, rolesConstants.EM_ADMIN, rolesConstants.EM_STAFF);
		case appRoutes.VIDEO:
			return roles.includes(rolesConstants.FLEET_STAFF, rolesConstants.EM_STAFF);
		case appRoutes.PREVIEW:
			return roles.includes(rolesConstants.FLEET_ADMIN, rolesConstants.FLEET_STAFF, rolesConstants.EM_ADMIN, rolesConstants.EM_STAFF);
		case appRoutes.ALERTS:
			return roles.includes(rolesConstants.FLEET_ADMIN);
		case appRoutes.ALERTS_CONFIG:
			return roles.includes(rolesConstants.FLEET_ADMIN);
		case appRoutes.ALERT_ZONES:
			return roles.includes(rolesConstants.FLEET_ADMIN);
		default:
			return true;
	}
}

export const hasAnyRol = (roles) => {
	return roles.includes(rolesConstants.ADMIN, rolesConstants.USER_ADMIN, rolesConstants.FLEET_ADMIN, rolesConstants.FLEET_STAFF, rolesConstants.EM_ADMIN, rolesConstants.EM_STAFF);
}

export const calculateRouteRoles = (roles) => {
	if (hasFleetAdminRole(roles) || hasFleetStaffRole(roles)) {
		return appRoutes.HOME;
	} else if (hasEMAdminRole(roles) || hasEMStaffRole(roles)) {
		return appRoutes.PREVIEW;
	} else if (hasUserAdminRole(roles)) {
		return appRoutes.USERS_MANAGEMENT;
	} else if(hasAdminRole(roles)) {
		return appRoutes.ADMIN_ORGANIZATIONS;
	} else {
		return appRoutes.HOME;
	}
}
