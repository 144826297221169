import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { messageDialogTypes } from '../../constants'
import '../../index.css'
import { useProgramFilterDelete } from '../../services/programs/useProgramFilterDelete'
import { useProgramFilterPost } from '../../services/programs/useProgramFilterPost'
import { assignFilter } from '../../utils/customFunctions'
import { datetimeGMTToLocaleInISOFormat, isStartDateBefore } from '../comun/DateUtils'
import FieldsetComponent from '../comun/FieldsetComponent'
import FilterFunctions from '../comun/FilterFunctions'
import MessageSnackBar from '../comun/MessageSnackBar'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import DatePickerComponent from '../comun/form_fields/DatePickerComponent'
import SelectComponent from '../comun/form_fields/SelectComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'

export default function ProgramFilter({
	users,
	clients,
	vessels,
	onFilter,
	filter,
	filterProject,
	favouriteFilters,
	statusOptions,
}) {
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
		formState: { errors },
	} = useForm({ mode: 'onTouched' })

	const [t] = useTranslation('common')

	const { dataFilter, saveFavouriteFilter, errorPostFilter } = useProgramFilterPost()
	const { deleteFavouriteFilter, errorDeleteFilter } = useProgramFilterDelete()

	const wholeForm = watch()

	useEffect(() => {
		if (filterProject) {
			setValue('description', filterProject.description ? filterProject.description : '')
			let user = users.find((u) => u.id == filterProject.idUser)
			setValue('users', user)
			setValue('startDate', filterProject.startDate)
			setValue('endDate', filterProject.endDate)
			let vessel = vessels.find((v) => v.vesselApiId == filterProject.vesselApiId)
			setValue('vessels', vessel)
			let statusValue = statusOptions.find((s) => s.value == filterProject.isFinish)
			setValue('status', statusValue)
		}
	}, [filterProject])

	const onSearch = (data) => {
		var idUser = data.users != null ? data.users.id : null
		// var idClient = data.clients != null ? data.clients.id : null
		var idVessel = data.vessels != null ? data.vessels.vesselApiId : null
		var startDate = datetimeGMTToLocaleInISOFormat(data.startDate)
		var endDate = datetimeGMTToLocaleInISOFormat(data.endDate)
		data.status = data.status?? 0;// 0 is all

		var filterData = {
			description: data.description,
			idUser: idUser,
			// idClient: idClient,
			startDate: startDate,
			endDate: endDate,
			vesselApiId: idVessel,
			isFinish: data.status,
		}

		assignFilter(filterData, filter)
		onFilter(filterData)
	}

	const [selectedFilter, setSelectedFilter] = useState('')
	const [favFilters, setFavFilters] = useState([])
	const [filterData, setFilterData] = useState({})

	useEffect(() => {
		if (favouriteFilters) {
			const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
				return {
					...filter,
					filterName,
				}
			})

			setFavFilters(favFiltersArray)
		}
	}, [favouriteFilters])

	const onClickSavedFilter = (selectedFilter) => {
		setSelectedFilter(selectedFilter)
	}

	const handleClearClick = () => {
		setSelectedFilter('')
	}

	const saveFilter = (filterName) => {
		let formValues = getValues()

		let filterDataAux = {
			filterName: filterName,
			description: formValues.description,
			//clientId: formValues.clients.id,
			vesselApiId: formValues.vessels?.vesselApiId,
			idUser: formValues.users?.id,
			startDate: datetimeGMTToLocaleInISOFormat(formValues.startDate),
			endDate: datetimeGMTToLocaleInISOFormat(formValues.startDate),
			isFinish: formValues.status?.value,
		}

		// let err = false

		// for (let key in filterDataAux) {
		// 	if (filterDataAux[key] === null || filterDataAux[key] === undefined || filterDataAux[key] === '') {
		// 		err = true
		// 	}
		// }

		// if( err ){
		// 	alert("Debes completar todos los campos para guardar un filtro...")
		// 	return
		// }

		saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux)
	}

	useEffect(() => {
		if (dataFilter && filterData) {
			setFavFilters((prevState) => [...prevState, filterData])
		}
	}, [dataFilter, filterData])

	const onDeleteSavedFilter = (event, filterName) => {
		deleteFavouriteFilter(filterName)
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)

		if (event instanceof Event) {
			event.stopPropagation()
		}
	}
	//Rellena los campos de favFilter
	useEffect(() => {
		if (selectedFilter && selectedFilter.filterName) {
			let usersObj = users.find((s) => s.id == selectedFilter.idUser)
			let status = statusOptions.find((s) => s.value == selectedFilter.isFinish)
			let vesselsObj = vessels.find((s) => s.vesselApiId == selectedFilter.vesselApiId)

			setValue('description', selectedFilter.description)
			setValue('users', usersObj)
			setValue('startDate', selectedFilter.startDate)
			setValue('endDate', selectedFilter.endDate)
			setValue('vessels', vesselsObj)
			setValue('status', status)
			setSelectedFilter('')
		}
	}, [selectedFilter])

	const resetFilter = () => {
		reset()
		setSelectedFilter('')
	}

	// Set errrors
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	useEffect(() => {
		if (errorPostFilter) {
			setOpenError(true)
			setErrorMsg(errorPostFilter)
		} else if (errorDeleteFilter) {
			setOpenError(true)
			setErrorMsg(errorDeleteFilter)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPostFilter, errorDeleteFilter])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<FieldsetComponent title={t('alert.zones.filter.title')}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box sx={{ display: 'flex' }} component='form' onSubmit={handleSubmit(onSearch)}>
				<Box sx={{ display: 'flex', mr: 1, width: '100%' }}>
					{/* First column */}
					<Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', mr: 1 }}>
						{/* Description */}

						<TextFieldComponent
							defaultValue={undefined}
							sx={{ width: '100%', m: 1 }}
							name='description'
							label={t('programs.filter.description')}
							control={control}
							size='small'
						/>

						{/* User */}
						<AutoCompleteComponent
							size='small'
							sx={{ width: '100%', m: 1 }}
							control={control}
							name='users'
							label={t('programs.filter.user')}
							optionsLabel={'userName'}
							keyProperty={'id'}
							options={users}
						/>
						{/* Dates */}
						<DatePickerComponent
							defaultValue={null}
							size='small'
							sx={{ width: '100%', m: 1 }}
							name='startDate'
							control={control}
							label={t('programs.filter.start-date')}
							errors={errors?.startDate}
							rules={{
								validate: (startDateValue) =>
									isStartDateBefore(startDateValue, getValues('endDate')) ||
									t('programs.validations.start-date'),
							}}
						/>
					</Box>
					{/* Second column */}
					<Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
						{/* Customer */}
						{/* <AutoCompleteComponent
							size='small'
							sx={{ width: '100%', m: 1, display: 'none' }}
							control={control}
							name='clients'
							label={t('programs.filter.customer')}
							optionsLabel={'name'}
							keyProperty={'id'}
							options={clients}
						/> */}

						{/* Vessel */}

						<AutoCompleteComponent
							size='small'
							sx={{ width: '100%', m: 1 }}
							control={control}
							name='vessels'
							label={t('programs.filter.vessel')}
							optionsLabel={'vesselName'}
							keyProperty={'vesselApiId'}
							options={vessels}
							evalMethod={(opt, val) => {
								return opt.vesselApiId == val.vesselApiId
							}}
						/>
						<AutoCompleteComponent
							size='small'
							sx={{ width: '100%', m: 1 }}
							label={t('programs.filter.status')}
							defaultValue={statusOptions ? statusOptions[0] : null}
							name='status'
							control={control}
							options={statusOptions}
							optionsLabel={'description'}
							keyProperty={'value'}
						/>
						<DatePickerComponent
							defaultValue={null}
							size='small'
							sx={{ width: '100%', m: 1 }}
							name='endDate'
							control={control}
							label={t('programs.filter.end-date')}
							errors={errors?.endDate}
						/>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<FilterFunctions
						filters={favFilters}
						handleClearClick={handleClearClick}
						onClickSavedFilter={onClickSavedFilter}
						threeLines
						resetFilter={resetFilter}
						saveFilter={saveFilter}
						onDeleteSavedFilter={onDeleteSavedFilter}
						selectedFilter={selectedFilter}
					/>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
