import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { modeTypesCRUD } from "../../../constants"
import BasicDialog from "../../comun/BasicDialog"
//import { Box } from "devextreme-react"
import Box from '@mui/material/Box'
import TextFieldComponent from "../../comun/form_fields/TextFieldComponent"
import { useForm } from "react-hook-form"
import MultipleSelectionCombo from "./MultipleSelectionCombo"
import FormGeneralDataOrganization from "./FormGeneralDataOrganization"

export default function OrganizationsForm({
    selectedOrg,
    vessels,
    fishingProfiles,
    mode,
    onClose,
    open,
    onSave
}) {

    const [t] = useTranslation('common')
    const methodsForm = useForm({ mode: 'onSubmit' })

    
    const getTitle = () => {
        if (modeTypesCRUD.UPDATE_MODE === mode) {
            return t('organizations.form.title-update')
        } else if (modeTypesCRUD.INSERT_MODE === mode) {
            return t('organizations.form.title-new')
        }
    }

    return (
        <Box component='form' onSubmit={methodsForm.handleSubmit((data) => onSave(data))}>
            <BasicDialog
                title={getTitle()}
                onOpen={open}
                onClose={onClose}
                mode={mode}
                saveTxt={t('comun.actions.save')}>
                <Box container sx={{ minWidth: '1000px', maxHeight: '600px', width: '100%', overflowY: 'auto', padding: '10px' }} spacing={2}>
                    <FormGeneralDataOrganization
                        methodsForm={methodsForm}
                        selected={selectedOrg}></FormGeneralDataOrganization>

                    <Box sx={{ m: 1 }}>
                        <MultipleSelectionCombo
                            methodsForm={methodsForm}
                            title={t('organizations.vessels.title')}
                            label={t('organizations.vessels.label')}
                            valueName='vessels'
                            options={vessels}
                            initialSelectedItems={selectedOrg.vessels}
                        ></MultipleSelectionCombo>
                    </Box>
                    <Box sx={{ m: 1 }}>
                        <MultipleSelectionCombo
                            methodsForm={methodsForm}
                            title={t('organizations.fishingProfile.title')}
                            label={t('organizations.fishingProfile.label')}
                            valueName='fishingProfiles'
                            options={fishingProfiles}
                            initialSelectedItems={selectedOrg.fishingProfiles}
                        ></MultipleSelectionCombo>
                    </Box>
                </Box>

            </BasicDialog>
        </Box>
    )
}

OrganizationsForm.defaultProps = {
    mode: modeTypesCRUD.INSERT_MODE,
    open: false,
    close: () => { },
}