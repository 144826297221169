import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../../constants'
import { ApiClientContext } from '../../../ApiClientProvider'

export const useOrganizationVesselsFavFilterDelete = () => {
    const [successDataFilter, setSuccessDataFilter] = useState(null)
    const [errorDeleteFilter, setErrorFilter] = useState(null)
    const [isLoadingFilter, setIsLoadingFilter] = useState(false)

    const { client } = useContext(ApiClientContext)
  
    const deleteFavouriteFilter = useCallback(
        (props) => {
            client
                .delete(baseUrl + 'horus/admin/organization/vessel/config/filter/favourite?filterName='+props)
                .then((result) => {
                    setIsLoadingFilter(false)

                    if (result.status === 200 && result.data.success) {
                        setSuccessDataFilter({ data: result.data })
                    } else {
                        setErrorFilter({ statusText: result.data.errorMsg })
                    }
                    
                    setIsLoadingFilter(false)
                })
                .catch((error) => {
                    setErrorFilter(error.response)
                    setIsLoadingFilter(false)
                })

                setSuccessDataFilter(null)
			    setErrorFilter(null)
			    setIsLoadingFilter(true)
            },
        [client]
    )
  
    return {
        successDataFilter,
        errorDeleteFilter,
        isLoadingFilter,
        deleteFavouriteFilter
    }
}