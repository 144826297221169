import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useVesselDelete = () => {
    const [vesselDataDelete, setVesselDataDelete] = useState(null)
    const [errorVesselDelete, seterrorVesselDelete] = useState(null)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)

    const { client } = useContext(ApiClientContext)

    const vesselDelete = useCallback(
        (vessel) => {
            client
                .delete(baseUrl + 'horus/admin/vessel', { data: vessel })
                .then((result) => {
                    if (result.status === 200 && result.data.success) {
                        setVesselDataDelete({ data: result.data })
                    } else {
                        seterrorVesselDelete({ statusText: result.data.errorMsg })
                    }
                    setIsLoadingDelete(false)
                })
                .catch((error) => {
                    seterrorVesselDelete(error.response)
                    setIsLoadingDelete(false)
                })

            setVesselDataDelete(null)
            seterrorVesselDelete(null)
            setIsLoadingDelete(true)
        },
        [client]
    )

    return {
        vesselDelete,
        isLoadingDelete,
        vesselDataDelete,
        setVesselDataDelete,
        errorVesselDelete,
        seterrorVesselDelete,
    }
}