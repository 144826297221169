import React from "react";
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import ErrorBoundary from "../components/ErrorBoundary";
import OrganizationsVesselContainer from '../components/admin/OrganizationsVesselContainer'

const AdminOrganizationVesselsPage = () => {
    const history = useHistory()
  return (
    <ErrorBoundary history={history}>
        <OrganizationsVesselContainer></OrganizationsVesselContainer>
    </ErrorBoundary>
  )
}

export default AdminOrganizationVesselsPage
