import DeleteIcon from '@mui/icons-material/Delete'
import RedoIcon from '@mui/icons-material/Redo'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Badge, Box, Collapse, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { messageDialogTypes, modeTypesCRUD } from '../../../../constants'
import { useVideoScreenshotInfo } from '../../../../services/videos/useVideoScreenshotInfo'
import { datetimeUTCtoString } from '../../../comun/DateUtils'
import MessageSnackBar from '../../../comun/MessageSnackBar'
import BackdropComponent from '../../../comun/utils/BackdropComponent'
import CountContainer from '../../../count/CountContainer'
import MeasureContainer from '../../../measurement/MeasureContainer'
import FormAnotacionesDinamico from '../../../notes/FormAnotacionesDinamico'
import useStore from '../../PreviewStore'
import { useShallow } from 'zustand/shallow'

const img = new Image()
function AnnotationRow({ annotation, fishingProfile, handleDeleteAnnotation, handleGoToMoment, icon }) {
	const [open, setOpen] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [showReviewComments, setShowReviewComments] = useState(false)
	const { setUpdatedSecondaryTab, project, play, pause } = useStore(
		useShallow((state) => ({
			project: state.project,
			setUpdatedSecondaryTab: state.setUpdatedSecondaryTab,
			play: state.play,
			pause: state.pause,
		}))
	)
	const [t] = useTranslation('common')

	const onOpenModal = (e, annotation) => {
		e.stopPropagation()
		pause()
		if (annotation.itemType == 'ME') {
			setProjectItemId(annotation.projectItemId)
			handleMeasurement()
		} else if (annotation.itemType == 'CT') {
			setProjectItemId(annotation.projectItemId)
			handleCount()
		} else {
			if (annotation.lineList == null || annotation.lineList.length == 0) {
				setShowReviewComments(false)
			} else {
				setShowReviewComments(true)
			}
			setOpenModal(true)
		}
	}

	const onClose = () => {
		setOpenModal(false)
		play()
	}

	//Measures
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeNoteModal, setTypeNoteModal] = useState(null)
	const [openMeasure, setOpenMeasure] = React.useState(false)

	const [projectItemId, setProjectItemId] = React.useState(null)
	const { getScreenshotInfo, screenshotInfoData, isLoadingScreenshotInfo, screenshotInfoError } =
		useVideoScreenshotInfo()

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const handleCloseMeasure = (success) => {
		if (success) {
			setUpdatedSecondaryTab('2')
		}
		setOpenMeasure(false)
		play()
	}

	useEffect(() => {
		if (screenshotInfoError) {
			setErrorMsg(screenshotInfoError)
			setOpenError(true)
		} else {
			setErrorMsg(null)
			setOpenError(false)
		}
	}, [screenshotInfoError])

	useEffect(() => {
		if (screenshotInfoData) {
			if (typeNoteModal == 'COU') {
				img.src = screenshotInfoData.url
				setOpenCount(true)
			} else if (typeNoteModal == 'MEA') {
				img.src = screenshotInfoData.url
				setOpenMeasure(true)
			}
		}
	}, [screenshotInfoData])

	const handleMeasurement = () => {
		let params = {
			VideoId: annotation.fileId,
			Offset: annotation.offset,
			Opt: 'MEA',
		}
		setTypeNoteModal('MEA')
		getScreenshotInfo(params)
	}
	///--------------------------------------------------------------------------

	//Count
	const [openCount, setOpenCount] = React.useState(false)

	const handleCloseCount = (success) => {
		if (success) {
			setUpdatedSecondaryTab('2')
		}
		setOpenCount(false)
		play()
	}
	const handleCount = () => {
		let params = {
			VideoId: annotation.fileId,
			Offset: annotation.offset,
			Opt: 'COU',
		}
		setTypeNoteModal('COU')
		getScreenshotInfo(params)
	}

	return (
		<React.Fragment>
			<BackdropComponent open={isLoadingScreenshotInfo} />
			<TableRow
				selected={open}
				onClick={() => setOpen(!open)}
				sx={{
					'&:last-child td, &:last-child th': { border: 0 },
				}}>
				<TableCell align='center' sx={{ display: 'flex', alignItems: 'center', borderBottom: 'none' }}>
					{annotation.lineUnreadCommentsNo != 0 && (
						<Badge
							badgeContent={annotation.lineComments}
							color={annotation.hasUnreadComments ? 'error' : 'secondary'}></Badge>
					)}
					<Typography sx={{ ml: 4 }}>{datetimeUTCtoString(annotation.itemDateTime)}</Typography>
				</TableCell>
				<TableCell align='center' sx={{ borderBottom: 'none' }}>
					{annotation.camNo}
				</TableCell>
				<TableCell align='center' sx={{ borderBottom: 'none' }} color='none'>
					<Tooltip title={annotation.description}>
						<SvgIcon component={icon} />
					</Tooltip>
				</TableCell>
				<TableCell align='center' sx={{ borderBottom: 'none' }}>
					<Tooltip title={t('preview.annotation.go-to-moment')}>
						<IconButton color='primary' sx={{ py: 0 }} onClick={(e) => handleGoToMoment(e, annotation)}>
							<RedoIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={t('preview.annotation.view-detail')}>
						<IconButton color='primary' sx={{ py: 0, mx: 1 }} onClick={(e) => onOpenModal(e, annotation)}>
							<VisibilityIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={t('comun.actions.delete')}>
						<IconButton color='error' sx={{ py: 0 }} onClick={(e) => { e.stopPropagation(); handleDeleteAnnotation(annotation) }}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</TableCell>
			</TableRow>

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					{(annotation.lineList.length != 0 || annotation.computedData) && (
						<Collapse in={open} timeout='auto' sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: annotation.computedData ? '1fr 1fr 1fr' : '1fr 1fr',
									columnGap: '2%',
									fontSize: '0.8rem',
								}}>
								<Box>
									<Box sx={{ borderBottom: '1px solid #1976d2' }}>Lines</Box>
									<Box sx={{ whiteSpace: 'pre-line' }}>
										{annotation.lineList.map(
											(l) => '[' + l.lineNo + '] ' + l.label + ': ' + l.value + '\n'
										)}
									</Box>
								</Box>
								<Box>
									<Box sx={{ borderBottom: '1px solid #1976d2' }}>Comments</Box>
									<Box sx={{ whiteSpace: 'pre-line' }}>
										{annotation.lineList.map((l) => {
											return l.commentList.map(
												(c, idx) => '[' + c.lineNo + '.' + (idx + 1) + '] ' + c.comment + '\n'
											)
										})}
									</Box>
								</Box>
								{annotation.computedData && (
									<Box>
										<Box sx={{ borderBottom: '1px solid #1976d2' }}>Computed data</Box>
										<Box>{annotation.computedData}</Box>
									</Box>
								)}
							</Box>
						</Collapse>
					)}
				</TableCell>
			</TableRow>
			{openModal && (
				<FormAnotacionesDinamico
					fishingProfileList={fishingProfile}
					tipoNota={annotation.itemType}
					tipoNotaDisabled={true}
					onOpen={openModal}
					onClose={onClose}
					mode={modeTypesCRUD.UPDATE_MODE}
					noteProp={annotation}
					projectId={annotation.projectId}
					canCreateEditAnnotations={true}
					canReviewAnnotations={true}
					showReviewComments={showReviewComments}
				/>
			)}

			{openMeasure && (
				<MeasureContainer
					canCreateEditAnnotations={true}
					onOpen={openMeasure}
					onClose={handleCloseMeasure}
					image={img}
					imageId={screenshotInfoData?.imageId}
					cam={annotation.camNo}
					vesselApiId={project.vesselApiId}
					projectItemId={projectItemId}
					isUpdating={true}
				/>
			)}

			{openCount && (
				<CountContainer
					canCreateEditAnnotations={true}
					onOpen={openCount}
					onClose={handleCloseCount}
					image={img}
					mode={modeTypesCRUD.INSERT_MODE}
					projectItemId={projectItemId}
					isUpdating={true}
				/>
			)}
			<MessageSnackBar
				openError={openError}
				handleCloseError={handleCloseError}
				errorMsg={errorMsg}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
		</React.Fragment>
	)
}
export default React.memo(AnnotationRow)
