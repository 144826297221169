import React from 'react'
import { useTranslation } from 'react-i18next'
import { modeTypesCRUD } from "../../../constants"
import { Box } from '@mui/material'
import TextFieldComponent from "../../comun/form_fields/TextFieldComponent"
import BasicDialog from '../../comun/BasicDialog'
import { useForm } from "react-hook-form"
import FormGeneralDataOrganization from '../organization/FormGeneralDataOrganization'
import FormGeneralDataVessel from './FormGeneralDataVessel'

export default function VesselsForm({
    selectedVessel,
    mode,
    onClose,
    open,
    onSave
}) {

    const [t] = useTranslation('common')
    const methodsForm = useForm({ mode: 'onSubmit' })

    const getTitle = () => {
        if (modeTypesCRUD.UPDATE_MODE === mode) {
            return t('vessels.form.title-update')
        } else if (modeTypesCRUD.INSERT_MODE === mode) {
            return t('vessels.form.title-new')
        }
    }

    return (
        <Box component='form' onSubmit={methodsForm.handleSubmit((data) => onSave(data))}>
            <BasicDialog
                title={getTitle()}
                onOpen={open}
                onClose={onClose}
                mode={mode}
                saveTxt={t('comun.actions.save')}>
                <Box container sx={{ minWidth: '1000px', maxHeight: '600px', width: '100%', overflowY: 'auto', padding: '10px' }} spacing={2}>
                    <FormGeneralDataVessel
                        methodsForm={methodsForm}
                        selected={selectedVessel}
                    ></FormGeneralDataVessel>
                </Box>
            </BasicDialog>
        </Box>
    )
}
